import DataType from './DataType'
import dataTypeUtils from '../../utils/dataTypeUtils'
import DiagramVariableType from '../../../data/models/DiagramVariableType'

class Sequence implements DataType {
  get name(): DiagramVariableType {
    return 'Sequence'
  }

  get symbol(): string {
    return 's'
  }

  public isCompatible(dataType: DataType): boolean {
    return dataTypeUtils.isCompatible(this, dataType)
  }
}

export default Sequence
