export const parseNodeId = (nodeId: string): number =>
  parseInt(nodeId.split(':')[1])

export const parseTargetHandleId = (
  targetHandleId: string
): { nodeId: number; inputIndex: number } => {
  const [nodeId, inputIndex] = targetHandleId.split('#')

  return {
    nodeId: parseNodeId(nodeId),
    inputIndex: parseInt(inputIndex)
  }
}
