import { ValueSettingInputProps } from '../ValueSettingInput/ValueSettingInput.types'
import { Stack, TextField } from '@mui/material'
import useVectorValueSettingInput from './useVectorValueSettingInput'

const VectorValueSettingInput = (props: ValueSettingInputProps) => {
  const { vector, handleXChange, handleYChange, handleZChange } =
    useVectorValueSettingInput(props)

  return (
    <Stack spacing={2}>
      <TextField
        type="number"
        label="x"
        fullWidth
        value={vector.x}
        onChange={handleXChange}
      />
      <TextField
        type="number"
        label="y"
        fullWidth
        value={vector.y}
        onChange={handleYChange}
      />
      <TextField
        type="number"
        label="z"
        fullWidth
        value={vector.z}
        onChange={handleZChange}
      />
    </Stack>
  )
}

export default VectorValueSettingInput
