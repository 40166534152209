import { IconButton, InputBase, Paper, Stack } from '@mui/material'
import { SearchOutlined } from '@mui/icons-material'
import React from 'react'
import { SearchProps } from './Search.types'

const Search = (props: SearchProps) => {
  const { onChange, placeholder, value } = props

  return (
    <Paper variant="outlined">
      <Stack flexDirection="row">
        <IconButton color="default">
          <SearchOutlined />
        </IconButton>
        <InputBase
          fullWidth
          type="search"
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          sx={{ paddingRight: 1 }}
        />
      </Stack>
    </Paper>
  )
}

export default Search
