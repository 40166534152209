import { Button, Card, CardActions } from '@mui/material'
import {
  CachedOutlined,
  ContentCopyOutlined,
  CopyAllOutlined,
  DeleteOutline,
  GroupWorkOutlined
} from '@mui/icons-material'
import { ContextualNodeActionsProps } from './ContextualNodeActions.types'

const ContextualNodeActions = (props: ContextualNodeActionsProps) => {
  const {
    deleteIsDisabled,
    containIsDisabled,
    decontainIsDisabled,
    replaceIsDisabled,
    duplicateIsDisabled,
    copyIsDisabled,
    onDeleteClick,
    onContainClick,
    onDecontainClick,
    onReplaceClick,
    onDuplicateClick,
    onCopyClick,
    show
  } = props

  return (
    <Card
      variant="outlined"
      sx={{
        display: show ? 'block' : 'none',
        position: 'absolute',
        bottom: 30,
        zIndex: 1000,
        left: '50%',
        transform: 'translateX(-50%)'
      }}
    >
      <CardActions>
        {!deleteIsDisabled && (
          <Button
            endIcon={<DeleteOutline />}
            disabled={deleteIsDisabled}
            variant="contained"
            size="small"
            onClick={onDeleteClick}
          >
            Delete
          </Button>
        )}
        {!containIsDisabled && (
          <Button
            endIcon={<GroupWorkOutlined />}
            disabled={containIsDisabled}
            variant="contained"
            size="small"
            onClick={onContainClick}
          >
            Contain
          </Button>
        )}
        {!decontainIsDisabled && (
          <Button
            endIcon={<GroupWorkOutlined />}
            disabled={decontainIsDisabled}
            variant="contained"
            size="small"
            onClick={onDecontainClick}
          >
            Decontain
          </Button>
        )}
        {!replaceIsDisabled && (
          <Button
            endIcon={<CachedOutlined />}
            disabled={replaceIsDisabled}
            variant="contained"
            size="small"
            onClick={onReplaceClick}
          >
            Replace
          </Button>
        )}
        {!duplicateIsDisabled && (
          <Button
            endIcon={<ContentCopyOutlined />}
            disabled={duplicateIsDisabled}
            variant="contained"
            size="small"
            onClick={onDuplicateClick}
          >
            Duplicate
          </Button>
        )}
        {!copyIsDisabled && (
          <Button
            endIcon={<CopyAllOutlined />}
            disabled={copyIsDisabled}
            variant="contained"
            size="small"
            onClick={onCopyClick}
          >
            Copy
          </Button>
        )}
      </CardActions>
    </Card>
  )
}

export default ContextualNodeActions
