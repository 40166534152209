import { useCallback } from 'react'

const useDownloader = () =>
  useCallback((name: string, data: string) => {
    const a = document.createElement('a')

    a.setAttribute('download', name)

    a.setAttribute('href', data)

    a.click()

    a.remove()
  }, [])

export default useDownloader
