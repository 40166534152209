import DraggedNode from '../DraggedNode'
import DraggedNodeFunctionNodeData from '../DraggedNodeFunctionNodeData'
import DiagramFunctionType from '../../data/models/DiagramFunctionType'

const draggedFunctionNodeFactory = {
  create: (
    name: DiagramFunctionType
  ): DraggedNode<DraggedNodeFunctionNodeData> => ({
    type: 'function',
    data: {
      name
    }
  })
}

export default draggedFunctionNodeFactory
