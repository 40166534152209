import { Handle, HandleProps } from 'reactflow'
import { HTMLAttributes } from 'react'
import './DatatypeHandle.styles.css'

const DatatypeHandle = (
  props: HandleProps &
    Omit<HTMLAttributes<HTMLDivElement>, 'id'> & {
      symbol: string
    }
) => {
  const { id, type, position, style, symbol } = props

  return (
    <Handle
      className="data-type-handle"
      id={id}
      type={type}
      position={position}
      style={{
        height: '71px',
        width: '71px',
        background: '#fff',
        border: '5px solid #808080',
        zIndex: 1000,
        ...style
      }}
      data-symbol={symbol}
    />
  )
}

export default DatatypeHandle
