import Value from './Value'

class ArrayValue<T extends Value> implements Value {
  public values: T[]

  constructor(values: T[] = []) {
    this.values = values
  }

  toString(): string {
    return `[${this.values.map((value) => value.toString()).join(', ')}]`
  }
}
export default ArrayValue
