import ValueFactory from './ValueFactory'
import FloatValue from '../../models/values/FloatValue'

const floatValueFactory: ValueFactory<FloatValue> = {
  tryCreateWithStringRepresentation(
    representation: string
  ): Promise<FloatValue> {
    const maybeFloat = parseFloat(representation)

    if (isNaN(maybeFloat)) {
      return Promise.reject('value was not parseable to a number')
    }

    return Promise.resolve(new FloatValue(maybeFloat))
  }
}

export default floatValueFactory
