import Node from './Node'
import Handle from './Handle'
import Input from './Input'
import Output from './Output'

class Connection {
  public readonly source: Handle<Output>

  public readonly target: Handle<Input>

  public readonly sourceNode: Node

  public readonly targetNode: Node

  constructor(
    source: Handle<Output>,
    target: Handle<Input>,
    sourceNode: Node | undefined = undefined,
    targetNode: Node | undefined = undefined
  ) {
    this.source = source
    this.target = target
    this.sourceNode = sourceNode ?? source.node
    this.targetNode = targetNode ?? target.node
  }

  get id(): string {
    return `${this.sourceNode.getId()}[${
      this.source.id
    }]->${this.targetNode.getId()}[${this.target.id}]`
  }
}

export default Connection
