import { ValueSettingInputProps } from '../ValueSettingInput/ValueSettingInput.types'
import { TextField } from '@mui/material'
import useFloatValueSettingInput from './useFloatValueSettingInput'

const FloatValueSettingInput = (props: ValueSettingInputProps) => {
  const { float, handleFloatChange } = useFloatValueSettingInput(props)

  return (
    <TextField
      type="number"
      fullWidth
      value={float}
      label="Float"
      onChange={handleFloatChange}
    />
  )
}

export default FloatValueSettingInput
