import DiagramDataModel from '../../../data/models/DiagramDataModel'
import Transformer from '../../../transformation/Transformer'
import mapperTransformerFactory from '../../../transformation/factories/mapperTransformerFactory'
import connectionToDiagramConnectionDataModelTransformer from './connectionToDiagramConnectionDataModelTransformer'
import containerUtils from '../../utils/containerUtils'
import nodeToDiagramPositionDataModelTransformer from './nodeToDiagramPositionDataModelTransformer'
import nodeToDiagramNodeDataModelTransformer from './nodeToDiagramNodeDataModelTransformer'
import nodesToDiagramPropertiesDataModelTransformer from './nodesToDiagramPropertiesDataModelTransformer'
import nodesToDiagramContainerDataModelTransformer from './nodesToDiagramContainerDataModelTransformer'
import DiagramView from '../../models/DiagramView'

const diagramViewDomainModelToDiagramDataModelTransformer: Transformer<
  DiagramView,
  DiagramDataModel
> = {
  transform: (diagramView): DiagramDataModel => {
    const flattenedNodes = containerUtils.flattenNodes(diagramView.nodes)

    return {
      manualLines: undefined,
      connections: mapperTransformerFactory
        .create(connectionToDiagramConnectionDataModelTransformer)
        .transform(diagramView.connections, undefined)
        .sort((a, b) => a.outputNodeId - b.outputNodeId),
      nodes: mapperTransformerFactory
        .create(nodeToDiagramNodeDataModelTransformer)
        .transform(flattenedNodes, undefined)
        .sort((a, b) => a.id - b.id),
      containers: nodesToDiagramContainerDataModelTransformer.transform(
        diagramView.nodes,
        undefined
      ),
      properties: nodesToDiagramPropertiesDataModelTransformer
        .transform(flattenedNodes, undefined)
        .sort((a, b) => a.id - b.id),
      positions: mapperTransformerFactory
        .create(nodeToDiagramPositionDataModelTransformer)
        .transform(flattenedNodes, undefined)
        .sort((a, b) => a.id - b.id),
      subdiagrams: undefined
    }
  }
}

export default diagramViewDomainModelToDiagramDataModelTransformer
