import { ConfirmationDialogProps } from './ConfirmationDialog.types'
import useConfirmationDialog from './useConfirmationDialog'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import { IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import * as React from 'react'

const ConfirmationDialog = (props: ConfirmationDialogProps) => {
  const {
    isOpen,
    onCancel,
    title,
    childrenOrDefault,
    onConfirmButtonRender,
    onConfirm,
    confirmButtonTextOrDefault,
    truncate
  } = useConfirmationDialog(props)

  return (
    <Dialog
      open={isOpen}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{truncate(title, 40)}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onCancel}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8
        }}
      >
        <Close />
      </IconButton>
      <DialogContent>{childrenOrDefault}</DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        {onConfirmButtonRender ? (
          onConfirmButtonRender(onConfirm, confirmButtonTextOrDefault)
        ) : (
          <Button variant="contained" onClick={onConfirm}>
            {confirmButtonTextOrDefault}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationDialog
