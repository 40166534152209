import { z } from 'zod'
import DiagramContainerDataModel from '../models/DiagramContainerDataModel'

const diagramContainerDataModelValidator: z.ZodType<DiagramContainerDataModel> =
  z.object({
    nodeIds: z.array(z.number()),
    x: z.number(),
    y: z.number(),
    description: z.string(),
    color: z.string().optional(),
    containers: z
      .array(z.lazy(() => diagramContainerDataModelValidator))
      .default([])
      .optional()
  })

export default diagramContainerDataModelValidator
