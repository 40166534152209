import Transformer from '../Transformer'

const mapperTransformerFactory = {
  create: <TInItem, TOutItem, TContext>(
    itemTransformer: Transformer<TInItem, TOutItem, TContext>
  ): Transformer<Readonly<TInItem[]>, TOutItem[], TContext> => ({
    transform: (items: TInItem[], context: TContext): TOutItem[] =>
      items.map((item) => itemTransformer.transform(item, context))
  })
}

export default mapperTransformerFactory
