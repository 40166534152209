import {
  Box,
  Card,
  CardContent,
  Chip,
  Divider,
  Stack,
  Typography
} from '@mui/material'
import React from 'react'
import { ValueSettingProps } from './ValueSetting.types'
import ViewSwitch from '../ViewSwitch'
import ViewSwitchCase from '../ViewSwitchCase'
import DiagramVariableType from '../../features/data/models/DiagramVariableType'
import FloatValueSettingInput from '../FloatValueSettingInput'
import BooleanValueSettingInput from '../BooleanValueSettingInput'
import IntegerValueSettingInput from '../IntegerValueSettingInput'
import StringValueSettingInput from '../StringValueSettingInput'
import VectorValueSettingInput from '../VectorValueSettingInput'
import OrientationValueSettingInput from '../OrientationValueSettingInput'
import ArrayValueSettingInput from '../ArrayValueSettingInput'
import SequenceValueSettingInput from '../SequenceValueSettingInput'
import booleanArrayValueFactory from '../../features/domain/factories/values/booleanArrayValueFactory'
import floatArrayValueFactory from '../../features/domain/factories/values/floatArrayValueFactory'
import FloatValue from '../../features/domain/models/values/FloatValue'
import BooleanValue from '../../features/domain/models/values/BooleanValue'
import OrientationValue from '../../features/domain/models/values/OrientationValue'
import orientationArrayValueFactory from '../../features/domain/factories/values/orientationArrayValueFactory'
import SequenceValue from '../../features/domain/models/values/SequenceValue'
import sequenceArrayValueFactory from '../../features/domain/factories/values/sequenceArrayValueFactory'
import VectorValue from '../../features/domain/models/values/VectorValue'
import vectorArrayValueFactory from '../../features/domain/factories/values/vectorArrayValueFactory'
import IntegerValue from '../../features/domain/models/values/IntegerValue'
import integerArrayValueFactory from '../../features/domain/factories/values/integerArrayValueFactory'
import StringValue from '../../features/domain/models/values/StringValue'
import stringArrayValueFactory from '../../features/domain/factories/values/stringArrayValueFactory'

const ValueSetting = (props: ValueSettingProps) => {
  const { dataType, value, onValueChange } = props

  return (
    <Card variant="outlined">
      <CardContent>
        <Stack direction="row">
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexGrow: 1
            }}
          >
            <Typography variant="body1">
              <b>Value</b>
            </Typography>
          </Box>
          <Chip size="small" label={dataType.name} color="primary" />
        </Stack>
        <Divider sx={{ marginBottom: 2, marginTop: 2 }} />
        <ViewSwitch<DiagramVariableType> activeCase={dataType.name}>
          <ViewSwitchCase<DiagramVariableType> case="Float array">
            <ArrayValueSettingInput<FloatValue>
              value={value}
              factory={floatArrayValueFactory}
              onValueChange={onValueChange}
            />
          </ViewSwitchCase>
          <ViewSwitchCase<DiagramVariableType> case="Integer array">
            <ArrayValueSettingInput<IntegerValue>
              value={value}
              factory={integerArrayValueFactory}
              onValueChange={onValueChange}
            />
          </ViewSwitchCase>
          <ViewSwitchCase<DiagramVariableType> case="Boolean array">
            <ArrayValueSettingInput<BooleanValue>
              value={value}
              factory={booleanArrayValueFactory}
              onValueChange={onValueChange}
            />
          </ViewSwitchCase>
          <ViewSwitchCase<DiagramVariableType> case="String array">
            <ArrayValueSettingInput<StringValue>
              value={value}
              factory={stringArrayValueFactory}
              onValueChange={onValueChange}
            />
          </ViewSwitchCase>
          <ViewSwitchCase<DiagramVariableType> case="Orientation array">
            <ArrayValueSettingInput<OrientationValue>
              value={value}
              factory={orientationArrayValueFactory}
              onValueChange={onValueChange}
            />
          </ViewSwitchCase>
          <ViewSwitchCase<DiagramVariableType> case="Vector array">
            <ArrayValueSettingInput<VectorValue>
              value={value}
              factory={vectorArrayValueFactory}
              onValueChange={onValueChange}
            />
          </ViewSwitchCase>
          <ViewSwitchCase<DiagramVariableType> case="Sequence array">
            <ArrayValueSettingInput<SequenceValue>
              value={value}
              factory={sequenceArrayValueFactory}
              onValueChange={onValueChange}
            />
          </ViewSwitchCase>
          <ViewSwitchCase<DiagramVariableType> case="Boolean">
            <BooleanValueSettingInput
              value={value}
              onValueChange={onValueChange}
            />
          </ViewSwitchCase>
          <ViewSwitchCase<DiagramVariableType> case="Float">
            <FloatValueSettingInput
              value={value}
              onValueChange={onValueChange}
            />
          </ViewSwitchCase>
          <ViewSwitchCase<DiagramVariableType> case="Integer">
            <IntegerValueSettingInput
              value={value}
              onValueChange={onValueChange}
            />
          </ViewSwitchCase>
          <ViewSwitchCase<DiagramVariableType> case="Orientation">
            <OrientationValueSettingInput
              value={value}
              onValueChange={onValueChange}
            />
          </ViewSwitchCase>
          <ViewSwitchCase<DiagramVariableType> case="Sequence">
            <SequenceValueSettingInput
              value={value}
              onValueChange={onValueChange}
            />
          </ViewSwitchCase>
          <ViewSwitchCase<DiagramVariableType> case="String">
            <StringValueSettingInput
              value={value}
              onValueChange={onValueChange}
            />
          </ViewSwitchCase>
          <ViewSwitchCase<DiagramVariableType> case="Vector">
            <VectorValueSettingInput
              value={value}
              onValueChange={onValueChange}
            />
          </ViewSwitchCase>
        </ViewSwitch>
      </CardContent>
    </Card>
  )
}

export default ValueSetting
