import functionNodeFactoryMap from '../domain/factories/functionNodeFactoryMap'
import FunctionTypeNodeFactory from '../domain/factories/FunctionTypeNodeFactory'
import constantInputNodeFactory from '../domain/factories/constantInputNodeFactory'
import streamInputNodeFactory from '../domain/factories/streamInputNodeFactory'
import NodeFactory from '../domain/factories/NodeFactory'
import outputNodeFactory from '../domain/factories/outputNodeFactory'
import DiagramFunctionType from '../data/models/DiagramFunctionType'

class NodeFactoryService {
  getAllFunctionNodeFactories(): FunctionTypeNodeFactory[] {
    return Object.values(functionNodeFactoryMap)
  }

  getFunctionNodeFactoryByName(
    name: DiagramFunctionType
  ): FunctionTypeNodeFactory | undefined {
    return functionNodeFactoryMap[name]
  }

  getConstantInputNodeFactory(): NodeFactory {
    return constantInputNodeFactory
  }

  getStreamInputNodeFactory(): NodeFactory {
    return streamInputNodeFactory
  }

  getOutputNodeFactory(): NodeFactory {
    return outputNodeFactory
  }
}

export default NodeFactoryService
