import { DiagramCreationConfirmationDialogProps } from './DiagramCreationConfirmationDialog.types'
import { ChangeEvent, useCallback, useMemo, useState } from 'react'

const useDiagramCreationConfirmationDialog = (
  props: DiagramCreationConfirmationDialogProps
) => {
  const { isOpen, onCancel, onCreateDiagram } = props

  const [name, setName] = useState<string>('')
  const [manualLines, setManualLines] = useState<string[]>([])

  const handleConfirm = useCallback(() => {
    setName('')
    setManualLines([])
    onCreateDiagram(name, manualLines)
  }, [manualLines, name, onCreateDiagram])

  const handleCancel = useCallback(() => {
    setName('')
    setManualLines([])
    onCancel()
  }, [onCancel])

  const handleNameChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => setName(event.target.value),
    []
  )

  const isCreateButtonDisabled = useMemo(() => name.length === 0, [name])

  const manual = useMemo(() => manualLines.join('\n'), [manualLines])

  const handleManualChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      setManualLines(event.target.value.split('\n'))
    },
    [setManualLines]
  )

  return {
    isOpen,
    handleCancel,
    handleConfirm,
    handleNameChange,
    name,
    isCreateButtonDisabled,
    manual,
    handleManualChange
  }
}

export default useDiagramCreationConfirmationDialog
