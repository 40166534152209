import Subdiagram from './Subdiagram'

class SubdiagramRepository {
  private _subdiagramMap: Record<number, Subdiagram> = {}

  constructor(subdiagrams: Subdiagram[]) {
    this._subdiagramMap = subdiagrams.reduce<Record<number, Subdiagram>>(
      (previous, current) => {
        previous[current.id] = current
        return previous
      },
      {}
    )
  }

  public add(subdiagram: Subdiagram): void {
    this._subdiagramMap[subdiagram.id] = subdiagram
  }

  public remove(subdiagram: Subdiagram): void {
    delete this._subdiagramMap[subdiagram.id]
  }

  public clear(): void {
    this._subdiagramMap = {}
  }

  public geyById(id: number): Subdiagram | undefined {
    return this._subdiagramMap[id]
  }

  public get all(): Readonly<Readonly<Subdiagram>[]> {
    return Object.values(this._subdiagramMap)
  }
}

export default SubdiagramRepository
