import ValueFactory from './ValueFactory'
import OrientationValue from '../../models/values/OrientationValue'

const OrientationValueFactory: ValueFactory<OrientationValue> = {
  tryCreateWithStringRepresentation(
    representation: string
  ): Promise<OrientationValue> {
    const regex = new RegExp(
      /\((?<x>[0-9.]+), (?<y>[0-9.]+), (?<z>[0-9.]+), (?<w>[0-9.]+)\)/g
    )

    const result = regex.exec(representation)

    if (result === null || !result.groups) {
      return Promise.reject(
        'representation does not conform to the correct format'
      )
    }

    const { x: xString, y: yString, z: zString, w: wString } = result.groups

    if (!xString) {
      return Promise.reject('x capture group was not satisfied')
    }

    if (!yString) {
      return Promise.reject('y capture group was not satisfied')
    }

    if (!zString) {
      return Promise.reject('z capture group was not satisfied')
    }

    if (!wString) {
      return Promise.reject('w capture group was not satisfied')
    }

    const x = parseFloat(xString)
    const y = parseFloat(yString)
    const z = parseFloat(zString)
    const w = parseFloat(wString)

    if (isNaN(x)) {
      return Promise.reject('x was not an float')
    }

    if (isNaN(y)) {
      return Promise.reject('y was not an float')
    }

    if (isNaN(z)) {
      return Promise.reject('z was not an float')
    }

    if (isNaN(w)) {
      return Promise.reject('w was not an float')
    }

    return Promise.resolve(new OrientationValue(x, y, z, w))
  }
}

export default OrientationValueFactory
