import { z } from 'zod'
import DiagramSubdiagramDataModel from '../models/DiagramSubdiagramDataModel'

const diagramSubdiagramDataModelValidator: z.ZodType<DiagramSubdiagramDataModel> =
  z.object({
    nodeIds: z.array(z.number()),
    description: z.string()
  })

export default diagramSubdiagramDataModelValidator
