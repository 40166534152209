import DiagramRepository from './DiagramRepository'
import Diagram from '../domain/models/Diagram'
import diagramFactory from './diagramFactory'
import diagramDomainModelToDiagramDataModelTransformer from '../domain/transformation/toData/diagramDomainModelToDiagramDataModelTransformer'
import NodeFactoryService from './NodeFactoryService'
import DataTypeService from './DataTypeService'
import diagramDataModelToDiagramDomainModelTransformer from '../data/transformation/toDomain/diagramDataModelToDiagramDomainModelTransformer'
import DiagramDataModel from '../data/models/DiagramDataModel'
import short from 'short-uuid'
import DiagramView from '../domain/models/DiagramView'
import diagramViewDomainModelToDiagramDataModelTransformer from '../domain/transformation/toData/diagramViewDomainModelToDiagramDataModelTransformer'

class DiagramService {
  private readonly _diagramRepository: DiagramRepository

  public readonly dataTypeService: DataTypeService

  public readonly nodeFactoryService: NodeFactoryService

  constructor(
    diagramRepository: DiagramRepository,
    nodeFactoryService: NodeFactoryService,
    dataTypeService: DataTypeService
  ) {
    this._diagramRepository = diagramRepository
    this.nodeFactoryService = nodeFactoryService
    this.dataTypeService = dataTypeService
  }

  async allDiagrams(): Promise<Diagram[]> {
    return this._diagramRepository.all()
  }

  async tryGetDiagramByUuid(uuid: string): Promise<Diagram> {
    return this._diagramRepository.tryGetByUuid(uuid)
  }

  async updateDiagram(diagram: Diagram): Promise<void> {
    return this._diagramRepository.set(diagram)
  }

  createJsonStringFromDiagram(diagram: Diagram): string {
    const data = diagramDomainModelToDiagramDataModelTransformer.transform(
      diagram,
      undefined
    )
    return JSON.stringify(data.model, undefined, 4)
  }

  public createJsonStringFromDiagramView(diagramView: DiagramView): string {
    const model = diagramViewDomainModelToDiagramDataModelTransformer.transform(
      diagramView,
      undefined
    )
    return JSON.stringify(model, undefined, 4)
  }

  async deleteDiagramByUuid(uuid: string): Promise<void> {
    return this._diagramRepository.delete(uuid)
  }

  async duplicateDiagramByUuid(uuid: string): Promise<void> {
    const diagram = await this._diagramRepository.tryGetByUuid(uuid)

    const data = diagramDomainModelToDiagramDataModelTransformer.transform(
      diagram,
      undefined
    )

    const domain = diagramDataModelToDiagramDomainModelTransformer.transform(
      data,
      undefined
    )

    const duplicatedDiagram = diagramFactory.create(
      `${domain.name} (duplicate)`,
      domain.manualLines,
      domain.nodeRepository,
      domain.connectionRepository,
      domain.nodeIdService,
      undefined,
      domain.subdiagramRepository
    )

    await this._diagramRepository.set(duplicatedDiagram)
  }

  async createDiagram(
    name = 'MyDiagram',
    manualLines: string[] = []
  ): Promise<Diagram> {
    const diagram = diagramFactory.create(name, manualLines)

    await this._diagramRepository.set(diagram)

    return diagram
  }

  public createDiagramViewDataModel(
    diagramDataModel: DiagramDataModel
  ): DiagramView {
    const diagram = diagramDataModelToDiagramDomainModelTransformer.transform(
      {
        uuid: short.generate(),
        name: '',
        model: diagramDataModel
      },
      undefined
    )
    return diagram.createDiagramView()
  }

  async importDiagram(name: string, diagramDataModel: DiagramDataModel) {
    // TODO: Write factory method
    const diagram = diagramDataModelToDiagramDomainModelTransformer.transform(
      {
        uuid: short.generate(),
        name,
        model: diagramDataModel
      },
      undefined
    )

    await this._diagramRepository.set(diagram)
  }
}

export default DiagramService
