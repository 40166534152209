import Value from './Value'

class SequenceValue implements Value {
  public values: string[]

  constructor(values: string[] = []) {
    this.values = values
  }

  toString(): string {
    return `[${this.values.reduce<string>(
      (previousValue, currentValue, currentIndex, array) =>
        `${previousValue}${currentValue}${
          currentIndex !== array.length - 1 ? ', ' : ''
        }`,
      ''
    )}]`
  }
}

export default SequenceValue
