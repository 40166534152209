import * as React from 'react'
import Typography from '@mui/material/Typography'
import {
  AppBar,
  Box,
  Button,
  DialogContentText,
  Paper,
  Stack,
  Toolbar
} from '@mui/material'
import { Helmet } from 'react-helmet-async'
import DiagramList from '../DiagramList'
import NoDiagramsAvailable from '../NoDiagramsAvailable'
import NoDiagramSelected from '../NoDiagramSelected'
import DiagramDetail from '../DiagramDetail'
import useDiagramsPage from './useDiagramsPage'
import { AddOutlined, FileUploadOutlined } from '@mui/icons-material'
import DiagramCreationConfirmationDialog from '../DiagramCreationConfirmationDialog'
import ConfirmationDialog from '../ConfirmationDialog'

const DiagramsPage = () => {
  const {
    selectedDiagram,
    diagrams,
    handleSelectDiagram,
    isDiagramCreationDialogOpen,
    closeDiagramCreationDialog,
    handleCreateDiagram,
    openDiagramCreationDialog,
    handleDuplicateDiagram,
    handleDeleteDiagram,
    openDiagramDeletionDialog,
    closeDiagramDeletionDialog,
    isDiagramDeletionDialogOpen,
    handleImportDiagram,
    handleDownloadDiagramAsImage,
    handleDownloadDiagramAsJson
  } = useDiagramsPage()

  return (
    <>
      <Helmet>
        <title>Dataflow Diagram Editor - Diagrams</title>
      </Helmet>
      <DiagramCreationConfirmationDialog
        isOpen={isDiagramCreationDialogOpen}
        onCancel={closeDiagramCreationDialog}
        onCreateDiagram={handleCreateDiagram}
      />
      {selectedDiagram && (
        <ConfirmationDialog
          title={`Confirm delete '${selectedDiagram?.name}'`}
          onCancel={closeDiagramDeletionDialog}
          onConfirmButtonRender={(confirm, text) => (
            <Button variant="contained" color="error" onClick={confirm}>
              {text}
            </Button>
          )}
          onConfirm={handleDeleteDiagram}
          confirmButtonText="Delete"
          isOpen={isDiagramDeletionDialogOpen}
        >
          <DialogContentText>
            Are you sure you want to delete '{selectedDiagram?.name}'
          </DialogContentText>
        </ConfirmationDialog>
      )}

      <Box sx={{ height: '100%', width: '100%', display: 'flex' }}>
        <Box sx={{ flexGrow: 1 }}>
          <AppBar
            position="static"
            variant="outlined"
            elevation={0}
            color="transparent"
          >
            <Toolbar>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                Diagrams
              </Typography>
              <Stack spacing={1} direction="row">
                <Button
                  endIcon={<AddOutlined />}
                  variant="contained"
                  size="small"
                  onClick={openDiagramCreationDialog}
                >
                  Create
                </Button>
                <Button
                  component="label"
                  endIcon={<FileUploadOutlined />}
                  variant="contained"
                  size="small"
                >
                  Import
                  <input
                    type="file"
                    accept=".dfd"
                    hidden
                    onChange={handleImportDiagram}
                  />
                </Button>
              </Stack>
            </Toolbar>
          </AppBar>
          <Box
            sx={{ height: 'calc(100% - 70px)', width: '100%', display: 'flex' }}
          >
            <Paper
              variant="outlined"
              sx={{
                height: '100%',
                width: '350px',
                borderTop: 0,
                borderRadius: 0,
                borderBottom: 0,
                flexGrow: 1,
                overflow: 'auto'
              }}
            >
              {diagrams.length > 0 ? (
                <DiagramList
                  diagrams={diagrams}
                  onSelectDiagram={handleSelectDiagram}
                  selectedDiagram={selectedDiagram}
                />
              ) : (
                <NoDiagramsAvailable
                  onCreate={openDiagramCreationDialog}
                  onImport={handleImportDiagram}
                />
              )}
            </Paper>
            <Box
              sx={{
                width: 'calc(100% - 350px);',
                height: '100%',
                position: 'relative'
              }}
            >
              {selectedDiagram ? (
                <DiagramDetail
                  diagram={selectedDiagram}
                  onDuplicate={handleDuplicateDiagram}
                  onDelete={openDiagramDeletionDialog}
                  onDownloadAsJson={handleDownloadDiagramAsJson}
                  onDownloadAsImage={handleDownloadDiagramAsImage}
                />
              ) : (
                <NoDiagramSelected />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default DiagramsPage
