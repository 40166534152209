import Transformer from '../../../transformation/Transformer'
import Connection from '../../models/Connection'
import DiagramConnectionDataModel from '../../../data/models/DiagramConnectionDataModel'
import { parseNodeId, parseTargetHandleId } from '../../utils/idParsers'

const connectionToDiagramConnectionDataModelTransformer: Transformer<
  Readonly<Connection>,
  DiagramConnectionDataModel
> = {
  transform: (connection) => ({
    outputNodeId: parseNodeId(connection.source.node.getId()),
    inputNodeId: parseNodeId(connection.target.node.getId()),
    inputIndex: parseTargetHandleId(connection.target.id).inputIndex
  })
}

export default connectionToDiagramConnectionDataModelTransformer
