import Transformer from '../../../transformation/Transformer'
import Node from '../../models/Node'
import DiagramPropertyDataModel from '../../../data/models/DiagramPropertyDataModel'
import nodeToDiagramPropertiesDataModelTransformer from './nodeToDiagramPropertiesDataModelTransformer'

const nodesToDiagramPropertiesDataModelTransformer: Transformer<
  Node[],
  DiagramPropertyDataModel[]
> = {
  transform: (nodes) => {
    const properties: DiagramPropertyDataModel[] = []

    for (const node of nodes) {
      properties.push(
        ...nodeToDiagramPropertiesDataModelTransformer.transform(
          node,
          undefined
        )
      )
    }

    return properties
  }
}

export default nodesToDiagramPropertiesDataModelTransformer
