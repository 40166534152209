import { DiagramDetailProps } from './DiagramDetail.types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import diagramToReactFlowDiagramTransformer from '../../features/domain/transformation/toPresentation/diagramToReactFlowDiagramTransformer'
import { ReactFlowInstance } from 'reactflow'
import { useNavigate } from 'react-router-dom'
import Subdiagram from '../../features/domain/models/Subdiagram'
import useForceRender from '../../shared/useForceRender'

const useDiagramDetail = (props: DiagramDetailProps) => {
  const {
    diagram,
    onDuplicate,
    onDelete,
    onDownloadAsImage,
    onDownloadAsJson
  } = props

  const forceRenderView = useForceRender()

  const navigate = useNavigate()

  const [reactFlowInstance, setReactFlowInstance] = useState<
    ReactFlowInstance | undefined
  >(undefined)

  const { nodes, edges: initialEdges } = useMemo(
    () =>
      diagram
        ? diagramToReactFlowDiagramTransformer.transform(
            diagram.createDiagramView(),
            undefined
          )
        : {
            nodes: [],
            edges: []
          },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [diagram, forceRenderView]
  )

  const edges = useMemo(
    () => initialEdges.map((edge) => ({ ...edge, animated: false })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initialEdges, forceRenderView]
  )

  useEffect(() => {
    if (reactFlowInstance && nodes) {
      setTimeout(reactFlowInstance.fitView)
    }
  }, [nodes, reactFlowInstance])

  const manual = useMemo(
    () => diagram.manualLines.join('\n'),
    [diagram.manualLines]
  )

  const handleEditClick = useCallback(
    () => navigate(diagram.uuid),
    [diagram.uuid, navigate]
  )

  const handleReactFlowInit = useCallback(
    (instance: ReactFlowInstance) => setReactFlowInstance(instance),
    []
  )

  const handleSubdiagramSelect = useCallback(
    (subdiagram: Subdiagram | undefined) => {
      diagram.setSelectedSubdiagram(subdiagram)
      forceRenderView()
    },
    [diagram, forceRenderView]
  )

  return {
    name: diagram.name,
    subdiagrams: diagram.subdiagrams,
    selectedSubdiagram: diagram.selectedSubdiagram,
    manual,
    nodes,
    handleEditClick,
    handleReactFlowInit,
    edges,
    onDuplicate,
    onDelete,
    onDownloadAsJson,
    onDownloadAsImage,
    handleSubdiagramSelect
  }
}

export default useDiagramDetail
