import {
  Box,
  Card,
  CardContent,
  Divider,
  IconButton,
  Stack,
  Typography
} from '@mui/material'
import React from 'react'
import { CloseOutlined } from '@mui/icons-material'
import { NodeDetailProps } from './NodeDetail.types'

const NodeDetail = (props: NodeDetailProps) => {
  const { children, open, close } = props

  return (
    <Card
      variant="outlined"
      sx={{
        width: '500px',
        height: '100%',
        borderTop: 0,
        borderRadius: 0,
        borderBottom: 0,
        display: open ? 'block' : 'none'
      }}
    >
      <CardContent>
        <Stack direction="row" sx={{ marginBottom: 1 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
            <Typography variant="h6">Node details</Typography>
          </Box>
          <IconButton color="inherit" onClick={close}>
            <CloseOutlined />
          </IconButton>
        </Stack>
        <Divider sx={{ marginBottom: 2 }} />
        {children}
      </CardContent>
    </Card>
  )
}

export default NodeDetail
