import Node from '../models/Node'
import Inputable from '../models/Inputable'
import Outputable from '../models/Outputable'

const nodeUtils = {
  isInputable: (node: Node): boolean => {
    const maybeInputable = node as unknown as Inputable

    return (
      typeof maybeInputable.getInputHandles === 'function' &&
      typeof maybeInputable.getInputHandleById === 'function'
    )
  },

  isOutputable: (node: Node): boolean => {
    const maybeOutputable = node as unknown as Outputable

    return typeof maybeOutputable.getOutputHandle === 'function'
  }
}

export default nodeUtils
