import { Paper, Stack } from '@mui/material'
import { DeselectOutlined } from '@mui/icons-material'
import Typography from '@mui/material/Typography'

const NoDiagramSelected = () => {
  return (
    <Paper
      variant="outlined"
      sx={{
        paddingX: 3,
        paddingY: 10,
        margin: 6
      }}
    >
      <Stack direction="column" alignItems="center" spacing={1}>
        <DeselectOutlined
          color="disabled"
          sx={{ fontSize: 110, marginBottom: 0.5 }}
        />
        <Typography variant="h6" sx={{ textAlign: 'center' }}>
          No diagram selected
        </Typography>
        <Typography variant="body2" sx={{ textAlign: 'center' }}>
          Select a diagram to see a preview and actions.
        </Typography>
      </Stack>
    </Paper>
  )
}

export default NoDiagramSelected
