import { NoDiagramsFoundProps } from './NoDiagramsFound.types'
import { Stack } from '@mui/material'
import { ClearOutlined, SearchOffOutlined } from '@mui/icons-material'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

const NoDiagramsFound = (props: NoDiagramsFoundProps) => {
  const { onClearSearchTerm, searchTerm } = props

  return (
    <Stack
      direction="column"
      alignItems="center"
      spacing={1}
      sx={{ margin: 4 }}
    >
      <SearchOffOutlined
        color="disabled"
        sx={{ fontSize: 80, marginBottom: 2 }}
      />
      <Typography variant="body2" sx={{ textAlign: 'center' }}>
        No diagrams found for '{searchTerm}'
        <br />
        <Button
          endIcon={<ClearOutlined />}
          size="small"
          onClick={onClearSearchTerm}
        >
          Clear search
        </Button>
      </Typography>
    </Stack>
  )
}

export default NoDiagramsFound
