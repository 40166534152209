import Transformer from '../../../transformation/Transformer'
import Node from '../../models/Node'
import { Node as ReactFlowNode } from 'reactflow'
import nodeToReactFlowNodeTransformer from './nodeToReactFlowNodeTransformer'

const nodesToReactFlowNodesTransformer: Transformer<Node[], ReactFlowNode[]> = {
  transform: (nodes) =>
    nodes.map((node) =>
      nodeToReactFlowNodeTransformer.transform(node, undefined)
    )
}

export default nodesToReactFlowNodesTransformer
