import Connection from './Connection'
import handleUtils from '../utils/handleUtils'
import Handle from './Handle'
import DataType from './dataTypes/DataType'
import Generic from './Generic'
import Node from './Node'
import Variant from './Variant'
import { formatHandle } from '../utils/idFormatters'
import Constraint from './Constraint'
import AnyConstraint from './AnyConstraint'

class GenericHandle<TVariant extends Variant = Variant>
  implements
    Handle<TVariant, DataType | undefined>,
    Generic<DataType | undefined>
{
  readonly variant: TVariant

  readonly index: number

  readonly node: Node

  protected _dataType: DataType | undefined

  readonly constraint: Constraint

  constructor(
    node: Node,
    variant: TVariant,
    constraint: Constraint = new AnyConstraint(),
    dataType?: DataType,
    index = 0
  ) {
    this.node = node
    this.variant = variant
    this.constraint = constraint
    this._dataType = constraint.modify(dataType)
    this.index = index
  }

  get dataType(): DataType | undefined {
    return this._dataType
  }

  set dataType(dataType: DataType | undefined) {
    this._dataType = this.constraint.modify(dataType)
  }

  get id(): string {
    return formatHandle(this.variant.toString(), this.node.getId(), this.index)
  }

  async createConnection(handle: Handle): Promise<Connection> {
    await handleUtils.canHandlesBeConnected(this, handle)
    return handleUtils.createConnection(handle, this)
  }

  isCompatibleWith(handle: Handle): boolean {
    return this._dataType && handle.dataType
      ? this._dataType?.isCompatible(handle.dataType)
      : this.constraint.conforms(handle.dataType)
  }

  get symbol(): string {
    return this.constraint.getSymbol(this._dataType)
  }
}

export default GenericHandle
