import { Stack } from '@mui/material'
import React from 'react'
import DescriptionSetting from '../DescriptionSetting'
import { ContainerNodeSettingsProps } from './ContainerNodeSettings.types'
import ColorSetting from '../ColorSetting'

const ContainerNodeSettings = (props: ContainerNodeSettingsProps) => {
  const { node, onChangeDescription, onChangeColor } = props

  return (
    <Stack spacing={2}>
      <DescriptionSetting
        description={node?.properties?.Description}
        onDescriptionChange={onChangeDescription}
      />
      <ColorSetting color={node.color} onColorChange={onChangeColor} />
    </Stack>
  )
}

export default ContainerNodeSettings
