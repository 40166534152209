import { HiddenSettingProps } from './HiddenSetting.types'
import { FormControlLabel, Switch } from '@mui/material'
import React from 'react'
import useHiddenSetting from './useHiddenSetting'

const HiddenSetting = (props: HiddenSettingProps) => {
  const { hidden, handleHiddenChange } = useHiddenSetting(props)

  return (
    <>
      <FormControlLabel
        control={
          <>
            <Switch checked={hidden} onChange={handleHiddenChange} />
          </>
        }
        label="Hidden"
      />
    </>
  )
}

export default HiddenSetting
