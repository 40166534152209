import { formatNodeId } from '../utils/idFormatters'

class NodeIdService {
  private _lastNodeId: number

  constructor(lastNodeId = 0) {
    this._lastNodeId = lastNodeId
  }

  public getNextNodeId(): string {
    return formatNodeId(this._lastNodeId + 1)
  }

  public incrementId(): void {
    this._lastNodeId += 1
  }
}

export default NodeIdService
