import Transformer from '../../../transformation/Transformer'
import DiagramView from '../../models/DiagramView'
import ReactFlowDiagram from '../../../presentation/ReactFlowDiagram'
import connectionsToReactFlowEdgesTransformer from './connectionsToReactFlowEdgesTransformer'
import nodesToReactFlowNodesTransformer from './nodesToReactFlowNodesTransformer'

const diagramToReactFlowDiagramTransformer: Transformer<
  DiagramView,
  ReactFlowDiagram
> = {
  transform: ({ nodes, connections }) => ({
    nodes: nodesToReactFlowNodesTransformer.transform(nodes, undefined),
    edges: connectionsToReactFlowEdgesTransformer.transform(
      connections,
      undefined
    )
  })
}

export default diagramToReactFlowDiagramTransformer
