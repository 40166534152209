import MultipleInputsNode from '../../../components/MultipleInputsNode'
import StreamInputNode from '../../../components/StreamInputNode'
import OutputNode from '../../../components/OutputNode'
import ConstantInputNode from '../../../components/ConstantInputNode'

const nodeTypes = {
  multipleInputs: MultipleInputsNode,
  shapeInput: StreamInputNode,
  shapeOutput: OutputNode,
  constantInput: ConstantInputNode
}

export default nodeTypes
