import DraggedNode from '../DraggedNode'
import DraggedNodeType from '../DraggedNodeType'
import DraggedNodeDataTypeableNodeData from '../DraggedNodeDataTypeableNodeData'
import DiagramVariableType from '../../data/models/DiagramVariableType'

const draggedDataTypeableNodeFactory = {
  create: (
    type: DraggedNodeType,
    dataType: DiagramVariableType
  ): DraggedNode<DraggedNodeDataTypeableNodeData> => ({
    type,
    data: {
      dataType
    }
  })
}

export default draggedDataTypeableNodeFactory
