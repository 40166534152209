import { SubdiagramTabsProps } from './SubdiagramTabs.types'
import useSubdiagramTabs from './useSubdiagramTabs'
import { Box, Divider, Tab, Tabs } from '@mui/material'
import React from 'react'

const SubdiagramTabs = (props: SubdiagramTabsProps) => {
  const { subdiagrams, handleChange, selectedTab } = useSubdiagramTabs(props)

  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: 'white',
        display: 'grid'
      }}
    >
      <Tabs
        value={selectedTab}
        variant="scrollable"
        scrollButtons="auto"
        onChange={handleChange}
      >
        <Tab
          value="none"
          sx={{
            textTransform: 'none'
          }}
          label={<i>default</i>}
        />
        {subdiagrams.map((subdiagram) => (
          <Tab
            key={subdiagram.id}
            sx={{
              textTransform: 'none'
            }}
            value={subdiagram}
            label={
              subdiagram.description.length > 0
                ? subdiagram.description
                : `[Subdiagram]`
            }
          />
        ))}
      </Tabs>
      <Divider />
    </Box>
  )
}

export default SubdiagramTabs
