import { Stack } from '@mui/material'
import { AddOutlined, TabOutlined } from '@mui/icons-material'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import * as React from 'react'
import { NoSubdiagramsAvailableProps } from './NoSubdiagramsAvailable.types'

const NoSubdiagramsAvailable = (props: NoSubdiagramsAvailableProps) => {
  const { onCreate } = props

  return (
    <Stack
      direction="column"
      alignItems="center"
      spacing={1}
      sx={{ margin: 4 }}
    >
      <TabOutlined color="disabled" sx={{ fontSize: 80, marginBottom: 2 }} />
      <Typography variant="body2" sx={{ textAlign: 'center' }}>
        It seems a bit empty here.
        <br />
        <Button endIcon={<AddOutlined />} size="small" onClick={onCreate}>
          Create
        </Button>
        your first subdiagram to get started.
      </Typography>
    </Stack>
  )
}

export default NoSubdiagramsAvailable
