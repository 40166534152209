import { DescriptionSettingProps } from './DescriptionSetting.types'
import { TextField } from '@mui/material'
import React from 'react'
import useDescriptionSetting from './useDescriptionSetting'

const DescriptionSetting = (props: DescriptionSettingProps) => {
  const { handleDescriptionChange, description, error, helperText } =
    useDescriptionSetting(props)

  return (
    <TextField
      label="Description"
      fullWidth
      value={description}
      onChange={handleDescriptionChange}
      inputProps={{ maxLength: 32 }}
      error={error}
      helperText={helperText}
    />
  )
}

export default DescriptionSetting
