import { DependencyList, MutableRefObject, useMemo } from 'react'

const useRefValueOrDefault = <TRef, TValue>(
  ref: MutableRefObject<TRef>,
  getValue: (ref: Exclude<TRef, undefined>) => TValue,
  orDefault: TValue,
  deps: DependencyList
): TValue =>
  useMemo(
    () =>
      ref.current
        ? getValue(ref.current as Exclude<TRef, undefined>)
        : orDefault,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps
  )

export default useRefValueOrDefault
