import { ConfirmationDialogProps } from './ConfirmationDialog.types'
import { useCallback, useMemo } from 'react'

const useConfirmationDialog = (props: ConfirmationDialogProps) => {
  const {
    title,
    children,
    onConfirmButtonRender,
    confirmButtonText,
    onConfirm,
    isOpen,
    onCancel
  } = props

  const confirmButtonTextOrDefault = useMemo(
    () => confirmButtonText ?? 'confirm',
    [confirmButtonText]
  )

  const childrenOrDefault = useMemo(
    () => children ?? 'do you want to confirm this action?',
    [children]
  )

  const truncate = useCallback(
    (str: string, maxStringLength: number): string => {
      return str.length > maxStringLength
        ? str.slice(0, maxStringLength - 1) + `...'`
        : str
    },
    []
  )

  return {
    title,
    onConfirmButtonRender,
    confirmButtonTextOrDefault,
    childrenOrDefault,
    confirmButtonText,
    onConfirm,
    onCancel,
    isOpen,
    truncate
  }
}

export default useConfirmationDialog
