import { ValueSettingInputProps } from '../ValueSettingInput/ValueSettingInput.types'
import { Stack, TextField } from '@mui/material'
import useOrientationValueSettingInput from './useOrientationValueSettingInput'

const OrientationValueSettingInput = (props: ValueSettingInputProps) => {
  const {
    orientation,
    handleXChange,
    handleYChange,
    handleZChange,
    handleWChange
  } = useOrientationValueSettingInput(props)

  return (
    <Stack spacing={2}>
      <TextField
        type="number"
        label="x"
        fullWidth
        value={orientation.x}
        onChange={handleXChange}
      />
      <TextField
        type="number"
        label="y"
        fullWidth
        value={orientation.y}
        onChange={handleYChange}
      />
      <TextField
        type="number"
        label="z"
        fullWidth
        value={orientation.z}
        onChange={handleZChange}
      />
      <TextField
        type="number"
        label="w"
        fullWidth
        value={orientation.w}
        onChange={handleWChange}
      />
    </Stack>
  )
}

export default OrientationValueSettingInput
