import Transformer from '../../../transformation/Transformer'
import { Node as ReactFlowNode } from 'reactflow'
import reactFlowNodeFactory from './factories/reactFlowNodeFactory'
import FunctionNode from '../../models/FunctionNode'
import FunctionNodeData from '../../../presentation/FunctionNodeData'
import handleToReactFlowHandleTransformer from './handleToReactFlowHandleTransformer'
import mapperTransformerFactory from '../../../transformation/factories/mapperTransformerFactory'

const functionNodeToReactFlowFunctionNodeTransformer: Transformer<
  FunctionNode,
  ReactFlowNode<FunctionNodeData>
> = {
  transform: (node) =>
    reactFlowNodeFactory.create<FunctionNodeData>(
      node,
      {
        color: node.color,
        label: node.properties.Description ?? node.name,
        inputs: mapperTransformerFactory
          .create(handleToReactFlowHandleTransformer)
          .transform(node.getInputHandles(), undefined),
        output: handleToReactFlowHandleTransformer.transform(
          node.getOutputHandle(),
          undefined
        )
      },
      {
        type: 'multipleInputs'
      }
    )
}

export default functionNodeToReactFlowFunctionNodeTransformer
