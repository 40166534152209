import ValueFactory from './ValueFactory'
import BooleanValue from '../../models/values/BooleanValue'

const booleanValueFactory: ValueFactory<BooleanValue> = {
  tryCreateWithStringRepresentation(
    representation: string
  ): Promise<BooleanValue> {
    if (representation === 'true') {
      return Promise.resolve(new BooleanValue(true))
    }

    if (representation === 'false') {
      return Promise.resolve(new BooleanValue(false))
    }

    return Promise.reject()
  }
}

export default booleanValueFactory
