import { DescriptionSettingProps } from './DescriptionSetting.types'
import { ChangeEvent, useCallback } from 'react'

const useDescriptionSetting = (props: DescriptionSettingProps) => {
  const { description = '', onDescriptionChange, error, helperText } = props

  const handleDescriptionChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onDescriptionChange(
        event.target.value.length === 0 ? undefined : event.target.value
      )
    },
    [onDescriptionChange]
  )

  return {
    description,
    handleDescriptionChange,
    error,
    helperText
  }
}

export default useDescriptionSetting
