import { NodeProps, Position } from 'reactflow'
import OutputNodeData from '../../features/presentation/OutputNodeData'
import DatatypeHandle from '../DatatypeHandle'
import Label from '../Label'
import useOutputNode from './useOutputNode'
import './OutputNode.styles.css'

const OutputNode = (props: NodeProps<OutputNodeData>) => {
  const {
    label,
    labelColor,
    input,
    selected,
    color,
    hasUniqueDescription,
    looping,
    hidden
  } = useOutputNode(props)

  return (
    <div style={{ position: 'relative' }}>
      <svg
        style={{ display: 'block' }}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 351.31 179"
      >
        {hidden && (
          <>
            <line
              fill="none"
              stroke="gray"
              strokeWidth={10}
              x1="317.78"
              y1="74.9"
              x2="347.78"
              y2="104.9"
            />
            <line
              fill="none"
              stroke="gray"
              strokeWidth={10}
              x1="347.78"
              y1="74.9"
              x2="317.78"
              y2="104.9"
            />
          </>
        )}
        <polygon
          fill={color ?? '#fff'}
          className={selected ? 'selected-node-outline' : 'node-stroke'}
          points="292.5 89.9 232.5 174.9 2.5 174.9 2.5 4.9 232.5 4.9 292.5 89.9"
        />
        {(!hasUniqueDescription || looping || hidden) && (
          <polyline
            strokeLinecap="round"
            fill="none"
            stroke={!hasUniqueDescription ? 'red' : 'gray'}
            strokeWidth={6}
            points="247.22 4.5 307.22 89.5 247.22 174.5"
          />
        )}
      </svg>
      <DatatypeHandle
        id={input.id}
        type="target"
        position={Position.Left}
        symbol={input.symbol}
      />
      <Label
        style={{
          width: 'calc(100% - 80px)'
        }}
        color={labelColor}
      >
        {label}
      </Label>
    </div>
  )
}

export default OutputNode
