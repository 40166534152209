import Node from '../models/Node'
import ContainerNode from '../models/ContainerNode'

const containerUtils = {
  createNodeIdContainerMap: (nodes: Node[]): Record<string, ContainerNode> => {
    const nodeIdContainerIdMap: Record<string, ContainerNode> = {}
    for (const node of nodes) {
      if (node instanceof ContainerNode) {
        const container = node

        const childNodeIds = node.getChildNodeIds()
        for (const childNodeId of childNodeIds) {
          nodeIdContainerIdMap[childNodeId] = container
        }
      }
    }

    return nodeIdContainerIdMap
  },

  createNodeIdContainerIdMap: (nodes: Node[]): Record<string, string> => {
    const nodeIdContainerIdMap: Record<string, string> = {}
    for (const node of nodes) {
      if (node instanceof ContainerNode) {
        const containerId = node.getId()

        const childNodeIds = node.getChildNodeIds()
        for (const childNodeId of childNodeIds) {
          nodeIdContainerIdMap[childNodeId] = containerId
        }
      }
    }

    return nodeIdContainerIdMap
  },

  flattenNodes: (nodes: Node[]): Node[] => {
    const flattened: Node[] = []
    for (const node of nodes) {
      if (node instanceof ContainerNode) {
        flattened.push(...containerUtils.flattenNodes(node.getChildNodes()))
        continue
      }

      flattened.push(node)
    }

    return flattened
  }
}

export default containerUtils
