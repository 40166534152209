import { HiddenSettingProps } from './HiddenSetting.types'
import { ChangeEvent, useCallback } from 'react'

const useHiddenSetting = (props: HiddenSettingProps) => {
  const { hidden, onHiddenChange } = props

  const handleHiddenChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      onHiddenChange(checked)
    },
    [onHiddenChange]
  )

  return {
    hidden,
    handleHiddenChange
  }
}

export default useHiddenSetting
