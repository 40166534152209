import ValueFactory from './ValueFactory'
import IntegerValue from '../../models/values/IntegerValue'

const integerValueFactory: ValueFactory<IntegerValue> = {
  tryCreateWithStringRepresentation(
    representation: string
  ): Promise<IntegerValue> {
    const maybeInteger = parseInt(representation)

    if (isNaN(maybeInteger)) {
      return Promise.reject('value was not parseable to a number')
    }

    return Promise.resolve(new IntegerValue(maybeInteger))
  }
}

export default integerValueFactory
