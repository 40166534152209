import DiagramDataModel from '../../models/Diagram'
import Transformer from '../../../transformation/Transformer'
import diagramPositionDataModelsToCompositeNodePositionsPartialMapTransformer from './diagramPositionDataModelsToCompositeNodePositionsPartialMapTransformer'
import diagramNodeDataModelToCompositeNodeNodePartialMapTransformer from './diagramNodeDataModelToCompositeNodeNodePartialMapTransformer'
import diagramPropertyDataModelsToCompositeNodePropertiesPartialMapTransformer from './diagramPropertyDataModelsToCompositeNodePropertiesPartialMapTransformer'
import compositeNodePartialMapsToCompositeNodesTransformer from './compositeNodePartialMapsToCompositeNodesTransformer'
import diagramConnectionDataModelsToConnectionsTransformer from './diagramConnectionDataModelsToConnectionsTransformer'
import ConnectionRepository from '../../../domain/models/ConnectionRepository'
import DiagramDomainModel from '../../../domain/models/Diagram'
import compositeNodesToNodesTransformer from './compositeNodesToNodesTransformer'
import NodeIdService from '../../../domain/models/NodeIdService'
import diagramNodeDataModelToHighestNodeIdTransformer from './diagramNodeDataModelToHighestNodeIdTransformer'
import nodesToNodesWithContainerNodesTransformer from './nodesToNodesWithContainerNodesTranformer'
import NodeSelection from '../../../domain/models/NodeSelection'
import NodeRepository from '../../../domain/models/NodeRepository'
import SubdiagramRepository from '../../../domain/models/SubdiagramRepository'
import diagramSubdiagramDataModelsToSubdiagramsTransformer from './diagramSubdiagramDataModelToSubdiagramsTransformer'
import SubdiagramIdService from '../../../domain/models/SubdiagramIdService'

const diagramDataModelToDiagramDomainModelTransformer: Transformer<
  DiagramDataModel,
  DiagramDomainModel
> = {
  transform: (diagramDataModel) => {
    const {
      uuid,
      name,
      model: {
        manualLines,
        positions: dataPositions,
        nodes: dataNodes,
        properties: dataProperties,
        containers: dataContainers,
        connections: dataConnections,
        subdiagrams: dataSubdiagrams
      }
    } = diagramDataModel

    const highestNodeId =
      diagramNodeDataModelToHighestNodeIdTransformer.transform(
        dataNodes,
        undefined
      )

    const nodeIdService = new NodeIdService(highestNodeId)

    const nodeSelection = new NodeSelection()

    const nodeRepository = new NodeRepository([])

    const nodeIdPositionMap =
      diagramPositionDataModelsToCompositeNodePositionsPartialMapTransformer.transform(
        dataPositions,
        undefined
      )

    const nodeIdNodePartialMap =
      diagramNodeDataModelToCompositeNodeNodePartialMapTransformer.transform(
        dataNodes,
        undefined
      )

    const nodeIdPropertiesMap =
      diagramPropertyDataModelsToCompositeNodePropertiesPartialMapTransformer.transform(
        dataProperties,
        undefined
      )

    const nodeIdCompositeNodeMap =
      compositeNodePartialMapsToCompositeNodesTransformer.transform(
        {
          nodeMap: nodeIdNodePartialMap,
          positionMap: nodeIdPositionMap,
          propertiesMap: nodeIdPropertiesMap
        },
        undefined
      )

    const nodes = compositeNodesToNodesTransformer.transform(
      Object.values(nodeIdCompositeNodeMap),
      {
        nodeSelection,
        nodeRepository
      }
    )

    const connections =
      diagramConnectionDataModelsToConnectionsTransformer.transform(
        dataConnections,
        { nodes }
      )

    const connectionRepository = new ConnectionRepository(connections)

    const nodesWithContainers =
      nodesToNodesWithContainerNodesTransformer.transform(nodes, {
        containers: dataContainers,
        connectionRepository,
        nodeSelection
      })

    nodeRepository.set(nodesWithContainers)

    const highestSubdiagramId = dataSubdiagrams
      ? dataSubdiagrams?.length - 1
      : 0

    const subdiagramIdService = new SubdiagramIdService(highestSubdiagramId)

    const subdiagrams =
      diagramSubdiagramDataModelsToSubdiagramsTransformer.transform(
        dataSubdiagrams,
        {
          nodeRepository
        }
      )

    const subdiagramRepository = new SubdiagramRepository(subdiagrams)

    return new DiagramDomainModel(
      uuid,
      name,
      nodeRepository,
      connectionRepository,
      manualLines ?? [],
      nodeIdService,
      nodeSelection,
      subdiagramRepository,
      subdiagramIdService
    )
  }
}

export default diagramDataModelToDiagramDomainModelTransformer
