import DataType from '../domain/models/dataTypes/DataType'
import Float from '../domain/models/dataTypes/Float'
import Integer from '../domain/models/dataTypes/Integer'
import Vector from '../domain/models/dataTypes/Vector'
import BooleanType from '../domain/models/dataTypes/Boolean'
import StringType from '../domain/models/dataTypes/String'
import Orientation from '../domain/models/dataTypes/Orientation'
import Sequence from '../domain/models/dataTypes/Sequence'
import Array from '../domain/models/dataTypes/Array'
import diagramVariableType from '../data/models/DiagramVariableType'

class DataTypeService {
  getAllDataTypes(): DataType[] {
    return [
      new Float(),
      new Integer(),
      new Vector(),
      new BooleanType(),
      new StringType(),
      new Orientation(),
      new Sequence(),
      new Array(new Float()),
      new Array(new Integer()),
      new Array(new Vector()),
      new Array(new BooleanType()),
      new Array(new StringType()),
      new Array(new Orientation()),
      new Array(new Sequence())
    ]
  }

  getDataTypeByName(name: diagramVariableType) {
    switch (name) {
      case 'Boolean':
        return new BooleanType()
      case 'String':
        return new StringType()
      case 'Vector':
        return new Vector()
      case 'Orientation':
        return new Orientation()
      case 'Float':
        return new Float()
      case 'Integer':
        return new Integer()
      case 'Sequence':
        return new Sequence()
      case 'Float array':
        return new Array(new Float())
      case 'Integer array':
        return new Array(new Integer())
      case 'Vector array':
        return new Array(new Vector())
      case 'Boolean array':
        return new Array(new BooleanType())
      case 'String array':
        return new Array(new StringType())
      case 'Orientation array':
        return new Array(new Orientation())
      case 'Sequence array':
        return new Array(new Sequence())
    }
  }
}

export default DataTypeService
