import ReactFlow, { Background, Controls } from 'reactflow'
import 'reactflow/dist/style.css'
import { Helmet } from 'react-helmet-async'
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Toolbar,
  Paper,
  TextField,
  Typography,
  Tooltip,
  Divider,
  Stack
} from '@mui/material'
import useDiagramEditor from './useDiagramEditor'

import {
  ArrowBack,
  FileDownloadOutlined,
  LayersClearOutlined,
  PhotoCameraOutlined,
  RedoOutlined,
  SaveOutlined,
  TabOutlined,
  UndoOutlined
} from '@mui/icons-material'
import nodeTypes from '../../features/presentation/utils/nodeTypes'
import proOptions from '../../features/presentation/utils/proOptions'
import NodeToolbox from '../NodeToolbox'
import React from 'react'
import ContextualNodeActions from '../ContextualNodeActions'
import { useNavigate } from 'react-router-dom'
import FunctionNode from '../../features/domain/models/FunctionNode'
import ConstantInputNodeClass from '../../features/domain/models/ConstantInputNode'
import OutputNodeClass from '../../features/domain/models/OutputNode'
import ContainerNode from '../../features/domain/models/ContainerNode'
import NodeDetail from '../NodeDetail'
import ViewSwitch from '../ViewSwitch'
import FunctionNodeSettings from '../FunctionNodeSettings'
import ViewSwitchCase from '../ViewSwitchCase'
import ContainerNodeSettings from '../ContainerNodeSettings'
import ConstantInputNodeSettings from '../ConstantInputNodeSettings'
import OutputNodeSettings from '../OutputNodeSettings/OutputNodeSettings.view'
import StreamInputNodeSettings from '../StreamInputNodeSettings/StreamInputNodeSettings.view'
import StreamInputNode from '../../features/domain/models/StreamInputNode'
import SubdiagramsDialog from '../SubdiagramsDialog'
import SubdiagramTabs from '../SubdiagramTabs'

const DiagramEditor = () => {
  const {
    name,
    nodes,
    edges,
    handleSave,
    handleNameChange,
    handleNodeChange,
    handleDelete,
    deleteIsDisabled,
    handleConnect,
    undo,
    redo,
    hasUndo,
    hasRedo,
    viewIsEmpty,
    downloadDiagramAsImage,
    downloadDiagramAsJson,
    manual,
    handleManualChange,
    handleContain,
    handleDecontain,
    containIsDisabled,
    decontainIsDisabled,
    handleEdgeDoubleClick,
    areNodesSelected,
    selectedNode,
    clearSelected,
    activeTabKey,
    handleNodeColorPropertyChange,
    handleNodeDescriptionPropertyChange,
    handleNodeOutputValuePropertyChange,
    handleClear,
    duplicateIsDisabled,
    handleDuplicate,
    handleReplace,
    replaceIsDisabled,
    handleNodeForwardingPropertyChange,
    handleNodeHiddenPropertyChange,
    handleOpenSubdiagramsDialog,
    handleCloseSubdiagramsDialog,
    subdiagramsDialogIsOpen,
    subdiagrams,
    handleCreateSubdiagram,
    handleDeleteSubdiagram,
    selectedSubdiagram,
    setSelectedSubdiagram,
    updateSubdiagramDescription,
    handleNodePreviewDrop,
    handleNodePreviewDragOver,
    setReactFlowInstance,
    reactFlowWrapper,
    copyIsDisabled,
    handleCopy
  } = useDiagramEditor()

  const navigate = useNavigate()

  return (
    <>
      <Helmet>
        <title>Dataflow Diagram Editor - Diagram: {name}</title>
      </Helmet>
      <Box sx={{ height: '100%', width: '100%', display: 'flex' }}>
        <Box sx={{ flexGrow: 1 }}>
          <AppBar
            position="static"
            variant="outlined"
            elevation={0}
            color="transparent"
          >
            <Toolbar>
              <IconButton
                onClick={() => navigate('../')}
                size="large"
                edge="start"
                color="inherit"
                sx={{ mr: 2 }}
              >
                <Tooltip title="Back to diagrams">
                  <ArrowBack />
                </Tooltip>
              </IconButton>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                {name}
              </Typography>
              <Stack spacing={1} direction="row">
                <Button
                  endIcon={<SaveOutlined />}
                  variant="contained"
                  size="small"
                  onClick={handleSave}
                >
                  save
                </Button>
                <Button
                  endIcon={<UndoOutlined />}
                  disabled={!hasUndo}
                  variant="contained"
                  size="small"
                  onClick={undo}
                >
                  Undo
                </Button>
                <Button
                  endIcon={<RedoOutlined />}
                  disabled={!hasRedo}
                  variant="contained"
                  size="small"
                  onClick={redo}
                >
                  Redo
                </Button>
                <Button
                  endIcon={<LayersClearOutlined />}
                  disabled={viewIsEmpty}
                  variant="contained"
                  size="small"
                  onClick={handleClear}
                >
                  Clear
                </Button>
                <Button
                  endIcon={<PhotoCameraOutlined />}
                  disabled={viewIsEmpty}
                  variant="contained"
                  size="small"
                  onClick={downloadDiagramAsImage}
                >
                  Image
                </Button>
                <Button
                  endIcon={<FileDownloadOutlined />}
                  disabled={viewIsEmpty}
                  variant="contained"
                  size="small"
                  onClick={downloadDiagramAsJson}
                >
                  Download
                </Button>
                <Button
                  endIcon={<TabOutlined />}
                  variant="text"
                  size="small"
                  onClick={handleOpenSubdiagramsDialog}
                >
                  subdiagrams
                </Button>
              </Stack>
            </Toolbar>
          </AppBar>
          <Box
            sx={{ height: 'calc(100% - 66px)', width: '100%', display: 'flex' }}
          >
            <Paper
              variant="outlined"
              sx={{
                height: '100%',
                width: '270px',
                borderTop: 0,
                borderRadius: 0,
                borderBottom: 0,
                flexGrow: 1
              }}
            >
              <Stack spacing={2} margin={1}>
                <TextField
                  label="Name"
                  size="small"
                  value={name}
                  onChange={handleNameChange}
                  fullWidth
                />
                <TextField
                  label="Manual"
                  value={manual}
                  onChange={handleManualChange}
                  multiline
                  fullWidth
                  maxRows={5}
                />
                <Divider />
              </Stack>
              <NodeToolbox />
            </Paper>
            <Box
              sx={{
                width: 'calc(100% - 270px);',
                height: '100%',
                backgroundColor: '#1976d217',
                position: 'relative'
              }}
            >
              {subdiagrams.length > 0 && (
                <SubdiagramTabs
                  subdiagrams={subdiagrams}
                  onSelectSubdiagram={setSelectedSubdiagram}
                  selectedSubdiagram={selectedSubdiagram}
                />
              )}
              <Box
                sx={{
                  height: subdiagrams.length > 0 ? 'calc(100% - 49px)' : '100%'
                }}
                ref={reactFlowWrapper}
              >
                <ReactFlow
                  onInit={setReactFlowInstance}
                  onDrop={handleNodePreviewDrop}
                  onDragOver={handleNodePreviewDragOver}
                  nodeTypes={nodeTypes}
                  nodes={nodes}
                  edges={edges}
                  onNodesChange={handleNodeChange}
                  onConnect={handleConnect}
                  fitView
                  proOptions={proOptions}
                  edgesFocusable
                  onEdgeDoubleClick={handleEdgeDoubleClick}
                  multiSelectionKeyCode="ControlLeft"
                  minZoom={0.2}
                  maxZoom={1}
                  defaultViewport={{ x: 0, y: 0, zoom: 0.8 }}
                >
                  <Background gap={45} size={2} color="#1976d2" />
                  <Controls showInteractive={false} />
                </ReactFlow>
              </Box>
              <ContextualNodeActions
                deleteIsDisabled={deleteIsDisabled}
                containIsDisabled={containIsDisabled}
                decontainIsDisabled={decontainIsDisabled}
                replaceIsDisabled={replaceIsDisabled}
                duplicateIsDisabled={duplicateIsDisabled}
                copyIsDisabled={copyIsDisabled}
                onDeleteClick={handleDelete}
                onContainClick={handleContain}
                onDecontainClick={handleDecontain}
                onReplaceClick={handleReplace}
                onDuplicateClick={handleDuplicate}
                onCopyClick={handleCopy}
                show={areNodesSelected}
              />
            </Box>
            <NodeDetail open={!!selectedNode} close={clearSelected}>
              <ViewSwitch activeCase={activeTabKey}>
                <ViewSwitchCase case="function">
                  <FunctionNodeSettings
                    node={selectedNode as FunctionNode}
                    onChangeColor={handleNodeColorPropertyChange}
                  />
                </ViewSwitchCase>
                <ViewSwitchCase case="constantInput">
                  <ConstantInputNodeSettings
                    node={selectedNode as ConstantInputNodeClass}
                    onChangeColor={handleNodeColorPropertyChange}
                    onChangeDescription={handleNodeDescriptionPropertyChange}
                    onChangeValue={handleNodeOutputValuePropertyChange}
                  />
                </ViewSwitchCase>
                <ViewSwitchCase case="streamInput">
                  <StreamInputNodeSettings
                    node={selectedNode as StreamInputNode}
                    onChangeColor={handleNodeColorPropertyChange}
                    onChangeDescription={handleNodeDescriptionPropertyChange}
                    onChangeForwarding={handleNodeForwardingPropertyChange}
                  />
                </ViewSwitchCase>
                <ViewSwitchCase case="output">
                  <OutputNodeSettings
                    node={selectedNode as OutputNodeClass}
                    onChangeColor={handleNodeColorPropertyChange}
                    onChangeDescription={handleNodeDescriptionPropertyChange}
                    onChangeHidden={handleNodeHiddenPropertyChange}
                  />
                </ViewSwitchCase>
                <ViewSwitchCase case="container">
                  <ContainerNodeSettings
                    node={selectedNode as ContainerNode}
                    onChangeColor={handleNodeColorPropertyChange}
                    onChangeDescription={handleNodeDescriptionPropertyChange}
                  />
                </ViewSwitchCase>
              </ViewSwitch>
            </NodeDetail>
          </Box>
        </Box>
      </Box>
      <SubdiagramsDialog
        isOpen={subdiagramsDialogIsOpen}
        onClose={handleCloseSubdiagramsDialog}
        subdiagrams={subdiagrams}
        onCreateSubdiagram={handleCreateSubdiagram}
        onDeleteSubdiagram={handleDeleteSubdiagram}
        onUpdateDescriptionOfSubdiagram={updateSubdiagramDescription}
      />
    </>
  )
}

export default DiagramEditor
