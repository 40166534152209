import Value from './Value'

class VectorValue implements Value {
  public x: number

  public y: number

  public z: number

  constructor(x = 0.0, y = 0.0, z = 0.0) {
    this.x = x
    this.y = y
    this.z = z
  }

  toString(): string {
    return `(${this.x.toFixed(4)}, ${this.y.toFixed(4)}, ${this.z.toFixed(4)})`
  }
}

export default VectorValue
