class Position {
  public readonly x: number

  public readonly y: number

  constructor(x: number, y: number) {
    this.x = x
    this.y = y
  }
}

export default Position
