import Transformer from '../../../transformation/Transformer'
import Connection from '../../models/Connection'
import { Edge } from 'reactflow'
import connectionToReactFlowEdgeTransformer from './connectionToReactFlowEdgeTransformer'

const connectionsToReactFlowEdgesTransformer: Transformer<
  Connection[],
  Edge[]
> = {
  transform: (connections) =>
    connections.map((connection) =>
      connectionToReactFlowEdgeTransformer.transform(connection, undefined)
    )
}

export default connectionsToReactFlowEdgesTransformer
