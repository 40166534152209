import Value from './Value'

class StringValue implements Value {
  public value: string

  constructor(value = '') {
    this.value = value
  }

  toString(): string {
    return this.value
  }
}

export default StringValue
