import { z } from 'zod'
import diagramConnectionDataModelValidator from './diagramConnectionDataModelValidator'
import diagramPropertyDataModelValidator from './diagramPropertyDataModelValidator'
import diagramContainerDataModelValidator from './diagramContainerDataModelValidator'
import diagramPositionDataModelValidator from './diagramPositionDataModelValidator'
import diagramNodeDataModelValidator from './diagramNodeDataModelValidator'
import DiagramDataModel from '../models/DiagramDataModel'
import diagramSubdiagramDataModelValidator from './diagramSubdiagramDataModelValidator'

const diagramDataModelValidator: z.ZodType<DiagramDataModel> = z.object({
  connections: z.array(diagramConnectionDataModelValidator),
  properties: z.array(diagramPropertyDataModelValidator),
  containers: z.array(diagramContainerDataModelValidator),
  manualLines: z.array(z.string()).optional(),
  positions: z.array(diagramPositionDataModelValidator),
  nodes: z.array(diagramNodeDataModelValidator),
  subdiagrams: z.array(diagramSubdiagramDataModelValidator).optional()
})

export default diagramDataModelValidator
