import Value from './Value'

class IntegerValue implements Value {
  public value: number

  constructor(value = 0) {
    this.value = value
  }

  toString(): string {
    return this.value.toString()
  }
}

export default IntegerValue
