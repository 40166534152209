import { useCallback, useContext, useEffect, useRef } from 'react'
import diagramServiceContext from '../context/diagramServiceContext'
import Diagram from '../../domain/models/Diagram'
import useThrowAsyncError from '../../../shared/useThrowAsyncError'
import useForceRender from '../../../shared/useForceRender'
import useRefValueOrDefault from '../../../shared/useRefValueOrDefault'
import useDiagramChangeHistory from './useDiagramChangeHistory'
import NodeFactory from '../../domain/factories/NodeFactory'
import FunctionTypeNodeFactory from '../../domain/factories/FunctionTypeNodeFactory'
import NodePositionUpdate from '../../presentation/NodePositionUpdate'
import StreamInputNode from '../../domain/models/StreamInputNode'
import OutputNode from '../../domain/models/OutputNode'
import Subdiagram from '../../domain/models/Subdiagram'
import DataType from '../../domain/models/dataTypes/DataType'
import Position from '../../domain/models/Position'
import diagramDataModelValidator from '../../data/validation/diagramDataModelValidator'

const useDiagram = (uuid: string) => {
  const service = useContext(diagramServiceContext)

  const throwError = useThrowAsyncError()

  const forceRenderName = useForceRender()

  const forceRenderView = useForceRender()

  const forceRenderManualLines = useForceRender()

  const forceRenderAll = useForceRender()

  const diagramRef = useRef<Diagram>()

  const {
    hasRedo,
    hasUndo,
    undo: undoDiagram,
    redo: redoDiagram,
    set: setDiagram
  } = useDiagramChangeHistory()

  useEffect(() => {
    void service
      .tryGetDiagramByUuid(uuid)
      .then((model) => {
        if (!diagramRef.current) {
          diagramRef.current = model
          setDiagram(model)
          forceRenderAll()
        }
      })
      .catch(throwError)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [service, uuid])

  const getNodeById = useCallback((nodeId: string) => {
    if (diagramRef.current) {
      return diagramRef.current?.getNodeById(nodeId)
    }
    return undefined
  }, [])

  const trySave = useCallback(() => {
    if (!diagramRef.current) {
      return Promise.reject('Diagram not found')
    }

    return service.updateDiagram(diagramRef.current)
  }, [service])

  const setName = useCallback(
    (newName: string) => {
      if (diagramRef.current) {
        diagramRef.current.name = newName
        forceRenderName()
      }
    },
    [forceRenderName]
  )

  const setManualLines = useCallback(
    (newManualLines: string[]) => {
      if (diagramRef.current) {
        diagramRef.current.manualLines = newManualLines
        forceRenderManualLines()
      }
    },
    [forceRenderManualLines]
  )

  const view = useRefValueOrDefault(
    diagramRef,
    (diagram) => diagram.createDiagramView(),
    {
      nodes: [],
      connections: []
    },
    [forceRenderAll, forceRenderView]
  )

  const name = useRefValueOrDefault(
    diagramRef,
    (diagram: Diagram) => diagram.name,
    '',
    [forceRenderAll, forceRenderName]
  )

  const manualLines = useRefValueOrDefault(
    diagramRef,
    useCallback((diagram) => diagram.manualLines, []),
    [],
    [forceRenderAll, forceRenderManualLines]
  )

  const deleteNodesByIds = useCallback(
    (nodeIds: string[]) => {
      if (diagramRef.current === undefined) {
        return
      }

      diagramRef.current?.deleteNodesByIds(nodeIds)

      setDiagram(diagramRef.current)

      forceRenderView()
    },
    [forceRenderView, setDiagram]
  )

  const updateNodePositions = useCallback(
    (nodePositionUpdates: NodePositionUpdate[]) => {
      if (
        diagramRef.current === undefined ||
        nodePositionUpdates.length === 0
      ) {
        return
      }

      for (const { nodeId, x, y } of nodePositionUpdates) {
        const node = diagramRef.current.getNodeById(nodeId)

        if (node === undefined) {
          continue
        }

        node.x = x
        node.y = y
      }

      setDiagram(diagramRef.current)

      forceRenderView()
    },
    [forceRenderView, setDiagram]
  )

  const updateNodeDescription = useCallback(
    (nodeId: string, description: string | undefined) => {
      if (diagramRef.current === undefined) {
        return
      }

      const node = diagramRef.current.getNodeById(nodeId)

      if (node === undefined) {
        return
      }

      if (!description) {
        delete node.properties.Description
      } else {
        node.properties.Description = description
      }

      setDiagram(diagramRef.current)

      forceRenderView()
    },
    [forceRenderView, setDiagram]
  )

  const updateNodeForwarding = useCallback(
    (nodeId: string, forwarding: boolean) => {
      if (diagramRef.current === undefined) {
        return
      }

      const node = diagramRef.current.getNodeById(nodeId)

      if (node === undefined) {
        return
      }

      if (!(node instanceof StreamInputNode)) {
        return
      }

      node.forwarding = forwarding

      setDiagram(diagramRef.current)

      forceRenderView()
    },
    [forceRenderView, setDiagram]
  )

  const updateNodeHidden = useCallback(
    (nodeId: string, hidden: boolean) => {
      if (diagramRef.current === undefined) {
        return
      }

      const node = diagramRef.current.getNodeById(nodeId)

      if (node === undefined) {
        return
      }

      if (!(node instanceof OutputNode)) {
        return
      }

      node.hidden = hidden

      setDiagram(diagramRef.current)

      forceRenderView()
    },
    [forceRenderView, setDiagram]
  )

  const updateNodeColor = useCallback(
    (nodeId: string, color: string) => {
      if (diagramRef.current === undefined) {
        return
      }

      const node = diagramRef.current.getNodeById(nodeId)

      if (node === undefined) {
        return
      }

      node.color = color

      setDiagram(diagramRef.current)

      forceRenderView()
    },
    [forceRenderView, setDiagram]
  )

  const updateNodeOutputValue = useCallback(
    (nodeId: string, value: string) => {
      if (diagramRef.current === undefined) {
        return
      }

      const node = diagramRef.current.getNodeById(nodeId)

      if (node === undefined) {
        return
      }

      node.properties.OutputValue = value

      setDiagram(diagramRef.current)

      forceRenderView()
    },
    [forceRenderView, setDiagram]
  )

  const tryCreateConnection = useCallback(
    async (
      sourceNodeId: string,
      targetNodeId: string,
      targetHandleId: string
    ) => {
      if (diagramRef.current === undefined) {
        return
      }

      const connection = await diagramRef.current?.tryCreateConnectionBetween(
        sourceNodeId,
        targetNodeId,
        targetHandleId
      )

      setDiagram(diagramRef.current)

      forceRenderView()

      return connection
    },
    [forceRenderView, setDiagram]
  )

  const tryRemoveConnection = useCallback(
    async (
      sourceNodeId: string,
      targetNodeId: string,
      targetHandleId: string
    ) => {
      if (diagramRef.current === undefined) {
        return
      }

      await diagramRef.current?.tryRemoveConnectionBetween(
        sourceNodeId,
        targetNodeId,
        targetHandleId
      )

      setDiagram(diagramRef.current)

      forceRenderView()
    },
    [forceRenderView, setDiagram]
  )

  const undo = useCallback(() => {
    const diagram = undoDiagram()
    if (diagram) {
      diagram.setSelectedSubdiagram(diagramRef.current?.selectedSubdiagram)
      diagramRef.current = diagram
      forceRenderView()
    }
  }, [forceRenderView, undoDiagram])

  const redo = useCallback(() => {
    const diagram = redoDiagram()

    if (diagram) {
      diagram.setSelectedSubdiagram(diagramRef.current?.selectedSubdiagram)
      diagramRef.current = diagram
      forceRenderView()
    }
  }, [forceRenderView, redoDiagram])

  const clear = useCallback(() => {
    if (!diagramRef.current) {
      return
    }

    diagramRef.current?.clear()

    setDiagram(diagramRef.current)

    forceRenderView()
  }, [forceRenderView, setDiagram])

  const tryToJsonString = useCallback(() => {
    if (!diagramRef.current) {
      return Promise.reject('diagram not available')
    }

    return Promise.resolve(
      service.createJsonStringFromDiagram(diagramRef.current)
    )
  }, [service])

  const tryContain = useCallback(
    (nodeIds: string[]) => {
      if (diagramRef.current) {
        const container = diagramRef.current?.tryContain(nodeIds)

        setDiagram(diagramRef.current)

        forceRenderView()

        return container
      }

      return Promise.reject('diagram not available')
    },
    [forceRenderView, setDiagram]
  )

  const isContainer = useCallback((nodeId: string) => {
    if (diagramRef.current) {
      return diagramRef.current?.isContainer(nodeId)
    }

    return false
  }, [])

  const tryDecontain = useCallback(
    (containerNodeId: string) => {
      if (diagramRef.current) {
        const container = diagramRef.current?.tryDecontain(containerNodeId)

        setDiagram(diagramRef.current)

        forceRenderView()

        return container
      }

      return Promise.reject('diagram not available')
    },
    [forceRenderView, setDiagram]
  )

  const addNode = useCallback(
    (factory: NodeFactory, dataType: DataType, position?: Position) => {
      if (diagramRef.current) {
        diagramRef.current?.addNode(factory, dataType, position)

        setDiagram(diagramRef.current)

        forceRenderView()
      }
    },
    [forceRenderView, setDiagram]
  )

  const addFunctionNode = useCallback(
    (factory: FunctionTypeNodeFactory, position?: Position) => {
      if (diagramRef.current) {
        diagramRef.current?.addFunctionNode(factory, position)

        setDiagram(diagramRef.current)

        forceRenderView()
      }
    },
    [forceRenderView, setDiagram]
  )

  const tryDuplicateNodes = useCallback(
    async (nodeIds: string[]) => {
      if (!diagramRef.current) {
        return Promise.reject('Diagram not found')
      }

      const result = await diagramRef.current?.tryDuplicate(nodeIds)

      setDiagram(diagramRef.current)

      forceRenderView()

      return result
    },
    [forceRenderView, setDiagram]
  )

  const tryReplaceNode = useCallback(
    async (aId: string, bId: string) => {
      if (diagramRef.current) {
        const replaced = await diagramRef.current?.tryReplaceNode(aId, bId)

        setDiagram(diagramRef.current)

        forceRenderView()

        return replaced
      }

      return Promise.reject('diagram not available')
    },
    [forceRenderView, setDiagram]
  )

  const tryCopy = useCallback(
    async (nodeIds: string[]) => {
      if (diagramRef.current) {
        const diagramView = await diagramRef.current?.tryCopy(nodeIds)

        return service.createJsonStringFromDiagramView(diagramView)
      }

      return Promise.reject('diagram not available')
    },
    [service]
  )

  const tryPaste = useCallback(
    async (data: string) => {
      if (!diagramRef.current) {
        return Promise.reject('Diagram not found')
      }

      try {
        const jsonData = JSON.parse(data) as unknown

        const diagramDataModel = diagramDataModelValidator.parse(jsonData)

        const diagramView = service.createDiagramViewDataModel(diagramDataModel)

        const result = await diagramRef.current?.tryPaste(diagramView)

        setDiagram(diagramRef.current)

        forceRenderView()

        return result
      } catch (e) {
        return Promise.reject('Clipboard could not be pasted')
      }
    },
    [forceRenderView, service, setDiagram]
  )

  const selectedNodeIds = useRefValueOrDefault(
    diagramRef,
    (current) => current.getSelectedNodeIds(),
    [],
    [forceRenderAll, forceRenderView]
  )

  const setSelectedNodeIds = useCallback(
    (newSelectedNodeIds: string[]) => {
      if (diagramRef.current) {
        diagramRef.current?.setSelectedNodeIds(newSelectedNodeIds)
        forceRenderView()
      }
    },
    [forceRenderView]
  )

  const subdiagrams = useRefValueOrDefault(
    diagramRef,
    (current) => current.subdiagrams,
    [],
    [forceRenderAll, forceRenderView]
  )

  const createSubdiagram = useCallback(() => {
    if (diagramRef.current) {
      diagramRef.current?.createSubdiagram()

      setDiagram(diagramRef.current)

      forceRenderAll()
    }
  }, [forceRenderAll, setDiagram])

  const deleteSubdiagramById = useCallback(
    (id: number) => {
      if (diagramRef.current) {
        diagramRef.current?.deleteSubdiagramById(id)

        setDiagram(diagramRef.current)

        forceRenderAll()
      }
    },
    [forceRenderAll, setDiagram]
  )

  const setSelectedSubdiagram = useCallback(
    (subdiagram: Subdiagram | undefined) => {
      if (diagramRef.current) {
        diagramRef.current?.setSelectedSubdiagram(subdiagram)
        forceRenderAll()
      }
    },
    [forceRenderAll]
  )

  const selectedSubdiagram = useRefValueOrDefault(
    diagramRef,
    (current) => current.selectedSubdiagram,
    undefined,
    [forceRenderAll, forceRenderView]
  )

  const updateSubdiagramDescription = useCallback(
    (id: number, description: string) => {
      if (!diagramRef.current) {
        return
      }

      const subdiagram = diagramRef.current?.subdiagramRepository.geyById(id)

      if (!subdiagram) {
        return
      }

      subdiagram.description = description

      setDiagram(diagramRef.current)

      forceRenderAll()
    },
    [forceRenderAll, setDiagram]
  )

  return {
    trySave,
    view,
    name,
    manualLines,
    setName,
    setManualLines,
    updateNodePositions,
    tryCreateConnection,
    tryRemoveConnection,
    deleteNodesByIds,
    undo,
    redo,
    hasUndo,
    hasRedo,
    clear,
    tryToJsonString,
    tryContain,
    isContainer,
    tryDecontain,
    addNode,
    addFunctionNode,
    getNodeById,
    updateNodeDescription,
    updateNodeColor,
    updateNodeOutputValue,
    tryDuplicateNodes,
    tryReplaceNode,
    selectedNodeIds,
    setSelectedNodeIds,
    updateNodeForwarding,
    updateNodeHidden,
    createSubdiagram,
    subdiagrams,
    deleteSubdiagramById,
    selectedSubdiagram,
    setSelectedSubdiagram,
    updateSubdiagramDescription,
    tryCopy,
    tryPaste
  }
}

export default useDiagram
