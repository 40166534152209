import React from 'react'
import './App.styles.css'
import { Outlet } from 'react-router-dom'
import useApp from './useApp'

const App = (): JSX.Element => {
  useApp()
  return (
    <main>
      <Outlet />
    </main>
  )
}

export default App
