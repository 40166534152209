import DataType from './DataType'
import dataTypeUtils from '../../utils/dataTypeUtils'
import DiagramVariableType from '../../../data/models/DiagramVariableType'

class Boolean implements DataType {
  get name(): DiagramVariableType {
    return 'Boolean'
  }

  get symbol(): string {
    return 'b'
  }

  public isCompatible(dataType: DataType): boolean {
    return dataTypeUtils.isCompatible(this, dataType)
  }
}

export default Boolean
