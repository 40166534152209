import DataType from './DataType'
import DiagramVariableType from '../../../data/models/DiagramVariableType'

class Array<T extends DataType> implements DataType {
  public readonly itemDataType: T

  constructor(dataType: T) {
    this.itemDataType = dataType
  }

  get name(): DiagramVariableType {
    return `${
      this.itemDataType.name as Exclude<
        DiagramVariableType,
        | 'Float array'
        | 'Integer array'
        | 'Vector array'
        | 'Boolean array'
        | 'String array'
        | 'Orientation array'
        | 'Sequence array'
      >
    } array`
  }

  get symbol(): string {
    return `[${this.itemDataType.symbol}]`
  }

  public isCompatible(dataType: DataType): boolean {
    return this.symbol === dataType.symbol
  }
}

export default Array
