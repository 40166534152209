import { useCallback, useEffect, useRef } from 'react'
import ChangeHistory from '../ChangeHistory'
import useRefValueOrDefault from '../../../shared/useRefValueOrDefault'
import useForceRender from '../../../shared/useForceRender'

const useChangeHistory = <T>(maxChanges = 20, present?: T | undefined) => {
  const ref = useRef<ChangeHistory<T>>()

  const forceRenderHasUndo = useForceRender()

  const forceRenderHasRedo = useForceRender()

  useEffect(() => {
    ref.current = new ChangeHistory<T>(maxChanges, present)
  }, [maxChanges, present])

  const hasUndo = useRefValueOrDefault(
    ref,
    (changes) => changes.hasUndo,
    false,
    [forceRenderHasUndo]
  )

  const hasRedo = useRefValueOrDefault(
    ref,
    (changes) => changes.hasRedo,
    false,
    [forceRenderHasRedo]
  )

  const undo = useCallback(() => {
    if (!ref.current) {
      return undefined
    }

    ref.current?.undo()
    forceRenderHasUndo()
    forceRenderHasRedo()

    return ref.current?.present
  }, [forceRenderHasRedo, forceRenderHasUndo])

  const redo = useCallback(() => {
    if (!ref.current) {
      return undefined
    }

    ref.current?.redo()
    forceRenderHasUndo()
    forceRenderHasRedo()

    return ref.current?.present
  }, [forceRenderHasRedo, forceRenderHasUndo])

  const set = useCallback(
    (newPresent: T) => {
      if (!ref.current) {
        return
      }

      ref.current?.setPresent(newPresent)
      forceRenderHasUndo()
      forceRenderHasRedo()
    },
    [forceRenderHasRedo, forceRenderHasUndo]
  )

  return {
    set,
    undo,
    redo,
    hasUndo,
    hasRedo
  }
}

export default useChangeHistory
