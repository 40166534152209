import { Button, Stack, TextField } from '@mui/material'
import useArrayValueSettingInput from './useArrayValueSettingInput'
import { SaveOutlined } from '@mui/icons-material'
import { ArrayValueSettingInputProps } from './ArrayValueSettingInput.types'
import Value from '../../features/domain/models/values/Value'

function ArrayValueSettingInput<TValue extends Value>(
  props: ArrayValueSettingInputProps<TValue>
) {
  const { text, handleTextChange, handleSave, errorText } =
    useArrayValueSettingInput(props)

  return (
    <Stack spacing={2}>
      <TextField
        error={!!errorText}
        multiline
        type="text"
        fullWidth
        maxRows={10}
        value={text}
        onChange={handleTextChange}
        helperText={errorText}
      />
      <Stack direction="row-reverse">
        <Button
          endIcon={<SaveOutlined />}
          onClick={handleSave}
          size="small"
          variant="outlined"
        >
          Save
        </Button>
      </Stack>
    </Stack>
  )
}

export default ArrayValueSettingInput
