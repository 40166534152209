import { DiagramCreationConfirmationDialogProps } from './DiagramCreationConfirmationDialog.types'
import useDiagramCreationConfirmationDialog from './useDiagramCreationConfirmationDialog'
import { Button, Stack, TextField } from '@mui/material'
import { AddOutlined } from '@mui/icons-material'
import ConfirmationDialog from '../ConfirmationDialog'
import React from 'react'

const DiagramCreationConfirmationDialog = (
  props: DiagramCreationConfirmationDialogProps
) => {
  const {
    isOpen,
    handleCancel,
    handleConfirm,
    name,
    handleNameChange,
    manual,
    handleManualChange,
    isCreateButtonDisabled
  } = useDiagramCreationConfirmationDialog(props)

  return (
    <ConfirmationDialog
      title="Create diagram"
      isOpen={isOpen}
      onCancel={handleCancel}
      onConfirm={handleConfirm}
      confirmButtonText="Create"
      onConfirmButtonRender={(confirm, text) => (
        <Button
          variant="contained"
          disabled={isCreateButtonDisabled}
          onClick={confirm}
          endIcon={<AddOutlined />}
        >
          {text}
        </Button>
      )}
    >
      <Stack spacing={2}>
        <TextField
          fullWidth
          required
          label="Name"
          value={name}
          onChange={handleNameChange}
        />
        <TextField
          label="Manual"
          value={manual}
          onChange={handleManualChange}
          multiline
          fullWidth
          minRows={5}
          maxRows={5}
        />
      </Stack>
    </ConfirmationDialog>
  )
}

export default DiagramCreationConfirmationDialog
