import { ChangeEvent, useCallback, useState } from 'react'
import Diagram from '../../features/domain/models/Diagram'
import useDiagrams from '../../features/application/hooks/useDiagrams'
import { toast } from '../../features/materialToast'
import diagramDataModelValidator from '../../features/data/validation/diagramDataModelValidator'
import useDownloader from '../../shared/useDownloader'
import useDiagramToImage from '../../features/presentation/hooks/useDiagramToImage'

const useDiagramsPage = () => {
  const {
    diagrams,
    createDiagram,
    duplicateDiagramByUuid,
    deleteDiagramByUuid,
    importDiagram,
    downloadDiagramAsJson
  } = useDiagrams()

  const [selectedDiagram, setSelectedDiagram] = useState<Diagram>()

  const download = useDownloader()

  const { toPng } = useDiagramToImage()

  const [isDiagramCreationDialogOpen, setIsDiagramCreationDialogOpen] =
    useState(false)

  const [isDiagramDeletionDialogOpen, setIsDiagramDeletionDialogOpen] =
    useState(false)

  const handleSelectDiagram = useCallback(
    (diagram: Diagram) => setSelectedDiagram(diagram),
    []
  )

  const closeDiagramCreationDialog = useCallback(
    () => setIsDiagramCreationDialogOpen(false),
    []
  )

  const openDiagramCreationDialog = useCallback(
    () => setIsDiagramCreationDialogOpen(true),
    []
  )

  const closeDiagramDeletionDialog = useCallback(
    () => setIsDiagramDeletionDialogOpen(false),
    []
  )

  const openDiagramDeletionDialog = useCallback(
    () => setIsDiagramDeletionDialogOpen(true),
    []
  )

  const handleCreateDiagram = useCallback(
    (name: string, manualLines: string[]) => {
      createDiagram(name, manualLines)
        .then(() => {
          setIsDiagramCreationDialogOpen(false)
          toast(`Diagram '${name}' created`, { type: 'success' })
        })
        .catch((reason) => toast(reason as string, { type: 'error' }))
    },
    [createDiagram]
  )

  const handleDeleteDiagram = useCallback(() => {
    if (selectedDiagram) {
      deleteDiagramByUuid(selectedDiagram.uuid)
        .then(() => {
          setSelectedDiagram(undefined)
          setIsDiagramDeletionDialogOpen(false)
          toast(`Diagram deleted`, { type: 'success' })
        })
        .catch((reason) => toast(reason as string, { type: 'error' }))
    }
  }, [deleteDiagramByUuid, selectedDiagram])

  const handleDuplicateDiagram = useCallback(() => {
    if (selectedDiagram) {
      duplicateDiagramByUuid(selectedDiagram.uuid)
        .then(() => {
          toast(`Diagram duplicated`, { type: 'success' })
        })
        .catch((reason) => toast(reason as string, { type: 'error' }))
    }
  }, [duplicateDiagramByUuid, selectedDiagram])

  // TODO: write abstraction for diagram upload UwU
  const handleImportDiagram = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files
      if (!files || files.length === 0) {
        toast('No file selected', { type: 'error' })
        return
      }

      if (files.length > 1) {
        toast('Multiple files not supported (for now)', { type: 'error' })
        return
      }

      const reader = new FileReader()

      const file = files[0]

      const name = file.name.replace(/\.[^/.]+$/, '')

      reader.readAsBinaryString(file)

      reader.onload = (progressEvent) => {
        if (!progressEvent.target) {
          toast('File could not be processed', { type: 'error' })
          return
        }

        const data = progressEvent.target.result

        if (typeof data !== 'string') {
          toast('File could not be processed', { type: 'error' })
          return
        }

        try {
          const jsonData = JSON.parse(data) as unknown

          const diagramDataModel = diagramDataModelValidator.parse(jsonData)

          void importDiagram(name, diagramDataModel)
            .then(() =>
              toast(`Diagram ${name} imported`, {
                type: 'success'
              })
            )
            .catch((reason) => toast(reason as string, { type: 'error' }))
        } catch {
          toast('Diagram could not be imported', { type: 'error' })
        }
      }
    },
    [importDiagram]
  )

  const handleDownloadDiagramAsImage = useCallback(() => {
    if (selectedDiagram) {
      void toPng()
        .then((data) => {
          download(selectedDiagram.name, data)
          toast('Diagram image downloading...', { type: 'info' })
        })
        .catch(() =>
          toast('Could not download diagram as image', { type: 'error' })
        )
    }
  }, [download, selectedDiagram, toPng])

  const handleDownloadDiagramAsJson = useCallback(() => {
    if (selectedDiagram) {
      void downloadDiagramAsJson(selectedDiagram)
        .then((json) => {
          download(
            `${selectedDiagram.name}.dfd`,
            window.URL.createObjectURL(
              new Blob([json], { type: 'application/json' })
            )
          )
          toast('Diagram downloading...', { type: 'info' })
        })
        .catch(() => toast('Could not download diagram', { type: 'error' }))
    }
  }, [download, selectedDiagram, downloadDiagramAsJson])

  return {
    diagrams,
    selectedDiagram,
    handleSelectDiagram,
    isDiagramCreationDialogOpen,
    closeDiagramCreationDialog,
    handleCreateDiagram,
    openDiagramCreationDialog,
    handleDuplicateDiagram,
    handleDeleteDiagram,
    openDiagramDeletionDialog,
    closeDiagramDeletionDialog,
    isDiagramDeletionDialogOpen,
    handleImportDiagram,
    handleDownloadDiagramAsImage,
    handleDownloadDiagramAsJson
  }
}

export default useDiagramsPage
