import Connection from './Connection'
import Node from './Node'
import ContainerNode from './ContainerNode'
import containerUtils from '../utils/containerUtils'
import Outputable from './Outputable'
import Inputable from './Inputable'
import Handle from './Handle'

class ConnectionRepository {
  private _nodeMap: Record<string, Record<string, Record<string, Connection>>>

  private _handleMap: Record<string, Connection>

  constructor(connections: Connection[]) {
    this._nodeMap = this._createNodeMapWithMultiple(connections)
    this._handleMap = this._createHandleMapWithMultiple(connections)
  }

  public getAllConnections(): Readonly<Readonly<Connection>[]> {
    const connectionIdMap = Object.values(this._handleMap).reduce<
      Record<string, Connection>
    >((previous, current) => {
      previous[current.id] = current
      return previous
    }, {})

    return Object.values(connectionIdMap)
  }

  public addConnection(connection: Connection) {
    this._nodeMap = this._createNodeMapWith(connection, this._nodeMap)
    this._handleMap = this._createHandleMapWith(connection, this._handleMap)
  }

  public addConnections(connections: Connection[]) {
    for (const connection of connections) {
      this.addConnection(connection)
    }
  }

  async duplicateConnectionsForNodes(
    originalNodes: Node[],
    duplicatedNodes: Node[],
    nodeIdConversionMap: Record<string, string>,
    originalConnections = this.getConnectionsForNodes(originalNodes)
  ): Promise<Connection[]> {
    const duplicatedConnections: Connection[] = []

    const duplicatedNodeIdMap = duplicatedNodes.reduce<Record<string, Node>>(
      (previous, current) => {
        previous[current.getId()] = current
        return previous
      },
      {}
    )

    for (const connection of originalConnections) {
      const newSourceNodeId = nodeIdConversionMap[connection.sourceNode.getId()]

      const newSourceNode = duplicatedNodeIdMap[newSourceNodeId] as Outputable &
        Node

      const newTargetNodeId = nodeIdConversionMap[connection.targetNode.getId()]

      const newTargetNode = duplicatedNodeIdMap[newTargetNodeId] as Inputable &
        Node

      if (!newTargetNode || !newSourceNode) {
        continue
      }

      const newConnection = await newTargetNode
        .getInputHandleByIndex(connection.target.index)
        ?.createConnection(newSourceNode.getOutputHandle())

      if (newConnection) {
        duplicatedConnections.push(newConnection)
      }
    }

    return duplicatedConnections
  }

  removeConnection(connection: Connection) {
    if (
      this._nodeMap[connection.targetNode.getId()] &&
      this._nodeMap[connection.targetNode.getId()][
        connection.sourceNode.getId()
      ]
    ) {
      delete this._nodeMap[connection.targetNode.getId()][
        connection.sourceNode.getId()
      ][connection.target.id]
    }

    if (
      this._nodeMap[connection.sourceNode.getId()] &&
      this._nodeMap[connection.sourceNode.getId()][
        connection.targetNode.getId()
      ]
    ) {
      delete this._nodeMap[connection.sourceNode.getId()][
        connection.targetNode.getId()
      ][connection.target.id]
    }

    delete this._handleMap[connection.target.id]

    delete this._handleMap[connection.source.id]

    connection.target.node.onRemoveConnection(this)
    connection.source.node.onRemoveConnection(this)
  }

  getConnectionForHandle(handle: Handle): Connection | undefined {
    return this._handleMap[handle.id]
  }

  getConnectionsForNodes(nodes: Node[], recursive = false): Connection[] {
    const connections = this._getUniqueConnectionsForNodes(nodes)

    if (recursive) {
      return connections
    }

    const nodeIdContainerMap = containerUtils.createNodeIdContainerMap(nodes)

    const connectionsWithContainers = this._rewriteConnectionsToContainers(
      connections,
      nodeIdContainerMap
    )

    return this._filterSelfReferencingConnections(connectionsWithContainers)
  }

  public removeAllConnections(): void {
    this._nodeMap = {}
    this._handleMap = {}
  }

  private _createHandleMapWith(
    connection: Connection,
    handleMap: Record<string, Connection> = {}
  ) {
    const newHandleMap = { ...handleMap }
    newHandleMap[connection.target.id] = connection
    newHandleMap[connection.source.id] = connection
    return newHandleMap
  }

  private _createHandleMapWithMultiple(
    connections: Connection[],
    handleMap: Record<string, Connection> = {}
  ) {
    let newHandleMap = { ...handleMap }

    for (const connection of connections) {
      newHandleMap = this._createHandleMapWith(connection, newHandleMap)
    }

    return newHandleMap
  }

  private _createNodeMapWith(
    connection: Connection,
    nodeMap: Record<string, Record<string, Record<string, Connection>>> = {}
  ) {
    if (!nodeMap[connection.sourceNode.getId()]) {
      nodeMap[connection.sourceNode.getId()] = {}
    }

    if (
      !nodeMap[connection.sourceNode.getId()][connection.targetNode.getId()]
    ) {
      nodeMap[connection.sourceNode.getId()][connection.targetNode.getId()] = {}
    }

    nodeMap[connection.sourceNode.getId()][connection.targetNode.getId()][
      connection.target.id
    ] = connection

    if (!nodeMap[connection.targetNode.getId()]) {
      nodeMap[connection.targetNode.getId()] = {}
    }

    if (
      !nodeMap[connection.targetNode.getId()][connection.sourceNode.getId()]
    ) {
      nodeMap[connection.targetNode.getId()][connection.sourceNode.getId()] = {}
    }

    nodeMap[connection.targetNode.getId()][connection.sourceNode.getId()][
      connection.target.id
    ] = connection

    return nodeMap
  }

  private _createNodeMapWithMultiple(
    connections: Connection[],
    nodeMap: Record<string, Record<string, Record<string, Connection>>> = {}
  ) {
    for (const connection of connections) {
      nodeMap = this._createNodeMapWith(connection, nodeMap)
    }

    return nodeMap
  }

  private _getUniqueConnectionsForNodes(nodes: Node[]): Connection[] {
    const connections = []
    for (const node of nodes) {
      connections.push(...this._getUniqueConnectionsForNode(node))
    }
    return connections.filter(
      (value, index, self) => self.indexOf(value) === index
    )
  }

  private _getUniqueConnectionsForNode(node: Node): Connection[] {
    const connections = []

    if (node instanceof ContainerNode) {
      return this._getUniqueConnectionsForNodes(node.getChildNodes())
    }

    if (this._nodeMap[node.getId()]) {
      for (const map of Object.values(this._nodeMap[node.getId()])) {
        connections.push(...Object.values(map))
      }
    }

    return connections.filter(
      (value, index, self) => self.indexOf(value) === index
    )
  }

  private _rewriteConnectionsToContainers(
    connections: Connection[],
    nodeIdContainerMap: Record<string, ContainerNode>
  ) {
    const rewritten: Connection[] = []
    for (const connection of connections) {
      const sourceContainer = nodeIdContainerMap[
        connection.sourceNode.getId()
      ] as ContainerNode | undefined
      const source = sourceContainer?.getOutputHandle() ?? connection.source

      const targetContainer = nodeIdContainerMap[
        connection.targetNode.getId()
      ] as ContainerNode | undefined
      const target =
        targetContainer?.getInputHandleById(connection.target.id) ??
        connection.target

      rewritten.push(
        new Connection(source, target, sourceContainer, targetContainer)
      )
    }

    return rewritten
  }

  private _filterSelfReferencingConnections(
    connections: Connection[]
  ): Connection[] {
    return connections.filter(
      (connection) =>
        connection.sourceNode.getId() !== connection.targetNode.getId()
    )
  }
}

export default ConnectionRepository
