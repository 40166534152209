import { ValueSettingInputProps } from '../ValueSettingInput/ValueSettingInput.types'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import FloatValue from '../../features/domain/models/values/FloatValue'
import floatValueFactory from '../../features/domain/factories/values/floatValueFactory'
import useNumberParsers from '../../shared/useNumberParsers'

const useFloatValueSettingInput = (props: ValueSettingInputProps) => {
  const { value, onValueChange } = props

  const [float, setFloat] = useState(new FloatValue())

  const { parseFloatOrDefault } = useNumberParsers()

  useEffect(() => {
    floatValueFactory
      .tryCreateWithStringRepresentation(value)
      .then(setFloat)
      .catch(console.log)
  }, [value])

  const handleFloatChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newFloat = new FloatValue(parseFloatOrDefault(event.target.value))
      setFloat(newFloat)
      onValueChange(newFloat.toString())
    },
    [onValueChange, parseFloatOrDefault]
  )

  return {
    float,
    handleFloatChange
  }
}

export default useFloatValueSettingInput
