import Transformer from '../../../transformation/Transformer'
import Node from '../../models/Node'
import { Node as ReactFlowNode } from 'reactflow'
import ContainerNode from '../../models/ContainerNode'
import FunctionNode from '../../models/FunctionNode'
import OutputNode from '../../models/OutputNode'
import StreamInputNode from '../../models/StreamInputNode'
import ConstantInputNode from '../../models/ConstantInputNode'
import containerNodeToReactFlowContainerNodeTransformer from './containerNodeToReactFlowContainerNodeTransformer'
import functionNodeToReactFlowFunctionNodeTransformer from './functionNodeToReactFlowFunctionNodeTransformer'
import outputNodeToReactFlowOutputNodeTransformer from './outputNodeToReactFlowOutputNodeTransformer'
import streamInputNodeToReactFlowStreamInputNodeTransformer from './streamInputNodeToReactFlowStreamInputNodeTransformer'
import constantInputNodeToReactFlowConstantInputNodeTransformer from './constantInputNodeToReactFlowConstantInputNodeTransformer'

const nodeToReactFlowNodeTransformer: Transformer<Node, ReactFlowNode> = {
  transform: (node) => {
    switch (true) {
      case node instanceof ContainerNode:
        return containerNodeToReactFlowContainerNodeTransformer.transform(
          node as ContainerNode,
          undefined
        )
      case node instanceof FunctionNode:
        return functionNodeToReactFlowFunctionNodeTransformer.transform(
          node as FunctionNode,
          undefined
        )
      case node instanceof OutputNode:
        return outputNodeToReactFlowOutputNodeTransformer.transform(
          node as OutputNode,
          undefined
        )
      case node instanceof StreamInputNode:
        return streamInputNodeToReactFlowStreamInputNodeTransformer.transform(
          node as StreamInputNode,
          undefined
        )
      case node instanceof ConstantInputNode:
        return constantInputNodeToReactFlowConstantInputNodeTransformer.transform(
          node as ConstantInputNode,
          undefined
        )
      default:
        throw new Error('node type not found')
    }
  }
}

export default nodeToReactFlowNodeTransformer
