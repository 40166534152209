import { ColorSettingProps } from './ColorSetting.types'
import { useCallback, useMemo } from 'react'
import { ColorOption } from '../RestrictiveColorInput/RestrictiveColorInput.types'

const useColorSetting = (props: ColorSettingProps) => {
  const { color, onColorChange } = props

  const handleColorChange = useCallback(
    (option: ColorOption) => onColorChange(option.color),
    [onColorChange]
  )

  const colorOptions = useMemo<ColorOption[]>(
    () => [
      {
        label: 'blue',
        color: 'blue'
      },
      {
        label: 'red',
        color: 'red'
      },
      {
        label: 'black',
        color: 'black'
      },
      {
        label: 'brown',
        color: 'brown'
      },
      {
        label: 'cyan',
        color: 'cyan'
      },
      {
        label: 'gray',
        color: 'gray'
      },
      {
        label: 'green',
        color: 'green'
      },
      {
        label: 'magenta',
        color: 'magenta'
      },
      {
        label: 'orange',
        color: 'orange'
      },
      {
        label: 'purple',
        color: 'purple'
      },
      {
        label: 'yellow',
        color: 'yellow'
      }
    ],
    []
  )

  const selectedColorOption = useMemo(
    () =>
      colorOptions.find((option) =>
        !color ? option.color === '' : option.color === color
      ),
    [color, colorOptions]
  )

  return {
    handleColorChange,
    colorOptions,
    selectedColorOption
  }
}

export default useColorSetting
