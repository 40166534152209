import { SubdiagramItemProps } from './SubdiagramItem.types'
import useSubdiagramItem from './useSubdiagramItem'
import { Divider, IconButton, Stack, TextField } from '@mui/material'
import { DeleteOutlined } from '@mui/icons-material'

const SubdiagramItem = (props: SubdiagramItemProps) => {
  const { handleChangeDescription, handleDelete, description } =
    useSubdiagramItem(props)

  return (
    <>
      <Stack direction="row">
        <TextField
          sx={{ flexGrow: 1 }}
          size="small"
          value={description}
          variant="outlined"
          label="description"
          onChange={handleChangeDescription}
        />
        <IconButton sx={{ marginLeft: 1 }} onClick={handleDelete}>
          <DeleteOutlined />
        </IconButton>
      </Stack>
      <Divider sx={{ marginTop: 1, marginBottom: 2 }} />
    </>
  )
}

export default SubdiagramItem
