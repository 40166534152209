import { ValueSettingInputProps } from '../ValueSettingInput/ValueSettingInput.types'
import { TextField } from '@mui/material'
import useStringValueSettingInput from './useStringValueSettingInput'

const StringValueSettingInput = (props: ValueSettingInputProps) => {
  const { string, handleStringChange } = useStringValueSettingInput(props)

  return (
    <TextField
      multiline
      type="text"
      fullWidth
      value={string.toString()}
      onChange={handleStringChange}
    />
  )
}

export default StringValueSettingInput
