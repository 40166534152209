import { ValueSettingInputProps } from '../ValueSettingInput/ValueSettingInput.types'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import booleanValueFactory from '../../features/domain/factories/values/booleanValueFactory'
import BooleanValue from '../../features/domain/models/values/BooleanValue'

const useBooleanValueSettingInput = (props: ValueSettingInputProps) => {
  const { value, onValueChange } = props

  const [boolean, setBoolean] = useState(new BooleanValue())

  useEffect(() => {
    booleanValueFactory
      .tryCreateWithStringRepresentation(value)
      .then(setBoolean)
      .catch(console.log)
  }, [value])

  const handleBooleanChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      const newBoolean = new BooleanValue(checked)
      setBoolean(newBoolean)
      onValueChange(newBoolean.toString())
    },
    [onValueChange]
  )

  return {
    boolean,
    handleBooleanChange
  }
}

export default useBooleanValueSettingInput
