import useNodeFactories from '../../features/application/hooks/useNodeFactories'
import { ChangeEvent, DragEvent, useCallback, useMemo, useState } from 'react'
import nodesToReactFlowNodesTransformer from '../../features/domain/transformation/toPresentation/nodesToReactFlowNodesTransformer'
import useDataTypes from '../../features/application/hooks/useDataTypes'
import NodeSelection from '../../features/domain/models/NodeSelection'
import NodeRepository from '../../features/domain/models/NodeRepository'
import DraggedNode from '../../features/presentation/DraggedNode'

const useNodeToolbox = () => {
  const {
    functionFactories,
    constantInputFactory,
    streamInputFactory,
    outputFactory
  } = useNodeFactories()

  const { dataTypes } = useDataTypes()

  const [isFunctionOpen, setIsFunctionOpen] = useState(false)
  const [isConstantOpen, setIsConstantOpen] = useState(false)
  const [isStreamOpen, setIsStreamOpen] = useState(false)
  const [isOutputOpen, setIsOutputOpen] = useState(false)
  const [search, setSearch] = useState('')

  const functionNodePreviews = useMemo(
    () =>
      nodesToReactFlowNodesTransformer.transform(
        functionFactories
          .map((functionFactory) =>
            functionFactory.create('n:1', 0, 0, {}, new NodeSelection())
          )
          .filter((node) =>
            node.name.toLowerCase().startsWith(search.toLowerCase())
          ),
        undefined
      ),
    [functionFactories, search]
  )

  const constantInputNodePreviews = useMemo(
    () =>
      nodesToReactFlowNodesTransformer.transform(
        dataTypes
          .filter((dataType) =>
            dataType.name.toLowerCase().startsWith(search.toLowerCase())
          )
          .map((dataType) =>
            constantInputFactory.create(
              'n:1',
              0,
              0,
              dataType,
              {},
              new NodeSelection(),
              new NodeRepository([])
            )
          ),
        undefined
      ),
    [constantInputFactory, dataTypes, search]
  )

  const streamInputNodePreviews = useMemo(
    () =>
      nodesToReactFlowNodesTransformer.transform(
        dataTypes
          .filter((dataType) =>
            dataType.name.toLowerCase().startsWith(search.toLowerCase())
          )
          .map((dataType) =>
            streamInputFactory.create(
              'n:1',
              0,
              0,
              dataType,
              {},
              new NodeSelection(),
              new NodeRepository([])
            )
          ),
        undefined
      ),
    [dataTypes, search, streamInputFactory]
  )

  const outputNodePreviews = useMemo(
    () =>
      nodesToReactFlowNodesTransformer.transform(
        dataTypes
          .filter((dataType) =>
            dataType.name.toLowerCase().startsWith(search.toLowerCase())
          )
          .map((dataType) =>
            outputFactory.create(
              'n:1',
              0,
              0,
              dataType,
              {},
              new NodeSelection(),
              new NodeRepository([])
            )
          ),
        undefined
      ),
    [dataTypes, outputFactory, search]
  )

  const handleFunctionPreviewsButtonClick = useCallback(() => {
    setIsFunctionOpen(!isFunctionOpen)
  }, [isFunctionOpen])

  const handleStreamPreviewsButtonClick = useCallback(() => {
    setIsStreamOpen(!isStreamOpen)
  }, [isStreamOpen])

  const handleConstantPreviewsButtonClick = useCallback(() => {
    setIsConstantOpen(!isConstantOpen)
  }, [isConstantOpen])

  const handleOutputPreviewsButtonClick = useCallback(() => {
    setIsOutputOpen(!isOutputOpen)
  }, [isOutputOpen])

  const handleSearchChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSearch(event.target.value)
    },
    []
  )

  const isFunctionPreviewsOpen = useMemo(
    () => search.length > 0 || isFunctionOpen,
    [isFunctionOpen, search.length]
  )

  const isConstantPreviewsOpen = useMemo(
    () => search.length > 0 || isConstantOpen,
    [isConstantOpen, search.length]
  )

  const isStreamPreviewsOpen = useMemo(
    () => search.length > 0 || isStreamOpen,
    [isStreamOpen, search.length]
  )

  const isOutputPreviewsOpen = useMemo(
    () => search.length > 0 || isOutputOpen,
    [isOutputOpen, search.length]
  )

  const handleDragStart = useCallback(
    <TData>(event: DragEvent<HTMLDivElement>, node: DraggedNode<TData>) => {
      event.dataTransfer.setData('application/dataflow', JSON.stringify(node))
      event.dataTransfer.effectAllowed = 'move'
    },
    []
  )

  return {
    functionNodePreviews,
    constantInputNodePreviews,
    streamInputNodePreviews,
    outputNodePreviews,
    handleFunctionPreviewsButtonClick,
    handleConstantPreviewsButtonClick,
    handleStreamPreviewsButtonClick,
    handleOutputPreviewsButtonClick,
    isFunctionPreviewsOpen,
    isConstantPreviewsOpen,
    isStreamPreviewsOpen,
    isOutputPreviewsOpen,
    handleSearchChange,
    handleDragStart
  }
}

export default useNodeToolbox
