import React from 'react'

function ViewSwitchCase<TCase>(props: {
  case: TCase
  children: React.ReactNode
}) {
  const { children } = props

  return <>{children}</>
}

export default ViewSwitchCase
