import diagramDataStore from '../data/store/diagramDataStore'
import diagramDataModelToDiagramDomainModelTransformer from '../data/transformation/toDomain/diagramDataModelToDiagramDomainModelTransformer'
import Diagram from '../domain/models/Diagram'
import diagramDomainModelToDiagramDataModelTransformer from '../domain/transformation/toData/diagramDomainModelToDiagramDataModelTransformer'

class DiagramRepository {
  async all(): Promise<Diagram[]> {
    const dataModels = await diagramDataStore.loadDiagrams()

    const diagrams: Diagram[] = []
    for (const dataModel of dataModels) {
      diagrams.push(
        diagramDataModelToDiagramDomainModelTransformer.transform(
          dataModel,
          undefined
        )
      )
    }

    return diagrams
  }

  async tryGetByUuid(uuid: string): Promise<Diagram> {
    const diagram = await diagramDataStore.loadDiagram(uuid)
    return diagramDataModelToDiagramDomainModelTransformer.transform(
      diagram,
      undefined
    )
  }

  async set(diagram: Diagram): Promise<void> {
    const dataModel = diagramDomainModelToDiagramDataModelTransformer.transform(
      diagram,
      undefined
    )

    await diagramDataStore.saveDiagram(dataModel)
  }

  async delete(uuid: string): Promise<void> {
    await diagramDataStore.deleteDiagram(uuid)
  }
}

export default DiagramRepository
