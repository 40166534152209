import Node from './Node'
import Outputable from './Outputable'
import DiagramPropertyPropertyString from '../../data/models/DiagramPropertyPropertyString'
import DataTypeable from './DataTypeable'
import NodeIdService from './NodeIdService'
import nodeUtils from '../utils/nodeUtils'
import NodeSelection from './NodeSelection'
import Subdiagram from './Subdiagram'
import Handle from './Handle'
import Output from './Output'
import TypedHandle from './TypedHandle'
import DataType from './dataTypes/DataType'

abstract class InputNode implements Node, Outputable, DataTypeable {
  public properties: Partial<Record<DiagramPropertyPropertyString, string>>

  public x: number

  public y: number

  public readonly dataType: DataType

  private readonly id: string

  private readonly outputHandle: Handle<Output>

  protected readonly _nodeSelection: NodeSelection

  public subdiagram: Subdiagram | undefined

  constructor(
    id: string,
    x: number,
    y: number,
    dataType: DataType,
    properties: Partial<Record<DiagramPropertyPropertyString, string>>,
    nodeSelection: NodeSelection
  ) {
    this.id = id
    this.x = x
    this.y = y
    this.dataType = dataType
    this.properties = properties
    this.outputHandle = new TypedHandle(this, new Output(), dataType)
    this._nodeSelection = nodeSelection
  }

  getId(): string {
    return this.id
  }

  getOutputHandle(): Handle<Output> {
    return this.outputHandle
  }

  abstract duplicate(nodeIdService: NodeIdService): Promise<InputNode>

  isCompatibleWith(node: Node): boolean {
    return (
      !nodeUtils.isInputable(node) &&
      nodeUtils.isOutputable(node) &&
      this.getOutputHandle().isCompatibleWith(
        (node as Node & Outputable).getOutputHandle()
      )
    )
  }

  get selected(): boolean {
    return this._nodeSelection.has(this)
  }

  public onCreateConnection() {}

  public onRemoveConnection() {}

  get color(): string {
    return this.properties.Color ?? 'blue'
  }

  set color(color: string) {
    if (color === 'blue') {
      delete this.properties.Color
      return
    }
    this.properties.Color = color
  }
}

export default InputNode
