import Node from '../models/Node'
import ContainerNode from '../models/ContainerNode'

export const formatNodeId = (nodeId: number | string): string => `n:${nodeId}`

export const formatContainerId = (nodeIds: (number | string)[]): string =>
  `c:(${nodeIds.join(',')})`

export const formatHandle = (
  variantString: string,
  formattedNodeId: string,
  index: number
): string => `${variantString}-${formattedNodeId}#${index}`

export const formatNodeIdFromNode = (node: Node): string => {
  if (node instanceof ContainerNode) {
    const formattedChildNodeIds: string[] = []
    for (const child of node.getChildNodes()) {
      formattedChildNodeIds.push(formatNodeIdFromNode(child))
    }

    return formatContainerId(formattedChildNodeIds)
  }

  return node.getId()
}
