import { ValueSettingInputProps } from '../ValueSettingInput/ValueSettingInput.types'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import stringValueFactory from '../../features/domain/factories/values/stringValueFactory'
import StringValue from '../../features/domain/models/values/StringValue'

const useStringValueSettingInput = (props: ValueSettingInputProps) => {
  const { value, onValueChange } = props

  const [string, setString] = useState(new StringValue())

  useEffect(() => {
    stringValueFactory
      .tryCreateWithStringRepresentation(value)
      .then(setString)
      .catch(console.log)
  }, [value])

  const handleStringChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newValue = new StringValue(event.target.value)
      setString(newValue)
      onValueChange(newValue.toString())
    },
    [onValueChange]
  )

  return {
    string,
    handleStringChange
  }
}

export default useStringValueSettingInput
