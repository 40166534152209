import { useState, useCallback } from 'react'

const useThrowAsyncError = () => {
  const [, setError] = useState()

  return useCallback(
    (e: Error) => {
      setError(() => {
        throw e
      })
    },
    [setError]
  )
}

export default useThrowAsyncError
