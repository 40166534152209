import { ValueSettingInputProps } from '../ValueSettingInput/ValueSettingInput.types'
import { Switch } from '@mui/material'
import useBooleanValueSettingInput from './useBooleanValueSettingInput'

const BooleanValueSettingInput = (props: ValueSettingInputProps) => {
  const { boolean, handleBooleanChange } = useBooleanValueSettingInput(props)

  return <Switch checked={boolean.value} onChange={handleBooleanChange} />
}

export default BooleanValueSettingInput
