import { ValueSettingInputProps } from '../ValueSettingInput/ValueSettingInput.types'
import { Button, Stack, TextField } from '@mui/material'
import useSequenceValueSettingInput from './useSequenceValueSettingInput'
import { SaveOutlined } from '@mui/icons-material'

const SequenceValueSettingInput = (props: ValueSettingInputProps) => {
  const { text, handleTextChange, handleSave, errorText } =
    useSequenceValueSettingInput(props)

  return (
    <Stack spacing={2}>
      <TextField
        error={!!errorText}
        multiline
        type="text"
        fullWidth
        value={text}
        onChange={handleTextChange}
        helperText={errorText}
      />
      <Stack direction="row-reverse">
        <Button
          endIcon={<SaveOutlined />}
          onClick={handleSave}
          size="small"
          variant="outlined"
        >
          Save
        </Button>
      </Stack>
    </Stack>
  )
}

export default SequenceValueSettingInput
