import Transformer from '../../../transformation/Transformer'
import DiagramPropertyDataModel from '../../models/DiagramPropertyDataModel'
import DiagramPropertyPropertyString from '../../models/DiagramPropertyPropertyString'
import CompositedNodePropertiesPartial from './types/CompositedNodePropertiesPartial'

const diagramPropertyDataModelsToCompositeNodePropertiesPartialMapTransformer: Transformer<
  DiagramPropertyDataModel[],
  Record<number, CompositedNodePropertiesPartial>
> = {
  transform: (positions) => {
    return positions.reduce<Record<number, CompositedNodePropertiesPartial>>(
      (map, property) => {
        if (!map[property.id]) {
          map[property.id] = {
            id: property.id,
            properties: {} as Record<DiagramPropertyPropertyString, string>
          }
        }

        map[property.id].properties[property.propertyString] =
          property.valueString
        return map
      },
      {}
    )
  }
}

export default diagramPropertyDataModelsToCompositeNodePropertiesPartialMapTransformer
