import { z } from 'zod'
import DiagramNodeDataModel from '../models/DiagramNodeDataModel'

const diagramNodeDataModelValidator: z.ZodType<DiagramNodeDataModel> = z.object(
  {
    id: z.number(),
    classString: z.enum([
      'Input stream',
      'Constant',
      'Function',
      'Variable output'
    ]),
    typeString: z.union([
      z.enum([
        'Float',
        'Integer',
        'Vector',
        'Boolean',
        'String',
        'Orientation',
        'Sequence',
        'Float array',
        'Integer array',
        'Vector array',
        'Boolean array',
        'String array',
        'Orientation array',
        'Sequence array'
      ]),
      z.enum([
        'Absolute',
        'Add',
        'Adder',
        'Add to array',
        'And',
        'Array absolute',
        'Array sum',
        'Average',
        'Averager',
        'Buffer',
        'Cap values',
        'Centered moving average',
        'Clip',
        'Cosine',
        'Create sequence',
        'Degrees to radians',
        'Delay',
        'Distance',
        'Divide',
        'Duration',
        'End time',
        'Equal',
        'Euler to quaternion',
        'Float',
        'Float interpolation',
        'Gate',
        'Get element',
        'Get interpolated values',
        'Insert to array',
        'Integer',
        'Integral',
        'Intersections',
        'Inverse cosine',
        'Inverse quaternion',
        'Inverse sine',
        'Inverse tangent',
        'JSON output',
        'Larger',
        'Larger or equal',
        'Make quaternion',
        'Make vector',
        'Max',
        'Maximum',
        'Min',
        'Minimum',
        'Moving averager',
        'Multiply',
        'Normalize',
        'Normalize quaternion',
        'Normalize vector',
        'Not',
        'Number of elements',
        'Or',
        'Order',
        'Pass updated',
        'Pass-through',
        'Power',
        'Quaternion angle',
        'Quaternion axis',
        'Quaternion interpolation',
        'Quaternion spline',
        'Quaternion to Euler',
        'Radians to degrees',
        'Remove from array',
        'Resample',
        'Rotate vector',
        'Round',
        'Scale',
        'Scale time',
        'Select interval',
        'Sequence rectifier',
        'Sequence timestamps',
        'Sequence values',
        'Sequencer',
        'Shift time',
        'Sine',
        'Smaller',
        'Smaller or equal',
        'Start time',
        'Subtract',
        'Switch',
        'Tangent',
        'Time derivative',
        'Time integral',
        'Timestamp',
        'Toggled',
        'Trigger',
        'Trim',
        'Update counter',
        'Updated',
        'Vector angle',
        'Vector cross product',
        'Vector dot product',
        'Vector length',
        'Vector x-coordinate',
        'Vector y-coordinate',
        'Vector z-coordinate',
        'Xor',
        'Insert to array',
        'Combine arrays',
        'Convert to sequence',
        'Create array',
        'Derivative',
        'Negate',
        'Peak detector',
        'Quaternion',
        'Search array',
        'Shift elements'
      ])
    ]),
    outputType: z
      .enum([
        'Float',
        'Integer',
        'Vector',
        'Boolean',
        'String',
        'Orientation',
        'Sequence',
        'Float array',
        'Integer array',
        'Vector array',
        'Boolean array',
        'String array',
        'Orientation array',
        'Sequence array'
      ])
      .optional()
  }
)

export default diagramNodeDataModelValidator
