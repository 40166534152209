import { Stack } from '@mui/material'
import React from 'react'
import DescriptionSetting from '../DescriptionSetting'
import ColorSetting from '../ColorSetting'
import { OutputNodeSettingsProps } from './OutputNodeSettings.types'
import LoopSetting from '../LoopSetting'
import HiddenSetting from '../HiddenSetting'

const OutputNodeSettings = (props: OutputNodeSettingsProps) => {
  const { node, onChangeColor, onChangeDescription, onChangeHidden } = props

  return (
    <Stack spacing={2}>
      <LoopSetting looping={node.looping} />
      <DescriptionSetting
        description={node?.properties?.Description}
        onDescriptionChange={onChangeDescription}
        error={!node.hasUniqueDescription}
        helperText={
          !node.hasUniqueDescription
            ? 'Description is not unique amongst output nodes'
            : undefined
        }
      />
      <ColorSetting color={node.color} onColorChange={onChangeColor} />
      <HiddenSetting hidden={node.hidden} onHiddenChange={onChangeHidden} />
    </Stack>
  )
}

export default OutputNodeSettings
