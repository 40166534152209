import { z } from 'zod'
import DiagramConnectionDataModel from '../models/DiagramConnectionDataModel'

const diagramConnectionDataModelValidator: z.ZodType<DiagramConnectionDataModel> =
  z.object({
    inputIndex: z.number(),
    outputNodeId: z.number(),
    inputNodeId: z.number()
  })

export default diagramConnectionDataModelValidator
