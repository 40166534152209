import ValueFactory from './ValueFactory'
import StringValue from '../../models/values/StringValue'

const stringValueFactory: ValueFactory<StringValue> = {
  tryCreateWithStringRepresentation(
    representation: string
  ): Promise<StringValue> {
    return Promise.resolve(new StringValue(representation))
  }
}

export default stringValueFactory
