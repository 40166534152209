import { NodeProps, Position } from 'reactflow'
import DatatypeHandle from '../DatatypeHandle'
import Label from '../Label'
import useStreamInputNode from './useStreamInputNode'
import './InputNode.styles.css'
import StreamInputNodeData from '../../features/presentation/StreamInputNodeData'

const StreamInputNode = (props: NodeProps<StreamInputNodeData>) => {
  const { output, label, labelColor, selected, color, forwarding, looping } =
    useStreamInputNode(props)

  return (
    <div style={{ position: 'relative' }}>
      <svg
        style={{ display: 'block' }}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 313.34 180"
      >
        <polygon
          fill={color ?? '#fff'}
          className={selected ? 'selected-node-outline' : 'node-stroke'}
          points="310.28 90 250.28 175 20.28 175 20.28 5 250.28 5 310.28 90"
        />
        {forwarding && (
          <polyline
            fill="none"
            className={selected ? 'selected-node-outline' : 'node-stroke'}
            points="235.28 5 295.28 90 235.28 175"
          />
        )}
        {looping && (
          <line
            stroke="gray"
            strokeLinecap="round"
            strokeWidth={10}
            x1="5"
            y1="5"
            x2="5"
            y2="175"
          />
        )}
      </svg>
      <DatatypeHandle
        id={output.id}
        type="source"
        position={Position.Right}
        symbol={output.symbol}
      />
      <Label
        style={{
          width: 'calc(100% - 30px)'
        }}
        color={labelColor}
      >
        {label}
      </Label>
    </div>
  )
}

export default StreamInputNode
