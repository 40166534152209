import Node from './Node'
import DiagramPropertyPropertyString from '../../data/models/DiagramPropertyPropertyString'
import DataTypeable from './DataTypeable'
import Inputable from './Inputable'
import handleUtils from '../utils/handleUtils'
import NodeIdService from './NodeIdService'
import nodeUtils from '../utils/nodeUtils'
import NodeSelection from './NodeSelection'
import NodeRepository from './NodeRepository'
import Loopable from './Loopable'
import StreamInputNode from './StreamInputNode'
import Subdiagram from './Subdiagram'
import Handle from './Handle'
import Input from './Input'
import TypedHandle from './TypedHandle'
import DataType from './dataTypes/DataType'
import NodeDuplicationOptions from './NodeDuplicationOptions'

class OutputNode implements Node, Inputable, DataTypeable, Loopable {
  public properties: Partial<Record<DiagramPropertyPropertyString, string>>

  public x: number

  public y: number

  public readonly dataType: DataType

  private readonly id: string

  private readonly inputHandles: Handle<Input>[]

  private readonly _nodeSelection: NodeSelection

  private readonly _nodeRepository: NodeRepository

  public subdiagram: Subdiagram | undefined

  constructor(
    id: string,
    x: number,
    y: number,
    dataType: DataType,
    properties: Partial<Record<DiagramPropertyPropertyString, string>>,
    nodeSelection: NodeSelection,
    nodeRepository: NodeRepository
  ) {
    this.id = id
    this.x = x
    this.y = y
    this.dataType = dataType
    this.properties = properties
    this.inputHandles = [new TypedHandle(this, new Input(), dataType)]
    this._nodeSelection = nodeSelection
    this._nodeRepository = nodeRepository
  }

  getId(): string {
    return this.id
  }

  getInputHandles(): Handle<Input>[] {
    return this.inputHandles
  }

  getInputHandleById(id: string): Handle<Input> | undefined {
    return handleUtils.findHandleByIdIn(this.inputHandles, id)
  }

  getInputHandleByIndex(index: number): Handle<Input> | undefined {
    return handleUtils.findHandleByIndexIn(this.getInputHandles(), index)
  }

  duplicate(
    nodeIdService: NodeIdService,
    options?: NodeDuplicationOptions
  ): Promise<OutputNode> {
    const node = new OutputNode(
      nodeIdService.getNextNodeId(),
      this.x,
      this.y,
      this.dataType,
      { ...this.properties },
      options?.nodeSelection ?? this._nodeSelection,
      options?.nodeRepository ?? this._nodeRepository
    )

    node.subdiagram = this.subdiagram

    nodeIdService.incrementId()

    return Promise.resolve(node)
  }

  findFirstInputHandle(
    where: (handle: Handle<Input>) => boolean
  ): Handle<Input> | undefined {
    return handleUtils.findFirstHandle(this.inputHandles, where)
  }

  isCompatibleWith(node: Node): boolean {
    return (
      !nodeUtils.isOutputable(node) &&
      nodeUtils.isInputable(node) &&
      handleUtils.isHandleArrayCompatible(
        this.getInputHandles(),
        (node as Node & Inputable).getInputHandles()
      )
    )
  }

  get selected(): boolean {
    return this._nodeSelection.has(this)
  }

  get hasUniqueDescription(): boolean {
    return (
      !this.properties.Description ||
      this._nodeRepository.filter(
        (node) =>
          node instanceof OutputNode &&
          node.properties.Description === this.properties.Description
      ).length === 1
    )
  }

  get looping(): boolean {
    return (
      this.hasUniqueDescription &&
      this._nodeRepository.filter(
        (node) =>
          node instanceof StreamInputNode &&
          !!node.properties.Description &&
          !!this.properties.Description &&
          node.dataType.isCompatible(this.dataType) &&
          node.properties.Description === this.properties.Description
      ).length > 0
    )
  }

  public get hidden(): boolean {
    return this.properties.Hidden === 'True'
  }

  public set hidden(value: boolean) {
    if (value) {
      this.properties.Hidden = 'True'
      return
    }
    delete this.properties.Hidden
  }

  public onCreateConnection() {}

  public onRemoveConnection() {}

  get color(): string {
    return this.properties.Color ?? 'blue'
  }

  set color(color: string) {
    if (color === 'blue') {
      delete this.properties.Color
      return
    }
    this.properties.Color = color
  }
}

export default OutputNode
