import { useCallback } from 'react'
import { toPng as htmlToImageToPng } from 'html-to-image'

const useDiagramToImage = () => {
  const filter = useCallback((node: HTMLElement) => {
    return !(
      node?.classList?.contains('react-flow__minimap') ||
      node?.classList?.contains('react-flow__controls') ||
      node?.classList?.contains('react-flow__background')
    )
  }, [])

  const toPng = useCallback(
    () =>
      htmlToImageToPng(
        document.querySelector<HTMLElement>('.react-flow') as HTMLElement,
        {
          filter
        }
      ),
    [filter]
  )

  return {
    toPng
  }
}

export default useDiagramToImage
