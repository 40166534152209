import Node from './Node'
import ConnectionRepository from './ConnectionRepository'
import DiagramView from './DiagramView'
import Connection from './Connection'
import ContainerNode from './ContainerNode'
import Outputable from './Outputable'
import Inputable from './Inputable'
import OutputNode from './OutputNode'
import StreamInputNode from './StreamInputNode'
import NodeIdService from './NodeIdService'
import NodeFactory from '../factories/NodeFactory'
import FunctionTypeNodeFactory from '../factories/FunctionTypeNodeFactory'
import nodeUtils from '../utils/nodeUtils'
import NodeSelection from './NodeSelection'
import NodeRepository from './NodeRepository'
import SubdiagramRepository from './SubdiagramRepository'
import Subdiagram from './Subdiagram'
import subdiagramFactory from '../factories/subdiagramFactory'
import SubdiagramIdService from './SubdiagramIdService'
import Handle from './Handle'
import Output from './Output'
import Input from './Input'
import DataType from './dataTypes/DataType'
import Position from './Position'
import containerUtils from '../utils/containerUtils'

class Diagram {
  private readonly _nodeRepository: NodeRepository

  private readonly _subdiagramRepository: SubdiagramRepository

  private readonly _subdiagramIdService: SubdiagramIdService

  private readonly _nodeSelection: NodeSelection

  private readonly _connectionRepository: ConnectionRepository

  private readonly _nodeIdService: NodeIdService

  public manualLines: string[]

  public readonly uuid: string

  public name: string

  private _selectedSubdiagram: Subdiagram | undefined

  constructor(
    uuid: string,
    name: string,
    nodeRepository: NodeRepository,
    connectionRepository: ConnectionRepository,
    manualLines: string[],
    nodeIdService: NodeIdService,
    nodeSelection: NodeSelection,
    subdiagramRepository: SubdiagramRepository,
    subdiagramIdService: SubdiagramIdService
  ) {
    this.uuid = uuid
    this.name = name
    this._nodeRepository = nodeRepository
    this._connectionRepository = connectionRepository
    this.manualLines = manualLines
    this._nodeIdService = nodeIdService
    this._nodeSelection = nodeSelection
    this._subdiagramRepository = subdiagramRepository
    this._selectedSubdiagram = undefined
    this._subdiagramIdService = subdiagramIdService
  }

  get nodeIdService() {
    return this._nodeIdService
  }

  get connectionRepository() {
    return this._connectionRepository
  }

  get nodeRepository(): NodeRepository {
    return this._nodeRepository
  }

  get subdiagramRepository(): SubdiagramRepository {
    return this._subdiagramRepository
  }

  getAllNodes(): Node[] {
    return this._nodeRepository.all
  }

  getAllConnections(): Readonly<Readonly<Connection>[]> {
    return this._connectionRepository.getAllConnections()
  }

  public async tryPaste({ nodes, connections }: DiagramView): Promise<void> {
    const duplicatedNodes: Node[] = []

    const nodeIdConversionMap: Record<string, string> = {}

    for (const node of nodes) {
      const duplicatedNode = await node.duplicate(this._nodeIdService, {
        nodeSelection: this._nodeSelection,
        nodeRepository: this._nodeRepository,
        connectionRepository: this._connectionRepository
      })

      nodeIdConversionMap[node.getId()] = duplicatedNode.getId()

      duplicatedNode.subdiagram = this._selectedSubdiagram

      duplicatedNode.x += 50
      duplicatedNode.y += 50

      duplicatedNodes.push(duplicatedNode)
    }

    const duplicatedConnections =
      await this._connectionRepository.duplicateConnectionsForNodes(
        nodes,
        duplicatedNodes,
        nodeIdConversionMap,
        connections
      )

    this._connectionRepository.addConnections(duplicatedConnections)

    this._nodeRepository.add(...duplicatedNodes)

    this._nodeSelection.set(duplicatedNodes)

    return Promise.resolve()
  }

  public addNode(
    factory: NodeFactory,
    dataType: DataType,
    position = this._findCenteredPositionForNodes(this._nodeRepository.all)
  ): void {
    const node = factory.create(
      this._nodeIdService.getNextNodeId(),
      position.x,
      position.y,
      dataType,
      {},
      this._nodeSelection,
      this._nodeRepository
    )
    this._nodeIdService.incrementId()
    this._nodeRepository.add(node)
    this._selectedSubdiagram?.addNode(node)
    this._nodeSelection.set([node])
  }

  public addFunctionNode(
    factory: FunctionTypeNodeFactory,
    position = this._findCenteredPositionForNodes(this._nodeRepository.all)
  ): void {
    const node = factory.create(
      this._nodeIdService.getNextNodeId(),
      position.x,
      position.y,
      {},
      this._nodeSelection
    )
    this._nodeIdService.incrementId()
    this._nodeRepository.add(node)
    this._selectedSubdiagram?.addNode(node)
    this._nodeSelection.set([node])
  }

  public getNodeById(nodeId: string): Node | undefined {
    return this._nodeRepository.getById(nodeId)
  }

  public deleteNodesByIds(nodeIds: string[]): void {
    const nodesToBeDeleted = this._nodeRepository.deleteByIds(nodeIds)

    const connections = this._connectionRepository.getConnectionsForNodes(
      nodesToBeDeleted,
      true
    )

    for (const connection of connections) {
      this._connectionRepository.removeConnection(connection)
    }

    for (const node of nodesToBeDeleted) {
      this._selectedSubdiagram?.removeNode(node)
    }

    this._nodeSelection.clear()
  }

  async tryCreateConnectionBetween(
    outputNodeId: string,
    inputNodeId: string,
    onInputHandleId: string
  ): Promise<Connection> {
    const { inputHandle, outputHandle } =
      await this._tryGetConnectingHandlesBetween(
        outputNodeId,
        inputNodeId,
        onInputHandleId
      )

    const existingInputConnection =
      this._connectionRepository.getConnectionForHandle(inputHandle)

    if (existingInputConnection) {
      this._connectionRepository.removeConnection(existingInputConnection)
    }

    const connection = await inputHandle.createConnection(outputHandle)

    this._connectionRepository.addConnection(connection)

    return connection
  }

  public async tryRemoveConnectionBetween(
    outputNodeId: string,
    inputNodeId: string,
    onInputHandleId: string
  ) {
    const { inputHandle, outputHandle } =
      await this._tryGetConnectingHandlesBetween(
        outputNodeId,
        inputNodeId,
        onInputHandleId
      )

    const connection = await inputHandle.createConnection(outputHandle)

    this._connectionRepository.removeConnection(connection)
  }

  private async _tryGetConnectingHandlesBetween(
    outputNodeId: string,
    inputNodeId: string,
    onInputHandleId: string
  ): Promise<{ outputHandle: Handle<Output>; inputHandle: Handle<Input> }> {
    const outputNode = this._nodeRepository.getById(outputNodeId) as
      | (Node & Outputable)
      | undefined

    const inputNode = this._nodeRepository.getById(inputNodeId) as
      | (Node & Inputable)
      | undefined

    if (outputNode === undefined) {
      return Promise.reject(`node '${outputNodeId}' was not found`)
    }

    if (inputNode === undefined) {
      return Promise.reject(`node '${inputNodeId}' was not found`)
    }

    if (outputNode.getOutputHandle === undefined) {
      return Promise.reject(`node '${outputNodeId}' has no output`)
    }

    if (inputNode.getInputHandleById === undefined) {
      return Promise.reject(`node '${inputNodeId}' has no output`)
    }

    const inputHandle = inputNode.getInputHandleById(onInputHandleId)

    if (!inputHandle) {
      return Promise.reject(`inputhandle with id: '${inputNodeId}' not found`)
    }

    return {
      outputHandle: outputNode.getOutputHandle(),
      inputHandle
    }
  }

  createDiagramView(): DiagramView {
    const nodes = this._nodeRepository.filter(
      (node) => node.subdiagram?.id === this._selectedSubdiagram?.id
    )

    return {
      connections: this._connectionRepository.getConnectionsForNodes(nodes),
      nodes
    }
  }

  clear(): void {
    this._nodeRepository.clear()
    this._connectionRepository.removeAllConnections()
    this._nodeSelection.clear()
    this._subdiagramRepository.clear()
  }

  public async tryReplaceNode(aId: string, bId: string): Promise<Node> {
    const [a] = this._nodeRepository.getByIds([aId])

    const [b] = this._nodeRepository.getByIds([bId])

    if (!a) {
      return Promise.reject(`Node with id: '${aId}' not found`)
    }

    if (!b) {
      return Promise.reject(`Node with id: '${bId}' not found`)
    }

    const aConnections = this._connectionRepository.getConnectionsForNodes([a])

    const bConnections = this._connectionRepository.getConnectionsForNodes([b])

    if (aConnections.length > 0 && bConnections.length > 0) {
      return Promise.reject(`Both nodes had connections`)
    }

    let replacement = a
    let original = b

    let originalConnections = bConnections

    if (bConnections.length === 0) {
      replacement = b
      original = a

      originalConnections = aConnections
    }

    if (!original.isCompatibleWith(replacement)) {
      return Promise.reject(`nodes are not compatible`)
    }

    const replacementConnections: Connection[] = []

    const usedInputHandleIdMap: Record<string, string> = {}

    for (const originalConnection of originalConnections) {
      if (
        originalConnection.targetNode.getId() === original.getId() &&
        nodeUtils.isInputable(replacement)
      ) {
        const inputableReplacement = replacement as Inputable & Node
        const inputHandle = inputableReplacement.findFirstInputHandle(
          (handle) =>
            handle.isCompatibleWith(originalConnection.target) &&
            !usedInputHandleIdMap[handle.id]
        )

        if (!inputHandle) {
          return Promise.reject(
            'Compatible input handle not found on replacement node'
          )
        }

        const connection = await inputHandle.createConnection(
          originalConnection.source
        )

        replacementConnections.push(connection)

        usedInputHandleIdMap[inputHandle.id] = inputHandle.id
      } else if (
        originalConnection.sourceNode.getId() === original.getId() &&
        nodeUtils.isOutputable(replacement)
      ) {
        const outputableReplacement = replacement as Outputable & Node
        const outputHandle = outputableReplacement.getOutputHandle()

        if (!outputHandle.isCompatibleWith(originalConnection.source)) {
          return Promise.reject(
            'Compatible output handle not found on replacement node'
          )
        }

        const connection = await outputHandle.createConnection(
          originalConnection.target
        )

        replacementConnections.push(connection)
      } else {
        return Promise.reject('Something went wrong')
      }
    }

    replacement.x = original.x
    replacement.y = original.y

    this.deleteNodesByIds([original.getId()])

    this._connectionRepository.addConnections(replacementConnections)

    this._nodeSelection.set([replacement])

    return Promise.resolve(replacement)
  }

  public async tryContain(nodeIds: string[]): Promise<Node> {
    const nodeIdMap = this._createNodeIdMap(nodeIds)

    const nodes = this._nodeRepository.getByIds(nodeIds)

    await this._nodesCanBeAddedToContainer(nodes)

    const { x, y } = this._findCenteredPositionForNodes(nodes)

    const container = new ContainerNode(
      x,
      y,
      {
        Description: '[Container]'
      },
      nodes,
      this._connectionRepository,
      this._nodeSelection
    )

    const newNodes = this._nodeRepository.filter(
      (node) => !nodeIdMap[node.getId()]
    )

    newNodes.push(container)

    this._nodeRepository.set(newNodes)

    this._nodeSelection.set([container])

    return container
  }

  public isContainer(nodeId: string): boolean {
    const node = this.getNodeById(nodeId)
    return node instanceof ContainerNode
  }

  public async tryDecontain(containerNodeId: string): Promise<void> {
    if (!this.isContainer(containerNodeId)) {
      return Promise.reject('node is not a container')
    }

    const container = this.getNodeById(containerNodeId) as ContainerNode

    const newNodes = this._nodeRepository.filter(
      (node) => node.getId() !== container.getId()
    )

    const children = container.getChildNodes()

    newNodes.push(...children)

    this._nodeRepository.set(newNodes)

    this._nodeSelection.set(children)
  }

  public async tryCopy(nodeIds: string[]): Promise<DiagramView> {
    const nodes = this._nodeRepository.getByIds(nodeIds)

    const allConnections = this._connectionRepository.getConnectionsForNodes(
      nodes,
      true
    )

    const flattenedNodeMap = containerUtils
      .flattenNodes(nodes)
      .reduce<Record<string, Node>>((previous, current) => {
        previous[current.getId()] = current
        return previous
      }, {})

    const connections: Connection[] = []
    for (const connection of allConnections) {
      if (
        flattenedNodeMap[connection.targetNode.getId()] &&
        flattenedNodeMap[connection.sourceNode.getId()]
      ) {
        connections.push(connection)
      }
    }

    return Promise.resolve({
      nodes,
      connections
    })
  }

  public async tryDuplicate(nodeIds: string[]): Promise<void> {
    const nodes = this._nodeRepository.getByIds(nodeIds)

    const duplicatedNodes: Node[] = []

    const nodeIdConversionMap: Record<string, string> = {}

    for (const node of nodes) {
      const duplicatedNode = await node.duplicate(this._nodeIdService)

      nodeIdConversionMap[node.getId()] = duplicatedNode.getId()

      duplicatedNode.x += 50
      duplicatedNode.y += 50

      duplicatedNodes.push(duplicatedNode)
    }

    const duplicatedConnections =
      await this._connectionRepository.duplicateConnectionsForNodes(
        nodes,
        duplicatedNodes,
        nodeIdConversionMap
      )

    this._connectionRepository.addConnections(duplicatedConnections)

    this._nodeRepository.add(...duplicatedNodes)

    this._nodeSelection.set(duplicatedNodes)

    return Promise.resolve()
  }

  public getSelectedNodeIds(): string[] {
    return this._nodeSelection.allIds()
  }

  public setSelectedNodeIds(nodeIds: string[]): void {
    this._nodeSelection.set(this._nodeRepository.getByIds(nodeIds))
  }

  public createSubdiagram(description = '') {
    this.subdiagramRepository.add(
      subdiagramFactory.create(
        this._subdiagramIdService.getNextSubdiagramId(),
        description
      )
    )
    this._subdiagramIdService.incrementId()
  }

  public deleteSubdiagramById(id: number) {
    const subdiagram = this.subdiagramRepository.geyById(id)
    if (!subdiagram) {
      return
    }

    if (subdiagram.id === this._selectedSubdiagram?.id) {
      this._selectedSubdiagram = undefined
    }

    this.subdiagramRepository.remove(subdiagram)

    const nodeIds = this._nodeRepository
      .filter((node) => node.subdiagram?.id === subdiagram.id)
      .map((node) => node.getId())

    this.deleteNodesByIds(nodeIds)
  }

  public get subdiagrams(): ReadonlyArray<Subdiagram> {
    return this.subdiagramRepository.all
  }

  public setSelectedSubdiagram(subdiagram: Subdiagram | undefined): void {
    this._selectedSubdiagram = subdiagram
      ? this.subdiagramRepository.geyById(subdiagram.id)
      : undefined
  }

  public get selectedSubdiagram(): Subdiagram | undefined {
    return this._selectedSubdiagram
  }

  private _nodesCanBeAddedToContainer(nodes: Node[]): Promise<void> {
    const nodesWithOutsideConnections: Node[] = []

    const inputHandleMap: Record<string, string> = {}
    for (const node of nodes) {
      const inputable = node as Inputable & Node
      if (typeof inputable.getInputHandles !== 'function') {
        continue
      }
      for (const inputHandle of inputable.getInputHandles()) {
        inputHandleMap[inputHandle.id] = inputHandle.id
      }
    }

    for (const node of nodes) {
      if (node instanceof OutputNode || node instanceof StreamInputNode) {
        return Promise.reject(
          `Container could not be created for node: ${
            node.properties.Description ?? node.getId()
          } because the node was a OutputNode or StreamInputNode.`
        )
      }

      const outputable = node as Outputable & Node
      if (typeof outputable.getOutputHandle !== 'function') {
        continue
      }

      const outputHandle = outputable.getOutputHandle()

      const connection =
        this._connectionRepository.getConnectionForHandle(outputHandle)

      if (!connection) {
        nodesWithOutsideConnections.push(node)
        continue
      }

      if (connection && !inputHandleMap[connection.target.id]) {
        nodesWithOutsideConnections.push(node)
      }
    }

    if (nodesWithOutsideConnections.length === 0) {
      return Promise.reject(
        `Container could not be created because the container would not have a output.`
      )
    }

    if (nodesWithOutsideConnections.length > 1) {
      return Promise.reject(
        `Container could not be created because the container would have multiple outputs.`
      )
    }

    return Promise.resolve()
  }

  private _findCenteredPositionForNodes(nodes: Node[]): Position {
    if (nodes.length === 0) {
      return new Position(0, 0)
    }

    const first = nodes[0]

    let minX = first.x
    let maxX = first.x

    let minY = first.y
    let maxY = first.y

    for (const node of nodes) {
      if (node.x < minX) {
        minX = node.x
      }

      if (node.x > maxX) {
        maxX = node.x
      }

      if (node.y < minY) {
        minY = node.y
      }

      if (node.y > maxY) {
        maxY = node.y
      }
    }

    const x = (minX + maxX) / 2

    const y = (minY + maxY) / 2

    return new Position(x, y)
  }

  private _createNodeIdMap(nodeIds: string[]): Record<string, string> {
    return nodeIds.reduce<Record<string, string>>((previous, current) => {
      previous[current] = current
      return previous
    }, {})
  }
}

export default Diagram
