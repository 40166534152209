import useChangeHistory from './useChangeHistory'
import DataDiagram from '../../data/models/Diagram'
import { useCallback } from 'react'
import DomainDiagram from '../../domain/models/Diagram'
import diagramDomainModelToDiagramDataModelTransformer from '../../domain/transformation/toData/diagramDomainModelToDiagramDataModelTransformer'
import diagramDataModelToDiagramDomainModelTransformer from '../../data/transformation/toDomain/diagramDataModelToDiagramDomainModelTransformer'

const useDiagramChangeHistory = () => {
  const {
    set: setData,
    undo: undoData,
    redo: redoData,
    hasRedo,
    hasUndo
  } = useChangeHistory<DataDiagram>()

  const set = useCallback(
    (diagram: DomainDiagram) => {
      const data = diagramDomainModelToDiagramDataModelTransformer.transform(
        diagram,
        undefined
      )

      setData(data)
    },
    [setData]
  )

  const undo = useCallback(() => {
    const data = undoData()
    if (!data) {
      return data
    }

    return diagramDataModelToDiagramDomainModelTransformer.transform(
      data,
      undefined
    )
  }, [undoData])

  const redo = useCallback(() => {
    const data = redoData()
    if (!data) {
      return data
    }

    return diagramDataModelToDiagramDomainModelTransformer.transform(
      data,
      undefined
    )
  }, [redoData])

  return {
    set,
    undo,
    redo,
    hasUndo,
    hasRedo
  }
}

export default useDiagramChangeHistory
