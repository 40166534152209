import { Stack } from '@mui/material'
import React from 'react'
import { ConstantInputNodeSettingsProps } from './ConstantInputNodeSettings.types'
import DescriptionSetting from '../DescriptionSetting'
import ColorSetting from '../ColorSetting'
import ValueSetting from '../ValueSetting/ValueSetting.view'

const ConstantInputNodeSettings = (props: ConstantInputNodeSettingsProps) => {
  const { node, onChangeColor, onChangeDescription, onChangeValue } = props

  return (
    <Stack spacing={2}>
      <DescriptionSetting
        description={node?.properties?.Description}
        onDescriptionChange={onChangeDescription}
      />
      <ColorSetting color={node.color} onColorChange={onChangeColor} />
      <ValueSetting
        dataType={node.dataType}
        value={node.properties.OutputValue || ''}
        onValueChange={onChangeValue}
      />
    </Stack>
  )
}

export default ConstantInputNodeSettings
