import Transformer from '../../../transformation/Transformer'
import { Node as ReactFlowNode } from 'reactflow'
import reactFlowNodeFactory from './factories/reactFlowNodeFactory'
import ConstantInputNode from '../../models/ConstantInputNode'
import ConstantInputNodeData from '../../../presentation/ConstantInputNodeData'
import handleToReactFlowHandleTransformer from './handleToReactFlowHandleTransformer'

const constantInputNodeToReactFlowConstantInputNodeTransformer: Transformer<
  ConstantInputNode,
  ReactFlowNode<ConstantInputNodeData>
> = {
  transform: (node) =>
    reactFlowNodeFactory.create<ConstantInputNodeData>(
      node,
      {
        dataType: node.dataType,
        color: node.color,
        label: node.properties.Description ?? (node.dataType.name as string),
        output: handleToReactFlowHandleTransformer.transform(
          node.getOutputHandle(),
          undefined
        ),
        value: node.properties.OutputValue
      },
      {
        type: 'constantInput'
      }
    )
}

export default constantInputNodeToReactFlowConstantInputNodeTransformer
