import { useCallback } from 'react'

const useNumberParsers = () => {
  const parseIntOrDefault = useCallback(
    (string: string, defaultValue = 0) =>
      isNaN(parseInt(string)) ? defaultValue : parseInt(string),
    []
  )

  const parseFloatOrDefault = useCallback(
    (string: string, defaultValue = 0.0) =>
      isNaN(parseFloat(string)) ? defaultValue : parseFloat(string),
    []
  )

  return {
    parseIntOrDefault,
    parseFloatOrDefault
  }
}

export default useNumberParsers
