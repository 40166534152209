import { SubdiagramTabsProps } from './SubdiagramTabs.types'
import { SyntheticEvent, useCallback, useMemo } from 'react'
import Subdiagram from '../../features/domain/models/Subdiagram'

const useSubdiagramTabs = (props: SubdiagramTabsProps) => {
  const { subdiagrams, selectedSubdiagram, onSelectSubdiagram } = props

  const handleChange = useCallback(
    (event: SyntheticEvent, value: unknown) =>
      onSelectSubdiagram(value as Subdiagram | undefined),
    [onSelectSubdiagram]
  )

  const selectedTab = useMemo(
    () => selectedSubdiagram ?? 'none',
    [selectedSubdiagram]
  )

  return {
    handleChange,
    subdiagrams,
    selectedTab
  }
}

export default useSubdiagramTabs
