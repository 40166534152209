import { useCallback, useContext, useMemo } from 'react'
import diagramServiceContext from '../context/diagramServiceContext'
import DiagramFunctionType from '../../data/models/DiagramFunctionType'

const useNodeFactories = () => {
  const service = useContext(diagramServiceContext)

  const functionFactories = useMemo(
    () => service.nodeFactoryService.getAllFunctionNodeFactories(),
    [service.nodeFactoryService]
  )

  const constantInputFactory = useMemo(
    () => service.nodeFactoryService.getConstantInputNodeFactory(),
    [service.nodeFactoryService]
  )

  const streamInputFactory = useMemo(
    () => service.nodeFactoryService.getStreamInputNodeFactory(),
    [service.nodeFactoryService]
  )

  const outputFactory = useMemo(
    () => service.nodeFactoryService.getOutputNodeFactory(),
    [service.nodeFactoryService]
  )

  const getFunctionNodeFactoryByName = useCallback(
    (name: DiagramFunctionType) =>
      service.nodeFactoryService.getFunctionNodeFactoryByName(name),
    [service.nodeFactoryService]
  )

  return {
    functionFactories,
    constantInputFactory,
    streamInputFactory,
    outputFactory,
    getFunctionNodeFactoryByName
  }
}

export default useNodeFactories
