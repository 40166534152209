import { NoDiagramsAvailableProps } from './NoDiagramsAvailable.types'
import { Stack } from '@mui/material'
import {
  AccountTreeOutlined,
  AddOutlined,
  FileUploadOutlined
} from '@mui/icons-material'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import * as React from 'react'

const NoDiagramsAvailable = (props: NoDiagramsAvailableProps) => {
  const { onCreate, onImport } = props

  return (
    <Stack
      direction="column"
      alignItems="center"
      spacing={1}
      sx={{ margin: 4 }}
    >
      <AccountTreeOutlined
        color="disabled"
        sx={{ fontSize: 80, marginBottom: 2 }}
      />
      <Typography variant="body2" sx={{ textAlign: 'center' }}>
        It seems a bit empty here.
        <br />
        <Button endIcon={<AddOutlined />} size="small" onClick={onCreate}>
          Create
        </Button>
        or
        <Button component="label" endIcon={<FileUploadOutlined />} size="small">
          Import
          <input type="file" accept=".dfd" hidden onChange={onImport} />
        </Button>
        <br />
        your first diagram to get started.
      </Typography>
    </Stack>
  )
}

export default NoDiagramsAvailable
