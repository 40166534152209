import Node from './Node'
import Outputable from './Outputable'
import DiagramPropertyPropertyString from '../../data/models/DiagramPropertyPropertyString'
import Inputable from './Inputable'
import DiagramFunctionType from '../../data/models/DiagramFunctionType'
import handleUtils from '../utils/handleUtils'
import NodeIdService from './NodeIdService'
import functionNodeFactoryMap from '../factories/functionNodeFactoryMap'
import nodeUtils from '../utils/nodeUtils'
import NodeSelection from './NodeSelection'
import Subdiagram from './Subdiagram'
import Handle from './Handle'
import Input from './Input'
import DataType from './dataTypes/DataType'
import GenericHandle from './GenericHandle'
import Output from './Output'
import Connection from './Connection'
import ConnectionRepository from './ConnectionRepository'
import Variant from './Variant'
import NodeDuplicationOptions from './NodeDuplicationOptions'

class FunctionNode implements Node, Outputable, Inputable {
  public properties: Partial<Record<DiagramPropertyPropertyString, string>>

  public x: number

  public y: number

  private readonly id: string

  public readonly name: DiagramFunctionType

  protected readonly _inputHandles: Handle<Input>[]

  protected readonly _outputHandle: Handle<Output>

  private readonly _nodeSelection: NodeSelection

  public subdiagram: Subdiagram | undefined

  constructor(
    id: string,
    x: number,
    y: number,
    properties: Partial<Record<DiagramPropertyPropertyString, string>>,
    nodeSelection: NodeSelection,
    name: DiagramFunctionType,
    createInputHandles: (node: FunctionNode) => Handle<Input>[],
    createOutputHandle: (node: FunctionNode) => Handle<Output>
  ) {
    this.id = id
    this.x = x
    this.y = y
    this.properties = properties
    this._nodeSelection = nodeSelection
    this.name = name
    this._inputHandles = createInputHandles(this)
    this._outputHandle = createOutputHandle(this)
  }

  public get isGeneric() {
    return this.getGenericHandles().length > 0
  }

  getId(): string {
    return this.id
  }

  getOutputHandle(): Handle<Output> {
    return this._outputHandle
  }

  getInputHandles(): Handle<Input>[] {
    return this._inputHandles
  }

  getInputHandleById(id: string): Handle<Input> | undefined {
    return handleUtils.findHandleByIdIn(this._inputHandles, id)
  }

  getInputHandleByIndex(index: number): Handle<Input> | undefined {
    return handleUtils.findHandleByIndexIn(this.getInputHandles(), index)
  }

  duplicate(
    nodeIdService: NodeIdService,
    options?: NodeDuplicationOptions
  ): Promise<FunctionNode> {
    const node = functionNodeFactoryMap[this.name].create(
      nodeIdService.getNextNodeId(),
      this.x,
      this.y,
      { ...this.properties },
      options?.nodeSelection ?? this._nodeSelection,
      this.isGeneric ? this.getGenericHandles()[0].dataType : undefined
    )

    node.subdiagram = this.subdiagram

    nodeIdService.incrementId()

    return Promise.resolve(node)
  }

  findFirstInputHandle(
    where: (handle: Handle<Input>) => boolean
  ): Handle<Input> | undefined {
    return handleUtils.findFirstHandle(this._inputHandles, where)
  }

  public setDataTypeForGenericHandles(dataType: DataType): void {
    const genericHandles = this.getGenericHandles()
    if (this._hasDataType(genericHandles)) {
      return
    }

    this._setDataTypeForGenericHandles(genericHandles, dataType)
  }

  public unsetDataTypeForGenericHandles(): void {
    const genericHandles = this.getGenericHandles()

    this._setDataTypeForGenericHandles(genericHandles, undefined)
  }

  private _hasDataType(handles: Handle[]): boolean {
    for (const handle of handles) {
      if (handle.dataType) {
        return true
      }
    }

    return false
  }

  private _setDataTypeForGenericHandles(
    handles: GenericHandle[],
    dataType: DataType | undefined
  ): void {
    for (const handle of handles) {
      handle.dataType = dataType
    }
  }

  public getGenericHandles(): GenericHandle[] {
    const handles: GenericHandle[] = []
    for (const inputHandle of this._inputHandles) {
      if (inputHandle instanceof GenericHandle<Input>) {
        handles.push(inputHandle as GenericHandle)
      }
    }

    if (this._outputHandle instanceof GenericHandle<Output>) {
      handles.push(this._outputHandle as GenericHandle)
    }

    return handles
  }

  isCompatibleWith(node: Node): boolean {
    return (
      nodeUtils.isInputable(node) &&
      handleUtils.isHandleArrayCompatible(
        this.getInputHandles(),
        (node as Node & Inputable).getInputHandles()
      ) &&
      nodeUtils.isOutputable(node) &&
      this.getOutputHandle().isCompatibleWith(
        (node as Node & Outputable).getOutputHandle()
      )
    )
  }

  get selected(): boolean {
    return this._nodeSelection.has(this)
  }

  public onCreateConnection(connection: Connection) {
    const a = connection.source
    const b = connection.target

    const aFunctionNode = a.node as FunctionNode
    if (
      a instanceof GenericHandle<Variant> &&
      typeof aFunctionNode.setDataTypeForGenericHandles === 'function' &&
      b.dataType
    ) {
      aFunctionNode.setDataTypeForGenericHandles(b.dataType)
    }

    const bFunctionNode = b.node as FunctionNode
    if (
      b instanceof GenericHandle<Variant> &&
      typeof bFunctionNode.setDataTypeForGenericHandles === 'function' &&
      a.dataType
    ) {
      bFunctionNode.setDataTypeForGenericHandles(a.dataType)
    }
  }

  public onRemoveConnection(connectionRepository: ConnectionRepository) {
    const genericHandles = this.getGenericHandles()

    let genericHandlesHaveConnections = false
    for (const genericHandle of genericHandles) {
      if (connectionRepository.getConnectionForHandle(genericHandle)) {
        genericHandlesHaveConnections = true
      }
    }

    if (genericHandlesHaveConnections) {
      return
    }

    this.unsetDataTypeForGenericHandles()
  }

  get color(): string {
    return this.properties.Color ?? 'blue'
  }

  set color(color: string) {
    if (color === 'blue') {
      delete this.properties.Color
      return
    }
    this.properties.Color = color
  }
}

export default FunctionNode
