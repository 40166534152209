import { Outlet } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import * as React from 'react'

const DiagramsLayout = () => {
  return (
    <>
      <Helmet>
        <title>Dataflow Diagram Editor</title>
      </Helmet>
      <Outlet />
    </>
  )
}

export default DiagramsLayout
