import { NodeProps } from 'reactflow'
import NodeData from '../../features/presentation/NodeData'
import Inputable from '../../features/presentation/Inputable'
import Outputable from '../../features/presentation/Outputable'
import useContrastingColor from '../../features/presentation/hooks/useContrastingColor'
import { useMemo } from 'react'

const useMultipleInputsNode = (
  props: NodeProps<NodeData & Inputable & Outputable>
) => {
  const {
    data: { label, inputs, output, color },
    selected
  } = props

  const rectHeight = useMemo(
    () => (inputs.length > 3 ? inputs.length * 75 : 230),
    [inputs]
  )

  const viewBoxHeight = useMemo(() => rectHeight + 5, [rectHeight])

  const labelColor = useContrastingColor(color ?? '')

  return {
    selected,
    color,
    label,
    inputs,
    output,
    labelColor,
    viewBoxHeight,
    rectHeight
  }
}

export default useMultipleInputsNode
