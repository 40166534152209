import { NodeProps, NodeToolbar, Position } from 'reactflow'
import ConstantInputNodeData from '../../features/presentation/ConstantInputNodeData'
import React from 'react'
import DatatypeHandle from '../DatatypeHandle'
import Label from '../Label'
import './ConstantInputNode.styles.css'
import useConstantInputNode from './useConstantInputNode'

const ConstantInputNode = (props: NodeProps<ConstantInputNodeData>) => {
  const {
    output,
    label,
    labelColor,
    selected,
    color,
    id,
    displayValue,
    displayNodeToolbar
  } = useConstantInputNode(props)

  return (
    <div style={{ position: 'relative' }}>
      <svg
        style={{ display: 'block' }}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 175 175"
      >
        <rect
          className={selected ? 'selected-node-outline' : 'node-stroke'}
          fill={color ?? '#fff'}
          x="2.5"
          y="2.5"
          width="170"
          height="170"
          rx="10"
          ry="10"
        />
      </svg>
      <NodeToolbar
        nodeId={id}
        isVisible={displayNodeToolbar}
        position={Position.Top}
      >
        <span>{displayValue}</span>
      </NodeToolbar>
      <DatatypeHandle
        id={output.id}
        type="source"
        position={Position.Right}
        symbol={output.symbol}
      />
      <Label
        style={{
          width: '100%'
        }}
        color={labelColor}
      >
        {label}
      </Label>
    </div>
  )
}

export default ConstantInputNode
