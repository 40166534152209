import Transformer from '../../../transformation/Transformer'
import Node from '../../models/Node'
import DiagramContainerDataModel from '../../../data/models/DiagramContainerDataModel'
import ContainerNode from '../../models/ContainerNode'
import { parseNodeId } from '../../utils/idParsers'

const nodesToDiagramContainerDataModelTransformer: Transformer<
  Node[],
  DiagramContainerDataModel[]
> = {
  transform: (nodes) => {
    const containers: DiagramContainerDataModel[] = []

    for (const node of nodes) {
      if (node instanceof ContainerNode) {
        const childContainers =
          nodesToDiagramContainerDataModelTransformer.transform(
            node.getChildNodes(),
            undefined
          )

        const container: DiagramContainerDataModel = {
          nodeIds: node
            .getChildNodeIds(false)
            .map((nodeId) => parseNodeId(nodeId)),
          x: node.x,
          y: node.y,
          description: node.properties.Description ?? '[Container]',
          color: node.properties.Color
        }

        if (childContainers.length > 0) {
          container.containers = childContainers
        }

        containers.push(container)
      }
    }

    return containers
  }
}

export default nodesToDiagramContainerDataModelTransformer
