class ChangeHistory<T> {
  private readonly _past: T[]

  private _present: T | undefined

  private _future: T[]

  public maxChanges: number

  constructor(maxChanges: number, present: T | undefined = undefined) {
    this._past = []
    this._present = present
    this._future = []
    this.maxChanges = maxChanges
  }

  public setPresent(present: T): void {
    if (this._present) {
      this._past.push(this._present)
    }

    if (this._past.length >= this.maxChanges) {
      this._past.shift()
    }

    this._present = present

    this._future = []
  }

  public get present(): T | undefined {
    return this._present
  }

  public get hasUndo(): boolean {
    return this._past.length > 0
  }

  public get hasRedo(): boolean {
    return this._future.length > 0
  }

  public undo(): void {
    const newPresent = this._past.pop()

    if (this._present) {
      this._future.push(this._present)
    }

    if (newPresent) {
      this._present = newPresent
    }
  }

  public redo(): void {
    const newPresent = this._future.pop()

    if (this._present) {
      this._past.push(this._present)
    }

    if (newPresent) {
      this._present = newPresent
    }
  }
}

export default ChangeHistory
