import { useCallback, useContext, useMemo } from 'react'
import diagramServiceContext from '../context/diagramServiceContext'
import DiagramVariableType from '../../data/models/DiagramVariableType'

const useDataTypes = () => {
  const service = useContext(diagramServiceContext)

  const dataTypes = useMemo(
    () => service.dataTypeService.getAllDataTypes(),
    [service.dataTypeService]
  )

  const getDataTypeByName = useCallback(
    (name: DiagramVariableType) =>
      service.dataTypeService.getDataTypeByName(name),
    [service.dataTypeService]
  )

  return {
    dataTypes,
    getDataTypeByName
  }
}

export default useDataTypes
