import ValueFactory from './ValueFactory'
import SequenceValue from '../../models/values/SequenceValue'

const sequenceValueFactory: ValueFactory<SequenceValue> = {
  tryCreateWithStringRepresentation(
    representation: string
  ): Promise<SequenceValue> {
    if (
      representation[0] !== '[' ||
      representation[representation.length - 1] !== ']'
    ) {
      return Promise.reject(
        'Sequence representation was not surrounded by square brackets'
      )
    }

    const regex = new RegExp(/\([0-9]+, [0-9]+\)/g)

    const result = representation.match(regex)

    if (!result) {
      return Promise.reject(
        'representation item does not conform to the correct format'
      )
    }

    const values = Array.from(result.values())

    return Promise.resolve(new SequenceValue(values))
  }
}

export default sequenceValueFactory
