import DiagramDataStore from '../../models/DiagramDataStore'

const loadDataStoreOrDefault = (def: DiagramDataStore = {}): Promise<unknown> =>
  new Promise((resolve) => {
    const item = window.localStorage.getItem('diagrams')
    if (item === null) {
      resolve(def)
    }

    try {
      const data = JSON.parse(item as string) as unknown
      resolve(data)
    } catch {
      resolve(def)
    }
  })

export default loadDataStoreOrDefault
