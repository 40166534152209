import Diagram from '../domain/models/Diagram'
import short from 'short-uuid'
import ConnectionRepository from '../domain/models/ConnectionRepository'
import NodeIdService from '../domain/models/NodeIdService'
import NodeSelection from '../domain/models/NodeSelection'
import NodeRepository from '../domain/models/NodeRepository'
import SubdiagramRepository from '../domain/models/SubdiagramRepository'
import SubdiagramIdService from '../domain/models/SubdiagramIdService'

const diagramFactory = {
  create: (
    name: string,
    manualLines: string[] = [],
    nodeRepository: NodeRepository = new NodeRepository([]),
    connectionRepository: ConnectionRepository = new ConnectionRepository([]),
    nodeIdService: NodeIdService = new NodeIdService(),
    nodeSelection: NodeSelection = new NodeSelection(),
    subdiagramRepository: SubdiagramRepository = new SubdiagramRepository([]),
    subdiagramIdService: SubdiagramIdService = new SubdiagramIdService()
  ): Diagram =>
    new Diagram(
      short.generate(),
      name,
      nodeRepository,
      connectionRepository,
      manualLines,
      nodeIdService,
      nodeSelection,
      subdiagramRepository,
      subdiagramIdService
    )
}

export default diagramFactory
