import { SubdiagramsDialogProps } from './SubdiagramsDialog.types'
import useSubdiagramsDialog from './useSubdiagramsDialog'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import { IconButton } from '@mui/material'
import { AddOutlined, Close } from '@mui/icons-material'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import * as React from 'react'
import NoSubdiagramsAvailable from '../NoSubdiagramsAvailable'
import SubdiagramItem from '../SubdiagramItem'

const SubdiagramsDialog = (props: SubdiagramsDialogProps) => {
  const {
    isOpen,
    onClose,
    subdiagrams,
    onCreateSubdiagram,
    onDeleteSubdiagram,
    onUpdateDescriptionOfSubdiagram
  } = useSubdiagramsDialog(props)

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Subdiagrams</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8
        }}
      >
        <Close />
      </IconButton>
      <DialogContent sx={{ width: 500, maxHeight: 500, minHeight: 250 }}>
        {subdiagrams.length > 0 ? (
          subdiagrams.map((subdiagram) => (
            <SubdiagramItem
              key={subdiagram.id}
              id={subdiagram.id}
              description={subdiagram.description}
              onDelete={onDeleteSubdiagram}
              onChangeDescription={onUpdateDescriptionOfSubdiagram}
            />
          ))
        ) : (
          <NoSubdiagramsAvailable onCreate={onCreateSubdiagram} />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          endIcon={<AddOutlined />}
          variant="contained"
          onClick={onCreateSubdiagram}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SubdiagramsDialog
