import Transformer from '../../../transformation/Transformer'
import Node from '../../models/Node'
import DiagramNodeDataModel from '../../../data/models/DiagramNodeDataModel'
import { parseNodeId } from '../../utils/idParsers'
import ConstantInputNode from '../../models/ConstantInputNode'
import StreamInputNode from '../../models/StreamInputNode'
import FunctionNode from '../../models/FunctionNode'
import OutputNode from '../../models/OutputNode'

const nodeToDiagramNodeDataModelTransformer: Transformer<
  Node,
  DiagramNodeDataModel
> = {
  transform: (node) => {
    switch (true) {
      case node instanceof ConstantInputNode:
        return {
          id: parseNodeId(node.getId()),
          classString: 'Constant',
          typeString: (node as ConstantInputNode).dataType.name
        }
      case node instanceof StreamInputNode:
        return {
          id: parseNodeId(node.getId()),
          classString: 'Input stream',
          typeString: (node as StreamInputNode).dataType.name
        }
      case node instanceof FunctionNode:
        const functionNode = node as FunctionNode

        const model: DiagramNodeDataModel = {
          id: parseNodeId(node.getId()),
          classString: 'Function',
          typeString: functionNode.name
        }

        if (functionNode.isGeneric) {
          model.outputType = functionNode.getGenericHandles()[0].dataType?.name
        }

        return model
      case node instanceof OutputNode:
        return {
          id: parseNodeId(node.getId()),
          classString: 'Variable output',
          typeString: (node as OutputNode).dataType.name
        }
      default:
        throw new Error('unknown node class')
    }
  }
}

export default nodeToDiagramNodeDataModelTransformer
