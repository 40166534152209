import { Stack } from '@mui/material'
import React from 'react'
import DescriptionSetting from '../DescriptionSetting'
import ColorSetting from '../ColorSetting'
import { StreamInputNodeSettingsProps } from './StreamInputNodeSettings.type'
import ForwardingSetting from '../ForwardingSetting'
import LoopSetting from '../LoopSetting'

const StreamInputNodeSettings = (props: StreamInputNodeSettingsProps) => {
  const { node, onChangeColor, onChangeDescription, onChangeForwarding } = props

  return (
    <Stack spacing={2}>
      <LoopSetting looping={node.looping} />
      <DescriptionSetting
        description={node?.properties?.Description}
        onDescriptionChange={onChangeDescription}
      />
      <ColorSetting color={node.color} onColorChange={onChangeColor} />
      <ForwardingSetting
        forwarding={node.forwarding}
        onForwardingChange={onChangeForwarding}
        disabled={node.looping}
      />
    </Stack>
  )
}

export default StreamInputNodeSettings
