import Node from './Node'

class NodeSelection {
  private _selectedNodes: Record<string, Node>

  constructor() {
    this._selectedNodes = {}
  }

  public has(node: Node): boolean {
    return !!this._selectedNodes[node.getId()]
  }

  public set(nodes: Node[]): void {
    this._selectedNodes = nodes.reduce<Record<string, Node>>(
      (previous, current) => {
        previous[current.getId()] = current
        return previous
      },
      {}
    )
  }

  public allIds(): string[] {
    return Object.values(this._selectedNodes).map((selectedNode) =>
      selectedNode.getId()
    )
  }

  public clear(): void {
    this._selectedNodes = {}
  }
}

export default NodeSelection
