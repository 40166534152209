import { ValueSettingInputProps } from '../ValueSettingInput/ValueSettingInput.types'
import { TextField } from '@mui/material'
import useIntegerValueSettingInput from './useIntegerValueSettingInput'

const InputValueSettingInput = (props: ValueSettingInputProps) => {
  const { integer, handleIntegerChange } = useIntegerValueSettingInput(props)

  return (
    <TextField
      type="number"
      fullWidth
      value={integer}
      label="Integer"
      onChange={handleIntegerChange}
    />
  )
}

export default InputValueSettingInput
