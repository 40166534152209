import { ForwardingSettingProps } from './ForwardingSetting.types'
import { Alert, FormControlLabel, Switch } from '@mui/material'
import React from 'react'
import useForwardingSetting from './useForwardingSetting'

const ForwardingSetting = (props: ForwardingSettingProps) => {
  const { forwarding, handleForwardingChange, disabled } =
    useForwardingSetting(props)

  return (
    <>
      {disabled && (
        <Alert severity="info">Forwarding is disabled in a local loop</Alert>
      )}
      <FormControlLabel
        control={
          <>
            <Switch
              checked={forwarding}
              onChange={handleForwardingChange}
              disabled={disabled}
            />
          </>
        }
        label="Forwarding"
      />
    </>
  )
}

export default ForwardingSetting
