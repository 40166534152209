import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.styles.css'
import 'react-toastify/dist/ReactToastify.css'
import reportWebVitals from './reportWebVitals'
import { RouterProvider } from 'react-router-dom'
import AppRouter from './components/AppRouter'
import { HelmetProvider } from 'react-helmet-async'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import DiagramService from './features/application/DiagramService'
import DiagramRepository from './features/application/DiagramRepository'
import DiagramServiceContext from './features/application/context/diagramServiceContext'
import { ReactFlowProvider } from 'reactflow'
import DataTypeService from './features/application/DataTypeService'
import NodeFactoryService from './features/application/NodeFactoryService'
import { ToastContainer } from 'react-toastify'

const diagramService = new DiagramService(
  new DiagramRepository(),
  new NodeFactoryService(),
  new DataTypeService()
)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <ToastContainer
      autoClose={5000}
      position="bottom-left"
      hideProgressBar
      icon={false}
      draggable={false}
      closeOnClick={false}
    />
    <HelmetProvider>
      <ReactFlowProvider>
        <DiagramServiceContext.Provider value={diagramService}>
          <RouterProvider router={AppRouter} />
        </DiagramServiceContext.Provider>
      </ReactFlowProvider>
    </HelmetProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
