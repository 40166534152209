import Transformer from '../../../transformation/Transformer'
import DiagramPositionDataModel from '../../models/DiagramPositionDataModel'
import CompositedNodePositionPartial from './types/CompositedNodePositionPartial'

const diagramPositionDataModelsToCompositeNodePositionsPartialMapTransformer: Transformer<
  DiagramPositionDataModel[],
  Record<number, CompositedNodePositionPartial>
> = {
  transform: (positions) => {
    return positions.reduce<Record<number, CompositedNodePositionPartial>>(
      (map, position) => {
        map[position.id] = {
          id: position.id,
          x: position.x,
          y: position.y
        }
        return map
      },
      {}
    )
  }
}

export default diagramPositionDataModelsToCompositeNodePositionsPartialMapTransformer
