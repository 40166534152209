import Node from './Node'
import ContainerNode from './ContainerNode'

class NodeRepository {
  private _nodes: Node[]

  constructor(nodes: Node[]) {
    this._nodes = nodes
  }

  public get all(): Node[] {
    return this._nodes
  }

  public add(...node: Node[]): void {
    this._nodes.push(...node)
  }

  public deleteByIds(nodeIds: string[]): Node[] {
    const nodeIdMap = this._createNodeIdMap(nodeIds)

    const remainingNodes = this._nodes.filter(
      (node) => !nodeIdMap[node.getId()]
    )

    const nodesToBeDeleted = this._nodes.filter(
      (node) => nodeIdMap[node.getId()]
    )

    this._nodes = remainingNodes

    return nodesToBeDeleted
  }

  public clear(): void {
    this._nodes = []
  }

  public set(nodes: Node[]): void {
    this._nodes = nodes
  }

  public getByIds(nodeIds: string[]): Node[] {
    const nodeIdMap = this._createNodeIdMap(nodeIds)

    const found: Node[] = []
    for (const node of this._nodes) {
      if (nodeIdMap[node.getId()]) {
        found.push(node)
      }
    }

    return found
  }

  public getById(nodeId: string, recursive = true): Node | undefined {
    return this._getNodeByIdIn(nodeId, this._nodes, recursive)
  }

  public filter(
    predicate: (node: Node, index: number, array: Node[]) => boolean
  ): Node[] {
    return this._nodes.filter(predicate)
  }

  private _getNodeByIdIn(
    nodeId: string,
    nodes: Node[],
    recursive = true
  ): Node | undefined {
    for (const node of nodes) {
      if (node.getId() === nodeId) {
        return node
      }

      if (!recursive) {
        return undefined
      }

      if (node instanceof ContainerNode) {
        const found = this._getNodeByIdIn(nodeId, node.getChildNodes())
        if (found) {
          return found
        }
      }
    }
    return undefined
  }

  private _createNodeIdMap(nodeIds: string[]): Record<string, string> {
    return nodeIds.reduce<Record<string, string>>((previous, current) => {
      previous[current] = current
      return previous
    }, {})
  }
}

export default NodeRepository
