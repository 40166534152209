import Node from './Node'

class Subdiagram {
  public readonly id: number

  public description: string

  constructor(id: number, description: string) {
    this.id = id
    this.description = description
  }

  public hasNode(node: Node): boolean {
    return node.subdiagram === this
  }

  public addNode(node: Node): void {
    if (node.subdiagram) {
      node.subdiagram.removeNode(node)
    }

    node.subdiagram = this
  }

  public removeNode(node: Node): void {
    if (this.hasNode(node)) {
      node.subdiagram = undefined
    }
  }
}

export default Subdiagram
