import Inputable from './Inputable'
import Outputable from './Outputable'
import Node from './Node'
import Descendable from './Descendable'
import DiagramPropertyPropertyString from '../../data/models/DiagramPropertyPropertyString'
import { formatNodeIdFromNode } from '../utils/idFormatters'
import handleUtils from '../utils/handleUtils'
import ConnectionRepository from './ConnectionRepository'
import NodeIdService from './NodeIdService'
import nodeUtils from '../utils/nodeUtils'
import NodeSelection from './NodeSelection'
import Subdiagram from './Subdiagram'
import Handle from './Handle'
import Input from './Input'
import Output from './Output'
import GenericHandle from './GenericHandle'
import AnyConstraint from './AnyConstraint'
import NodeDuplicationOptions from './NodeDuplicationOptions'

class ContainerNode implements Node, Inputable, Outputable, Descendable {
  public properties: Partial<Record<DiagramPropertyPropertyString, string>>

  public x: number

  public y: number

  private readonly _childNodes: Node[]

  private readonly _connectionRepository: ConnectionRepository

  private readonly _nodeSelection: NodeSelection

  public get subdiagram(): Subdiagram | undefined {
    return this.hasChildNodes() ? this._childNodes[0].subdiagram : undefined
  }

  public set subdiagram(subdiagram: Subdiagram | undefined) {
    for (const childNode of this._childNodes) {
      childNode.subdiagram = subdiagram
    }
  }

  constructor(
    x: number,
    y: number,
    properties: Partial<Record<DiagramPropertyPropertyString, string>>,
    childNodes: Node[],
    connectionRepository: ConnectionRepository,
    nodeSelection: NodeSelection
  ) {
    this.x = x
    this.y = y
    this.properties = properties
    this._childNodes = childNodes
    this._connectionRepository = connectionRepository
    this._nodeSelection = nodeSelection
  }

  public getId(): string {
    return formatNodeIdFromNode(this)
  }

  public getInputHandles(): Handle<Input>[] {
    const childHandles: Handle<Input>[] = []

    for (const child of this._childNodes) {
      if (
        typeof (child as unknown as Inputable).getInputHandles !== 'function'
      ) {
        continue
      }

      const handles = (child as unknown as Inputable).getInputHandles()

      if (handles) {
        childHandles.push(...handles)
      }
    }

    const childNodeIds = this.getChildNodeIds()
    const childNodeIdMap: Record<string, string> = {}

    for (const childNodeId of childNodeIds) {
      childNodeIdMap[childNodeId] = childNodeId
    }

    const filtered = []

    for (const childHandle of childHandles) {
      const connection =
        this._connectionRepository.getConnectionForHandle(childHandle)

      if (!connection || !childNodeIdMap[connection.source.node.getId()]) {
        filtered.push(childHandle)
      }
    }

    return filtered
  }

  public getOutputHandle(): Handle<Output> {
    const childHandles: Handle<Output>[] = []

    for (const child of this._childNodes) {
      if (
        typeof (child as unknown as Outputable).getOutputHandle !== 'function'
      ) {
        continue
      }

      const handle = (child as unknown as Outputable).getOutputHandle()

      if (handle) {
        childHandles.push(handle)
      }
    }

    const childNodeIds = this.getChildNodeIds()
    const childNodeIdMap: Record<string, string> = {}

    for (const childNodeId of childNodeIds) {
      childNodeIdMap[childNodeId] = childNodeId
    }

    const filtered = []

    for (const childHandle of childHandles) {
      const connection =
        this._connectionRepository.getConnectionForHandle(childHandle)

      if (!connection || !childNodeIdMap[connection.target.node.getId()]) {
        filtered.push(childHandle)
      }
    }

    return filtered.length > 0
      ? filtered[0]
      : new GenericHandle(this, new Output(), new AnyConstraint())
  }

  public getChildNodes(): Node[] {
    return this._childNodes
  }

  public hasChildNodes(): boolean {
    return this._childNodes.length > 0
  }

  getInputHandleById(id: string): Handle<Input> | undefined {
    return handleUtils.findHandleByIdIn(this.getInputHandles(), id)
  }

  getChildNodeIds(recursive = true): string[] {
    const ids: string[] = []
    for (const childNode of this._childNodes) {
      if (childNode instanceof ContainerNode) {
        if (recursive) {
          ids.push(...childNode.getChildNodeIds())
        }
        continue
      }
      ids.push(childNode.getId())
    }
    return ids
  }

  async duplicate(
    nodeIdService: NodeIdService,
    options?: NodeDuplicationOptions
  ): Promise<ContainerNode> {
    const duplicatedChildNodes: Node[] = []

    const nodeIdConversionMap: Record<string, string> = {}

    const connectionRepository =
      options?.connectionRepository ?? this._connectionRepository

    for (const childNode of this._childNodes) {
      const duplicatedNode = await childNode.duplicate(nodeIdService, options)

      nodeIdConversionMap[childNode.getId()] = duplicatedNode.getId()

      duplicatedChildNodes.push(duplicatedNode)
    }

    const duplicatedConnections =
      await this._connectionRepository.duplicateConnectionsForNodes(
        this._childNodes,
        duplicatedChildNodes,
        nodeIdConversionMap
      )

    connectionRepository.addConnections(duplicatedConnections)

    return new ContainerNode(
      this.x,
      this.y,
      { ...this.properties },
      duplicatedChildNodes,
      connectionRepository,
      options?.nodeSelection ?? this._nodeSelection
    )
  }

  getInputHandleByIndex(index: number): Handle<Input> | undefined {
    return handleUtils.findHandleByIndexIn(this.getInputHandles(), index)
  }

  findFirstInputHandle(
    where: (handle: Handle<Input>) => boolean
  ): Handle<Input> | undefined {
    return handleUtils.findFirstHandle(this.getInputHandles(), where)
  }

  isCompatibleWith(node: Node): boolean {
    return (
      nodeUtils.isInputable(node) &&
      handleUtils.isHandleArrayCompatible(
        this.getInputHandles(),
        (node as Node & Inputable).getInputHandles()
      ) &&
      nodeUtils.isOutputable(node) &&
      this.getOutputHandle().isCompatibleWith(
        (node as Node & Outputable).getOutputHandle()
      )
    )
  }

  get selected(): boolean {
    return this._nodeSelection.has(this)
  }

  public onCreateConnection() {}

  public onRemoveConnection() {}

  get color(): string {
    return this.properties.Color ?? 'green'
  }

  set color(color: string) {
    if (color === 'green') {
      delete this.properties.Color
      return
    }
    this.properties.Color = color
  }
}

export default ContainerNode
