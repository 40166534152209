class SubdiagramIdService {
  private _lastSubdiagramId: number

  constructor(lastSubdiagramId = 0) {
    this._lastSubdiagramId = lastSubdiagramId
  }

  public getNextSubdiagramId(): number {
    return this._lastSubdiagramId + 1
  }

  public incrementId(): void {
    this._lastSubdiagramId += 1
  }
}

export default SubdiagramIdService
