import Transformer from '../../../transformation/Transformer'
import CompositedNode from './types/CompositedNode'
import { formatNodeId } from '../../../domain/utils/idFormatters'
import ConstantInputNode from '../../../domain/models/ConstantInputNode'
import DiagramVariableType from '../../models/DiagramVariableType'
import StreamInputNode from '../../../domain/models/StreamInputNode'
import functionNodeFactory from '../../../domain/factories/functionNodeFactory'
import DiagramFunctionType from '../../models/DiagramFunctionType'
import OutputNode from '../../../domain/models/OutputNode'
import Node from '../../../domain/models/Node'
import NodeSelection from '../../../domain/models/NodeSelection'
import NodeRepository from '../../../domain/models/NodeRepository'
import diagramVariableTypeToDataTypeTransformer from './diagramVariableTypeToDataTypeTransformer'

const compositeNodesToNodesTransformer: Transformer<
  CompositedNode[],
  Node[],
  { nodeSelection: NodeSelection; nodeRepository: NodeRepository }
> = {
  transform: (compositeNodes, { nodeSelection, nodeRepository }) => {
    const nodeMap: Record<string, Node> = {}

    for (const node of compositeNodes) {
      switch (node.classString) {
        case 'Constant':
          nodeMap[formatNodeId(node.id)] = new ConstantInputNode(
            formatNodeId(node.id),
            node.x,
            node.y,
            diagramVariableTypeToDataTypeTransformer.transform(
              node.typeString as DiagramVariableType,
              undefined
            ),
            node.properties,
            nodeSelection
          )
          continue
        case 'Input stream':
          nodeMap[formatNodeId(node.id)] = new StreamInputNode(
            formatNodeId(node.id),
            node.x,
            node.y,
            diagramVariableTypeToDataTypeTransformer.transform(
              node.typeString as DiagramVariableType,
              undefined
            ),
            node.properties,
            nodeSelection,
            nodeRepository
          )
          continue
        case 'Function':
          nodeMap[formatNodeId(node.id)] = functionNodeFactory.create(
            formatNodeId(node.id),
            node.x,
            node.y,
            diagramVariableTypeToDataTypeTransformer.transform(
              node.outputType as DiagramVariableType,
              undefined
            ),
            node.properties,
            nodeSelection,
            node.typeString as DiagramFunctionType
          )
          continue
        case 'Variable output':
          nodeMap[formatNodeId(node.id)] = new OutputNode(
            formatNodeId(node.id),
            node.x,
            node.y,
            diagramVariableTypeToDataTypeTransformer.transform(
              node.typeString as DiagramVariableType,
              undefined
            ),
            node.properties,
            nodeSelection,
            nodeRepository
          )
      }
    }

    return Object.values(nodeMap)
  }
}

export default compositeNodesToNodesTransformer
