import Transformer from '../../../transformation/Transformer'
import { Node as ReactFlowNode } from 'reactflow'
import reactFlowNodeFactory from './factories/reactFlowNodeFactory'
import StreamInputNode from '../../models/StreamInputNode'
import handleToReactFlowHandleTransformer from './handleToReactFlowHandleTransformer'
import StreamInputNodeData from '../../../presentation/StreamInputNodeData'

const streamInputNodeToReactFlowStreamInputNodeTransformer: Transformer<
  StreamInputNode,
  ReactFlowNode<StreamInputNodeData>
> = {
  transform: (node) =>
    reactFlowNodeFactory.create<StreamInputNodeData>(
      node,
      {
        forwarding: node.forwarding,
        looping: node.looping,
        color: node.color,
        label: node.properties.Description ?? node.dataType.name,
        output: handleToReactFlowHandleTransformer.transform(
          node.getOutputHandle(),
          undefined
        )
      },
      {
        type: 'shapeInput'
      }
    )
}

export default streamInputNodeToReactFlowStreamInputNodeTransformer
