import ValueFactory from './ValueFactory'
import ArrayValue from '../../models/values/ArrayValue'
import Value from '../../models/values/Value'

const arrayValueFactoryFactory = {
  create: <TItemValue extends Value>(
    valueFactory: ValueFactory<TItemValue>
  ): ValueFactory<ArrayValue<TItemValue>> => ({
    tryCreateWithStringRepresentation: async (representation) => {
      if (
        representation[0] !== '[' ||
        representation[representation.length - 1] !== ']'
      ) {
        throw new Error(
          'Array representation was not surrounded by square brackets'
        )
      }

      const regex = new RegExp(
        /(([0-9.a-z])|(\([0-9., ]+\))|(\[[0-9.(), ]+]))+/g
      )

      const valueRepresentations = representation.slice(1, -1).match(regex)

      const values: TItemValue[] = []
      for (const valueRepresentation of valueRepresentations ?? []) {
        const value = await valueFactory.tryCreateWithStringRepresentation(
          valueRepresentation
        )
        values.push(value)
      }

      return new ArrayValue<TItemValue>(values)
    }
  })
}

export default arrayValueFactoryFactory
