import { z } from 'zod'
import Diagram from '../models/Diagram'
import diagramDataModelValidator from './diagramDataModelValidator'

const diagramValidator: z.ZodType<Diagram> = z.object({
  uuid: z.string(),
  name: z.string(),
  model: diagramDataModelValidator
})

export default diagramValidator
