const colorNameToHexOrDefault = (
  colorName: string,
  defaultColor: string
): string => {
  switch (colorName) {
    case 'blue':
      return '#0000FF'
    case 'red':
      return '#FF0000'
    case 'black':
      return '#000000'
    case 'brown':
      return '#A52A2A'
    case 'cyan':
      return '#00FFFF'
    case 'gray':
      return '#808080'
    case 'green':
      return '#008000'
    case 'magenta':
      return '#FF00FF'
    case 'orange':
      return '#FFA500'
    case 'purple':
      return '#800080'
    case 'yellow':
      return '#FFFF00'
    default:
      return defaultColor
  }
}

export default colorNameToHexOrDefault
