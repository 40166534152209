import Transformer from '../../../transformation/Transformer'
import Connection from '../../models/Connection'
import { Edge } from 'reactflow'

const connectionToReactFlowEdgeTransformer: Transformer<Connection, Edge> = {
  transform: (connection) => ({
    id: connection.id,
    source: connection.sourceNode.getId(),
    target: connection.targetNode.getId(),
    targetHandle: connection.target.id,
    type: 'straight'
  })
}

export default connectionToReactFlowEdgeTransformer
