import { useMemo } from 'react'
import getContrastingColor from '../utils/getContrastingColor'
import colorStringToHex from '../utils/colorStringToHex'

const useContrastingColor = (
  colorName: string,
  options?: {
    defaultColor?: string
    amplifyTo?: { lightColor: string; darkColor: string }
  }
) => {
  const {
    defaultColor = '#ffffff',
    amplifyTo = {
      lightColor: '#ffffff',
      darkColor: '#000000'
    }
  } = options || {}

  return useMemo(
    () =>
      getContrastingColor(
        colorStringToHex(colorName ?? '', defaultColor),
        amplifyTo
      ),
    [amplifyTo, colorName, defaultColor]
  )
}

export default useContrastingColor
