import { ValueSettingInputProps } from '../ValueSettingInput/ValueSettingInput.types'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import integerValueFactory from '../../features/domain/factories/values/integerValueFactory'
import IntegerValue from '../../features/domain/models/values/IntegerValue'
import useNumberParsers from '../../shared/useNumberParsers'

const useIntegerValueSettingInput = (props: ValueSettingInputProps) => {
  const { value, onValueChange } = props

  const [integer, setInteger] = useState(new IntegerValue())

  const { parseIntOrDefault } = useNumberParsers()

  useEffect(() => {
    integerValueFactory
      .tryCreateWithStringRepresentation(value)
      .then(setInteger)
      .catch(console.log)
  }, [value])

  const handleIntegerChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newInteger = new IntegerValue(parseIntOrDefault(event.target.value))
      setInteger(newInteger)
      onValueChange(newInteger.toString())
    },
    [onValueChange, parseIntOrDefault]
  )

  return {
    integer,
    handleIntegerChange
  }
}

export default useIntegerValueSettingInput
