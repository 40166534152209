import Transformer from '../../../transformation/Transformer'
import DiagramNodeDataModel from '../../models/DiagramNodeDataModel'

const diagramNodeDataModelToHighestNodeIdTransformer: Transformer<
  DiagramNodeDataModel[],
  number
> = {
  transform: (nodes) => {
    return nodes.reduce<number>(
      (highest, node) => (node.id > highest ? node.id : highest),
      0
    )
  }
}

export default diagramNodeDataModelToHighestNodeIdTransformer
