import React from 'react'
import { ColorSettingProps } from './ColorSetting.types'
import useColorSetting from './useColorSetting'
import RestrictiveColorInput from '../RestrictiveColorInput'

const ColorSetting = (props: ColorSettingProps) => {
  const { handleColorChange, selectedColorOption, colorOptions } =
    useColorSetting(props)

  return (
    <RestrictiveColorInput
      colorOptions={colorOptions}
      selectedColorOption={selectedColorOption}
      onChange={handleColorChange}
    />
  )
}

export default ColorSetting
