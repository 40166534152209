import Transformer from '../../../transformation/Transformer'
import { Node as ReactFlowNode } from 'reactflow'
import reactFlowNodeFactory from './factories/reactFlowNodeFactory'
import OutputNode from '../../models/OutputNode'
import OutputNodeData from '../../../presentation/OutputNodeData'
import mapperTransformerFactory from '../../../transformation/factories/mapperTransformerFactory'
import handleToReactFlowHandleTransformer from './handleToReactFlowHandleTransformer'

const outputNodeToReactFlowOutputNodeTransformer: Transformer<
  OutputNode,
  ReactFlowNode<OutputNodeData>
> = {
  transform: (node) =>
    reactFlowNodeFactory.create<OutputNodeData>(
      node,
      {
        hasUniqueDescription: node.hasUniqueDescription,
        looping: node.looping,
        hidden: node.hidden,
        color: node.color,
        label: node.properties.Description ?? node.dataType.name,
        inputs: mapperTransformerFactory
          .create(handleToReactFlowHandleTransformer)
          .transform(node.getInputHandles(), undefined)
      },
      {
        type: 'shapeOutput'
      }
    )
}

export default outputNodeToReactFlowOutputNodeTransformer
