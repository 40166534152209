import { SubdiagramItemProps } from './SubdiagramItem.types'
import { ChangeEvent, useCallback } from 'react'

const useSubdiagramItem = (props: SubdiagramItemProps) => {
  const { id, onChangeDescription, onDelete, description } = props

  const handleChangeDescription = useCallback(
    (event: ChangeEvent<HTMLInputElement>) =>
      onChangeDescription(id, event.target.value),
    [id, onChangeDescription]
  )

  const handleDelete = useCallback(() => onDelete(id), [id, onDelete])

  return {
    description,
    handleChangeDescription,
    handleDelete
  }
}

export default useSubdiagramItem
