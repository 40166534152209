import NodeFactory from './NodeFactory'
import DiagramPropertyPropertyString from '../../data/models/DiagramPropertyPropertyString'
import OutputNode from '../models/OutputNode'
import NodeSelection from '../models/NodeSelection'
import NodeRepository from '../models/NodeRepository'
import DataType from '../models/dataTypes/DataType'

const outputNodeFactory: NodeFactory = {
  create: (
    id: string,
    x: number,
    y: number,
    dataType: DataType,
    properties: Partial<Record<DiagramPropertyPropertyString, string>>,
    nodeSelection: NodeSelection,
    nodeRepository: NodeRepository
  ) =>
    new OutputNode(
      id,
      x,
      y,
      dataType,
      properties,
      nodeSelection,
      nodeRepository
    )
}

export default outputNodeFactory
