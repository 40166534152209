import DiagramPropertyPropertyString from '../../data/models/DiagramPropertyPropertyString'
import FunctionNode from '../models/FunctionNode'
import FunctionTypeNodeFactory from './FunctionTypeNodeFactory'
import NodeSelection from '../models/NodeSelection'
import DataType from '../models/dataTypes/DataType'

const FunctionNodeTypeFactoryFactory = {
  create: (
    create: (
      id: string,
      x: number,
      y: number,
      properties: Partial<Record<DiagramPropertyPropertyString, string>>,
      nodeSelection: NodeSelection,
      dataType?: DataType
    ) => FunctionNode
  ): FunctionTypeNodeFactory => ({ create })
}

export default FunctionNodeTypeFactoryFactory
