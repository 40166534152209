import { NodeProps } from 'reactflow'
import useContrastingColor from '../../features/presentation/hooks/useContrastingColor'
import ConstantInputNodeData from '../../features/presentation/ConstantInputNodeData'
import { useMemo } from 'react'
import Array from '../../features/domain/models/dataTypes/Array'

const useConstantInputNode = (props: NodeProps<ConstantInputNodeData>) => {
  const {
    id,
    data: { label, output, color, value, displayNodeToolbar = true, dataType },
    selected
  } = props

  const labelColor = useContrastingColor(color ?? '')

  const displayValue = useMemo(
    () => (dataType instanceof Array ? dataType.symbol : value),
    [dataType, value]
  )

  return {
    id,
    color,
    label,
    labelColor,
    output,
    selected,
    displayValue,
    displayNodeToolbar
  }
}

export default useConstantInputNode
