import DiagramDomainModel from '../../models/Diagram'
import DiagramDataModel from '../../../data/models/Diagram'
import Transformer from '../../../transformation/Transformer'
import mapperTransformerFactory from '../../../transformation/factories/mapperTransformerFactory'
import connectionToDiagramConnectionDataModelTransformer from './connectionToDiagramConnectionDataModelTransformer'
import containerUtils from '../../utils/containerUtils'
import nodeToDiagramPositionDataModelTransformer from './nodeToDiagramPositionDataModelTransformer'
import nodeToDiagramNodeDataModelTransformer from './nodeToDiagramNodeDataModelTransformer'
import nodesToDiagramPropertiesDataModelTransformer from './nodesToDiagramPropertiesDataModelTransformer'
import nodesToDiagramContainerDataModelTransformer from './nodesToDiagramContainerDataModelTransformer'
import nodesToDiagramSubdiagramsDataModelTransformer from './nodesToDiagramSubdiagramsDataModelTransformer'

const diagramDomainModelToDiagramDataModelTransformer: Transformer<
  DiagramDomainModel,
  DiagramDataModel
> = {
  transform: (diagram): DiagramDataModel => {
    const flattenedNodes = containerUtils.flattenNodes(diagram.getAllNodes())

    return {
      uuid: diagram.uuid,
      name: diagram.name,
      model: {
        manualLines:
          diagram.manualLines.length > 0 ? diagram.manualLines : undefined,
        connections: mapperTransformerFactory
          .create(connectionToDiagramConnectionDataModelTransformer)
          .transform(diagram.getAllConnections(), undefined)
          .sort((a, b) => a.outputNodeId - b.outputNodeId),
        nodes: mapperTransformerFactory
          .create(nodeToDiagramNodeDataModelTransformer)
          .transform(flattenedNodes, undefined)
          .sort((a, b) => a.id - b.id),
        containers: nodesToDiagramContainerDataModelTransformer.transform(
          diagram.getAllNodes(),
          undefined
        ),
        properties: nodesToDiagramPropertiesDataModelTransformer
          .transform(flattenedNodes, undefined)
          .sort((a, b) => a.id - b.id),
        positions: mapperTransformerFactory
          .create(nodeToDiagramPositionDataModelTransformer)
          .transform(flattenedNodes, undefined)
          .sort((a, b) => a.id - b.id),
        subdiagrams: nodesToDiagramSubdiagramsDataModelTransformer.transform(
          flattenedNodes,
          { subdiagramRepository: diagram.subdiagramRepository }
        )
      }
    }
  }
}

export default diagramDomainModelToDiagramDataModelTransformer
