import Transformer from '../../../transformation/Transformer'
import { Node as ReactFlowNode } from 'reactflow'
import ContainerNode from '../../models/ContainerNode'
import reactFlowNodeFactory from './factories/reactFlowNodeFactory'
import ContainerNodeData from '../../../presentation/ContainerNodeData'
import mapperTransformerFactory from '../../../transformation/factories/mapperTransformerFactory'
import handleToReactFlowHandleTransformer from './handleToReactFlowHandleTransformer'

const containerNodeToReactFlowContainerNodeTransformer: Transformer<
  ContainerNode,
  ReactFlowNode<ContainerNodeData>
> = {
  transform: (node) =>
    reactFlowNodeFactory.create<ContainerNodeData>(
      node,
      {
        color: node.color,
        label: node.properties.Description ?? '[Container]',
        inputs: mapperTransformerFactory
          .create(handleToReactFlowHandleTransformer)
          .transform(node.getInputHandles(), undefined),
        output: handleToReactFlowHandleTransformer.transform(
          node.getOutputHandle(),
          undefined
        )
      },
      {
        type: 'multipleInputs'
      }
    )
}

export default containerNodeToReactFlowContainerNodeTransformer
