import { RestrictiveColorInputProps } from './RestrictiveColorInput.types'
import { ChangeEvent, useCallback } from 'react'

const useRestrictiveColorInput = (props: RestrictiveColorInputProps) => {
  const { colorOptions, onChange } = props

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const colorOption = colorOptions.find(
        (option) => option.color === event.target.value
      )
      if (colorOption) {
        onChange(colorOption)
      }
    },
    [colorOptions, onChange]
  )

  return {
    handleChange
  }
}

export default useRestrictiveColorInput
