import { DiagramListProps } from './DiagramList.types'
import { ChangeEvent, useCallback, useMemo, useState } from 'react'

const useDiagramList = (props: DiagramListProps) => {
  const { diagrams: allDiagrams, onSelectDiagram, selectedDiagram } = props

  const [searchTerm, setSearchTerm] = useState('')

  const handleSearchTermChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => setSearchTerm(event.target.value),
    []
  )

  const clearSearchTerm = useCallback(() => setSearchTerm(''), [])

  const diagrams = useMemo(
    () =>
      allDiagrams.filter((diagram) =>
        diagram.name.toLowerCase().includes(searchTerm.toLowerCase())
      ),
    [allDiagrams, searchTerm]
  )

  const handleDiagramClick = useCallback(onSelectDiagram, [onSelectDiagram])

  const isDiagramSelected = useCallback(
    (diagramUuid: string) => diagramUuid === selectedDiagram?.uuid,
    [selectedDiagram?.uuid]
  )

  return {
    diagrams,
    searchTerm,
    handleSearchTermChange,
    clearSearchTerm,
    handleDiagramClick,
    isDiagramSelected
  }
}

export default useDiagramList
