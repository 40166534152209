import { useCallback, useState } from 'react'

const useForceRender = () => {
  const [forceRender, setForceRender] = useState(true)

  return useCallback(() => {
    setForceRender(!forceRender)
  }, [forceRender])
}

export default useForceRender
