import { LabelProps } from './Label.types'

const Label = (props: LabelProps) => {
  const { children, color, style } = props

  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        flexDirection: 'column',
        height: '100%',
        color: color ? color : 'inherit',
        wordBreak: 'break-word',
        paddingLeft: 30,
        paddingRight: 30,
        ...style
      }}
    >
      <label htmlFor="text">{children}</label>
    </div>
  )
}

export default Label
