import React from 'react'
import DiagramService from '../DiagramService'
import DiagramRepository from '../DiagramRepository'
import DataTypeService from '../DataTypeService'
import NodeFactoryService from '../NodeFactoryService'

const diagramServiceContext = React.createContext<DiagramService>(
  new DiagramService(
    new DiagramRepository(),
    new NodeFactoryService(),
    new DataTypeService()
  )
)

export default diagramServiceContext
