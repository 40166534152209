import Handle from '../models/Handle'
import Input from '../models/Input'
import Connection from '../models/Connection'

const handleUtils = {
  canHandlesBeConnected: (a: Handle, b: Handle): Promise<void> => {
    if (a.node.getId() === b.node.getId() && a.id === b.id) {
      return Promise.reject('handle cannot be connected to itself')
    }

    if (a.node.getId() === b.node.getId()) {
      return Promise.reject('node cannot be connected to itself')
    }

    if (!a.dataType && !b.dataType) {
      return Promise.reject(
        'node cannot be connected: generic handles cannot be connected to each other'
      )
    }

    if (a.variant.toString() === b.variant.toString()) {
      return Promise.reject(
        'node cannot be connected: handle variants are equal'
      )
    }

    if (!a.isCompatibleWith(b) && !b.isCompatibleWith(a)) {
      return Promise.reject(
        'node cannot be connected: handle data types are not compatible'
      )
    }

    return Promise.resolve()
  },

  createConnection: (a: Handle, b: Handle): Connection => {
    const connection =
      b.variant instanceof Input ? new Connection(a, b) : new Connection(b, a)

    a.node.onCreateConnection(connection)
    b.node.onCreateConnection(connection)

    return connection
  },

  findHandleByIdIn<THandle extends Handle>(
    handles: THandle[],
    id: string
  ): THandle | undefined {
    for (const handle of handles) {
      if (handle.id === id) {
        return handle
      }
    }
    return undefined
  },

  findHandleByIndexIn<THandle extends Handle>(
    handles: THandle[],
    index: number
  ): THandle | undefined {
    for (const handle of handles) {
      if (handle.index === index) {
        return handle
      }
    }
    return undefined
  },

  findFirstHandle<THandle extends Handle>(
    handles: THandle[],
    where: (handle: THandle) => boolean
  ): THandle | undefined {
    return handles.find((value) => where(value))
  },

  isHandleArrayCompatible<THandle extends Handle>(
    aArray: THandle[],
    bArray: THandle[]
  ): boolean {
    const bMutableArray = [...bArray]

    for (const aHandle of aArray) {
      let compatibleIndex = null
      for (let i = 0; i < bMutableArray.length; i++) {
        const bHandle = bMutableArray[i]
        if (aHandle.isCompatibleWith(bHandle)) {
          compatibleIndex = i
        }
      }

      if (compatibleIndex === null) {
        return false
      }

      bMutableArray.splice(compatibleIndex, 1)
    }

    return true
  }
}

export default handleUtils
