import Value from './Value'

class BooleanValue implements Value {
  public value: boolean

  constructor(value = false) {
    this.value = value
  }

  toString(): string {
    return this.value ? 'true' : 'false'
  }
}

export default BooleanValue
