import Transformer from '../../../transformation/Transformer'
import Node from '../../models/Node'
import DiagramSubdiagramDataModel from '../../../data/models/DiagramSubdiagramDataModel'
import { parseNodeId } from '../../utils/idParsers'
import SubdiagramRepository from '../../models/SubdiagramRepository'

const nodesToDiagramSubdiagramsDataModelTransformer: Transformer<
  Node[],
  DiagramSubdiagramDataModel[],
  { subdiagramRepository: SubdiagramRepository }
> = {
  transform: (nodes, { subdiagramRepository }) => {
    const subdiagramMap: Record<string, DiagramSubdiagramDataModel> = {}

    for (const subdiagram of subdiagramRepository.all) {
      subdiagramMap[subdiagram.id] = {
        description: subdiagram.description,
        nodeIds: []
      }
    }

    for (const node of nodes) {
      if (!node.subdiagram) {
        continue
      }

      subdiagramMap[node.subdiagram?.id].nodeIds.push(parseNodeId(node.getId()))
    }

    return Object.values(subdiagramMap)
  }
}

export default nodesToDiagramSubdiagramsDataModelTransformer
