import Transformer from '../../../transformation/Transformer'
import Node from '../../models/Node'
import DiagramPropertyDataModel from '../../../data/models/DiagramPropertyDataModel'
import { parseNodeId } from '../../utils/idParsers'
import DiagramPropertyPropertyString from '../../../data/models/DiagramPropertyPropertyString'

const nodeToDiagramPropertiesDataModelTransformer: Transformer<
  Node,
  DiagramPropertyDataModel[]
> = {
  transform: (node) => {
    const properties: DiagramPropertyDataModel[] = []

    for (const pair of Object.entries(node.properties)) {
      const [key, value] = pair as [DiagramPropertyPropertyString, string]

      properties.push({
        id: parseNodeId(node.getId()),
        propertyString: key,
        valueString: value
      })
    }

    return properties
  }
}

export default nodeToDiagramPropertiesDataModelTransformer
