import InputNode from './InputNode'
import NodeIdService from './NodeIdService'
import Loopable from './Loopable'
import DiagramPropertyPropertyString from '../../data/models/DiagramPropertyPropertyString'
import NodeSelection from './NodeSelection'
import NodeRepository from './NodeRepository'
import OutputNode from './OutputNode'
import DataType from './dataTypes/DataType'
import NodeDuplicationOptions from './NodeDuplicationOptions'

class StreamInputNode extends InputNode implements Loopable {
  private readonly _nodeRepository: NodeRepository

  constructor(
    id: string,
    x: number,
    y: number,
    dataType: DataType,
    properties: Partial<Record<DiagramPropertyPropertyString, string>>,
    nodeSelection: NodeSelection,
    nodeRepository: NodeRepository
  ) {
    super(id, x, y, dataType, properties, nodeSelection)

    this._nodeRepository = nodeRepository
  }

  public get forwarding(): boolean {
    if (this.looping) {
      this.forwarding = false
    }

    return !(
      this.properties.Forwarding && this.properties.Forwarding === 'False'
    )
  }

  public set forwarding(value: boolean) {
    if (!value) {
      this.properties.Forwarding = 'False'
      return
    }
    delete this.properties.Forwarding
  }

  duplicate(
    nodeIdService: NodeIdService,
    options?: NodeDuplicationOptions
  ): Promise<StreamInputNode> {
    const node = new StreamInputNode(
      nodeIdService.getNextNodeId(),
      this.x,
      this.y,
      this.dataType,
      { ...this.properties },
      options?.nodeSelection ?? this._nodeSelection,
      options?.nodeRepository ?? this._nodeRepository
    )

    node.subdiagram = this.subdiagram

    nodeIdService.incrementId()

    return Promise.resolve(node)
  }

  get looping(): boolean {
    return (
      this._nodeRepository.filter(
        (node) =>
          node instanceof OutputNode &&
          !!node.properties.Description &&
          !!this.properties.Description &&
          node.dataType.isCompatible(this.dataType) &&
          node.properties.Description === this.properties.Description
      ).length === 1
    )
  }
}

export default StreamInputNode
