import { ValueSettingInputProps } from '../ValueSettingInput/ValueSettingInput.types'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import vectorValueFactory from '../../features/domain/factories/values/vectorValueFactory'
import VectorValue from '../../features/domain/models/values/VectorValue'
import useNumberParsers from '../../shared/useNumberParsers'

const useVectorValueSettingInput = (props: ValueSettingInputProps) => {
  const { value, onValueChange } = props

  const [vector, setVector] = useState(new VectorValue())

  const { parseFloatOrDefault } = useNumberParsers()

  useEffect(() => {
    vectorValueFactory
      .tryCreateWithStringRepresentation(value)
      .then(setVector)
      .catch(console.log)
  }, [value])

  const handleXChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      vector.x = parseFloatOrDefault(event.target.value)
      setVector(vector)
      onValueChange(vector.toString())
    },
    [onValueChange, parseFloatOrDefault, vector]
  )

  const handleYChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      vector.y = parseFloatOrDefault(event.target.value)
      setVector(vector)
      onValueChange(vector.toString())
    },
    [onValueChange, parseFloatOrDefault, vector]
  )

  const handleZChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      vector.z = parseFloatOrDefault(event.target.value)
      setVector(vector)
      onValueChange(vector.toString())
    },
    [onValueChange, parseFloatOrDefault, vector]
  )

  return {
    vector,
    handleXChange,
    handleYChange,
    handleZChange
  }
}

export default useVectorValueSettingInput
