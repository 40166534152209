import InputNode from './InputNode'
import DiagramPropertyPropertyString from '../../data/models/DiagramPropertyPropertyString'
import Value from './values/Value'
import BooleanValue from './values/BooleanValue'
import StringValue from './values/StringValue'
import VectorValue from './values/VectorValue'
import OrientationValue from './values/OrientationValue'
import FloatValue from './values/FloatValue'
import IntegerValue from './values/IntegerValue'
import SequenceValue from './values/SequenceValue'
import NodeIdService from './NodeIdService'
import NodeSelection from './NodeSelection'
import ArrayValue from './values/ArrayValue'
import DataType from './dataTypes/DataType'
import NodeDuplicationOptions from './NodeDuplicationOptions'

class ConstantInputNode extends InputNode {
  constructor(
    id: string,
    x: number,
    y: number,
    dataType: DataType,
    properties: Partial<Record<DiagramPropertyPropertyString, string>>,
    nodeSelection: NodeSelection
  ) {
    if (!properties.OutputValue) {
      let value: Value | undefined = undefined
      switch (dataType.name) {
        case 'Boolean':
          value = new BooleanValue()
          break
        case 'String':
          value = new StringValue()
          break
        case 'Vector':
          value = new VectorValue()
          break
        case 'Orientation':
          value = new OrientationValue()
          break
        case 'Float':
          value = new FloatValue()
          break
        case 'Integer':
          value = new IntegerValue()
          break
        case 'Sequence':
          value = new SequenceValue()
          break
        case 'Float array':
          value = new ArrayValue<FloatValue>()
          break
        case 'Integer array':
          value = new ArrayValue<IntegerValue>()
          break
        case 'Vector array':
          value = new ArrayValue<VectorValue>()
          break
        case 'Boolean array':
          value = new ArrayValue<BooleanValue>()
          break
        case 'String array':
          value = new ArrayValue<StringValue>()
          break
        case 'Orientation array':
          value = new ArrayValue<OrientationValue>()
          break
        case 'Sequence array':
          value = new ArrayValue<SequenceValue>()
          break
      }

      properties.OutputValue = value?.toString()
    }

    super(id, x, y, dataType, properties, nodeSelection)
  }

  duplicate(
    nodeIdService: NodeIdService,
    options?: NodeDuplicationOptions
  ): Promise<ConstantInputNode> {
    const node = new ConstantInputNode(
      nodeIdService.getNextNodeId(),
      this.x,
      this.y,
      this.dataType,
      { ...this.properties },
      options?.nodeSelection ?? this._nodeSelection
    )

    node.subdiagram = this.subdiagram

    nodeIdService.incrementId()

    return Promise.resolve(node)
  }
}

export default ConstantInputNode
