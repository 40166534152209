import Transformer from '../../../transformation/Transformer'
import ReactFlowHandle from '../../../presentation/Handle'
import Handle from '../../models/Handle'

const connectionToReactFlowEdgeTransformer: Transformer<
  Handle,
  ReactFlowHandle
> = {
  transform: (handle) => ({
    id: handle.id,
    symbol: handle.symbol,
    nodeId: handle.node.getId()
  })
}

export default connectionToReactFlowEdgeTransformer
