import React, { useMemo, Children, ReactElement } from 'react'

function ViewSwitch<TCase>(props: {
  children: React.ReactNode
  activeCase?: TCase
}) {
  const { children, activeCase } = props

  const activeChild = useMemo(() => {
    const activeChildren = Children.toArray(children).filter(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      (child) => activeCase === (child as ReactElement)?.props?.case
    )
    return activeChildren.length > 0 ? activeChildren[0] : null
  }, [children, activeCase])

  return <>{activeChild}</>
}

export default ViewSwitch
