import FunctionNode from '../models/FunctionNode'
import DiagramPropertyPropertyString from '../../data/models/DiagramPropertyPropertyString'
import DiagramFunctionType from '../../data/models/DiagramFunctionType'
import functionNodeFactoryMap from './functionNodeFactoryMap'
import NodeSelection from '../models/NodeSelection'
import DataType from '../models/dataTypes/DataType'

const functionNodeFactory = {
  create: (
    id: string,
    x: number,
    y: number,
    dataType: DataType,
    properties: Partial<Record<DiagramPropertyPropertyString, string>>,
    nodeSelection: NodeSelection,
    name: DiagramFunctionType
  ): FunctionNode => {
    if (!functionNodeFactoryMap[name]) {
      return functionNodeFactoryMap.Absolute.create(
        id,
        x,
        y,
        properties,
        nodeSelection,
        dataType
      )
    }

    return functionNodeFactoryMap[name].create(
      id,
      x,
      y,
      properties,
      nodeSelection,
      dataType
    )
  }
}

export default functionNodeFactory
