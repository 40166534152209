import ValueFactory from './ValueFactory'
import VectorValue from '../../models/values/VectorValue'

const vectorValueFactory: ValueFactory<VectorValue> = {
  tryCreateWithStringRepresentation(
    representation: string
  ): Promise<VectorValue> {
    const regex = new RegExp(/\((?<x>[0-9.]+), (?<y>[0-9.]+), (?<z>[0-9.]+)\)/g)

    const result = regex.exec(representation)

    if (result === null || !result.groups) {
      return Promise.reject(
        'representation does not conform to the correct format'
      )
    }

    const { x: xString, y: yString, z: zString } = result.groups

    if (!xString) {
      return Promise.reject('x capture group was not satisfied')
    }

    if (!yString) {
      return Promise.reject('y capture group was not satisfied')
    }

    if (!zString) {
      return Promise.reject('z capture group was not satisfied')
    }

    const x = parseFloat(xString)
    const y = parseFloat(yString)
    const z = parseFloat(zString)

    if (isNaN(x)) {
      return Promise.reject('x was not an float')
    }

    if (isNaN(y)) {
      return Promise.reject('y was not an float')
    }

    if (isNaN(z)) {
      return Promise.reject('z was not an float')
    }

    return Promise.resolve(new VectorValue(x, y, z))
  }
}

export default vectorValueFactory
