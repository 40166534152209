import Connection from './Connection'
import Node from './Node'
import { formatHandle } from '../utils/idFormatters'
import Handle from './Handle'
import DataType from './dataTypes/DataType'
import Variant from './Variant'
import handleUtils from '../utils/handleUtils'

class TypedHandle<TVariant extends Variant, TDataType extends DataType>
  implements Handle<TVariant, TDataType>
{
  readonly variant: TVariant

  readonly node: Node

  readonly index: number

  private readonly _dataType: TDataType

  constructor(node: Node, variant: TVariant, dataType: TDataType, index = 0) {
    this.node = node
    this.variant = variant
    this._dataType = dataType
    this.index = index
  }

  get dataType(): TDataType {
    return this._dataType
  }

  get symbol(): string {
    return this._dataType.symbol
  }

  get id(): string {
    return formatHandle(this.variant.toString(), this.node.getId(), this.index)
  }

  async createConnection(handle: Handle): Promise<Connection> {
    await handleUtils.canHandlesBeConnected(this, handle)
    return handleUtils.createConnection(handle, this)
  }

  isCompatibleWith(handle: Handle): boolean {
    return handle.dataType ? this.dataType.isCompatible(handle.dataType) : false
  }
}

export default TypedHandle
