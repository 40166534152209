import { ValueSettingInputProps } from '../ValueSettingInput/ValueSettingInput.types'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import useNumberParsers from '../../shared/useNumberParsers'
import OrientationValue from '../../features/domain/models/values/OrientationValue'
import orientationValueFactory from '../../features/domain/factories/values/orientationValueFactory'

const useOrientationValueSettingInput = (props: ValueSettingInputProps) => {
  const { value, onValueChange } = props

  const [orientation, setOrientation] = useState(new OrientationValue())

  const { parseFloatOrDefault } = useNumberParsers()

  useEffect(() => {
    orientationValueFactory
      .tryCreateWithStringRepresentation(value)
      .then(setOrientation)
      .catch(console.log)
  }, [value])

  const handleXChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      orientation.x = parseFloatOrDefault(event.target.value)
      setOrientation(orientation)
      onValueChange(orientation.toString())
    },
    [orientation, parseFloatOrDefault, onValueChange]
  )

  const handleYChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      orientation.y = parseFloatOrDefault(event.target.value)
      setOrientation(orientation)
      onValueChange(orientation.toString())
    },
    [onValueChange, parseFloatOrDefault, orientation]
  )

  const handleZChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      orientation.z = parseFloatOrDefault(event.target.value)
      setOrientation(orientation)
      onValueChange(orientation.toString())
    },
    [onValueChange, parseFloatOrDefault, orientation]
  )

  const handleWChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      orientation.w = parseFloatOrDefault(event.target.value)
      setOrientation(orientation)
      onValueChange(orientation.toString())
    },
    [onValueChange, parseFloatOrDefault, orientation]
  )

  return {
    orientation,
    handleXChange,
    handleYChange,
    handleZChange,
    handleWChange
  }
}

export default useOrientationValueSettingInput
