import Transformer from '../../../transformation/Transformer'
import DiagramPositionDataModel from '../../../data/models/DiagramPositionDataModel'
import Node from '../../models/Node'
import { parseNodeId } from '../../utils/idParsers'

const nodeToDiagramPositionDataModelTransformer: Transformer<
  Readonly<Node>,
  DiagramPositionDataModel
> = {
  transform: (node) => ({
    id: parseNodeId(node.getId()),
    x: node.x,
    y: node.y
  })
}

export default nodeToDiagramPositionDataModelTransformer
