import Transformer from '../../../transformation/Transformer'
import CompositedNodePartialMaps from './types/CompositedNodePartialMaps'
import CompositedNode from './types/CompositedNode'

const compositeNodePartialMapsToCompositeNodesTransformer: Transformer<
  CompositedNodePartialMaps,
  CompositedNode[]
> = {
  transform: ({ nodeMap, positionMap, propertiesMap }) => {
    const keys = Object.keys(positionMap)

    const map: Record<string, CompositedNode> = {}

    for (const key of keys) {
      if (!nodeMap[key] || !positionMap[key]) {
        continue
      }

      map[key] = {
        ...nodeMap[key],
        ...positionMap[key],
        ...(propertiesMap[key] ? propertiesMap[key] : { properties: {} })
      }
    }

    return Object.values(map)
  }
}

export default compositeNodePartialMapsToCompositeNodesTransformer
