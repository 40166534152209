import { DiagramDetailProps } from './DiagramDetail.types'
import useDiagramDetail from './useDiagramDetail'
import { Box, Button, ButtonBase, Divider, Paper, Stack } from '@mui/material'
import ReactFlow, { Background } from 'reactflow'
import nodeTypes from '../../features/presentation/utils/nodeTypes'
import proOptions from '../../features/presentation/utils/proOptions'
import React from 'react'
import Typography from '@mui/material/Typography'
import {
  ContentCopyOutlined,
  Delete,
  EditOutlined,
  FileDownloadOutlined,
  PhotoCameraOutlined
} from '@mui/icons-material'
import SubdiagramTabs from '../SubdiagramTabs'

const DiagramDetail = (props: DiagramDetailProps) => {
  const {
    name,
    manual,
    handleEditClick,
    nodes,
    edges,
    handleReactFlowInit,
    onDelete,
    onDuplicate,
    onDownloadAsImage,
    onDownloadAsJson,
    subdiagrams,
    selectedSubdiagram,
    handleSubdiagramSelect
  } = useDiagramDetail(props)

  return (
    <Paper
      variant="outlined"
      sx={{
        margin: 6
      }}
    >
      {subdiagrams.length > 0 && (
        <SubdiagramTabs
          subdiagrams={subdiagrams}
          onSelectSubdiagram={handleSubdiagramSelect}
          selectedSubdiagram={selectedSubdiagram}
        />
      )}
      <Box
        sx={{
          width: '100%',
          height: 600,
          backgroundColor: '#1976d217',
          position: 'relative'
        }}
      >
        {nodes.length > 0 && (
          <ReactFlow
            nodeTypes={nodeTypes}
            nodes={nodes}
            panOnDrag={false}
            zoomOnPinch={false}
            zoomOnScroll={false}
            edges={edges}
            proOptions={proOptions}
            onInit={handleReactFlowInit}
          >
            <Background gap={45} size={2} color="#1976d2" />
          </ReactFlow>
        )}
        <ButtonBase
          onClick={handleEditClick}
          sx={{
            height: '100%',
            width: '100%',
            position: 'absolute',
            top: 0,
            left: 0
          }}
        />
      </Box>
      <Divider />
      <Box sx={{ padding: 4 }}>
        <Typography variant="h6">{name}</Typography>
        <Typography variant="body2" sx={{ marginBottom: 2 }}>
          {manual.length > 0 ? manual : <span>&nbsp;</span>}
        </Typography>
        <Stack direction="row" spacing={1}>
          <Button
            endIcon={<EditOutlined />}
            variant="contained"
            size="large"
            onClick={handleEditClick}
          >
            Edit
          </Button>
          <Button
            endIcon={<ContentCopyOutlined />}
            variant="contained"
            size="large"
            onClick={onDuplicate}
          >
            Duplicate
          </Button>
          <Button
            endIcon={<PhotoCameraOutlined />}
            variant="contained"
            size="large"
            onClick={onDownloadAsImage}
          >
            Image
          </Button>
          <Button
            endIcon={<FileDownloadOutlined />}
            variant="contained"
            size="large"
            onClick={onDownloadAsJson}
          >
            Download
          </Button>
          <Button
            endIcon={<Delete />}
            variant="contained"
            size="large"
            color="error"
            onClick={onDelete}
          >
            Delete
          </Button>
        </Stack>
      </Box>
    </Paper>
  )
}

export default DiagramDetail
