import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { ArrayValueSettingInputProps } from './ArrayValueSettingInput.types'
import Value from '../../features/domain/models/values/Value'

const useArrayValueSettingInput = <TValue extends Value>(
  props: ArrayValueSettingInputProps<TValue>
) => {
  const { value, onValueChange, factory } = props

  const [text, setText] = useState('')

  const [errorText, setErrorText] = useState<string>()

  useEffect(() => {
    factory
      .tryCreateWithStringRepresentation(value)
      .then((created) => setText(created.values.join('\n')))
      .catch(console.log)
  }, [factory, value])

  const handleTextChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => setText(event.target.value),
    []
  )

  const handleSave = useCallback(() => {
    const representation = `[${text.split('\n').join(', ')}]`

    void factory
      .tryCreateWithStringRepresentation(representation)
      .then((newValue) => {
        onValueChange(newValue.toString())
        setErrorText('')
      })
      .catch((reason) => setErrorText(reason as string))
  }, [factory, onValueChange, text])

  return {
    text,
    handleTextChange,
    handleSave,
    errorText
  }
}

export default useArrayValueSettingInput
