import {
  createBrowserRouter,
  createRoutesFromElements,
  Route
} from 'react-router-dom'
import App from '../App'
import DiagramsLayout from '../DiagramsLayout'
import DiagramEditor from '../DiagramEditor'
import DiagramsPage from '../DiagramsPage/DiagramsPage.view'

const AppRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route path="diagrams" element={<DiagramsLayout />}>
        <Route element={<DiagramsPage />} index />
        <Route path=":uuid" element={<DiagramEditor />} />
      </Route>
    </Route>
  )
)

export default AppRouter
