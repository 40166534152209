/**
 * @throws {Error}
 */
const getContrastingColor = (
  hex: string,
  amplifyTo?: { lightColor: string; darkColor: string }
) => {
  if (hex.indexOf('#') === 0) {
    hex = hex.slice(1)
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
  }

  if (hex.length !== 6) {
    throw new Error('Invalid HEX color.')
  }

  const r = parseInt(hex.slice(0, 2), 16)
  const g = parseInt(hex.slice(2, 4), 16)
  const b = parseInt(hex.slice(4, 6), 16)

  if (amplifyTo) {
    return r * 0.299 + g * 0.587 + b * 0.114 > 50
      ? amplifyTo.darkColor
      : amplifyTo.lightColor
  }
  // invert color components
  const rString = (255 - r).toString(16)
  const gString = (255 - g).toString(16)
  const bString = (255 - b).toString(16)
  // pad each with zeros and return
  return `#${rString.padStart(2, '0')}${gString.padStart(
    2,
    '0'
  )}${bString.padStart(2, '0')}`
}

export default getContrastingColor
