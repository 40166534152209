import Transformer from '../../../transformation/Transformer'
import DiagramConnectionDataModel from '../../models/DiagramConnectionDataModel'
import Connection from '../../../domain/models/Connection'
import { formatNodeId, formatHandle } from '../../../domain/utils/idFormatters'
import Node from '../../../domain/models/Node'
import Outputable from '../../../domain/models/Outputable'
import Inputable from '../../../domain/models/Inputable'
import nodeUtils from '../../../domain/utils/nodeUtils'

const diagramConnectionDataModelsToConnectionsTransformer: Transformer<
  DiagramConnectionDataModel[],
  Connection[],
  { nodes: Node[] }
> = {
  transform: (dataConnections, { nodes }) => {
    const nodeMap = nodes.reduce<Record<string, Node>>((previous, current) => {
      previous[current.getId()] = current
      return previous
    }, {})

    const connections: Connection[] = []

    for (const dataConnection of dataConnections) {
      const inputNode = nodeMap[
        formatNodeId(dataConnection.inputNodeId)
      ] as unknown as (Inputable & Node) | undefined

      const outputNode = nodeMap[
        formatNodeId(dataConnection.outputNodeId)
      ] as unknown as (Outputable & Node) | undefined

      if (
        !inputNode ||
        !nodeUtils?.isInputable(inputNode) ||
        !outputNode ||
        !nodeUtils?.isOutputable(outputNode)
      ) {
        continue
      }

      const handleId = formatHandle(
        'i',
        formatNodeId(dataConnection.inputNodeId),
        dataConnection.inputIndex
      )

      const inputHandle = inputNode.getInputHandleById(handleId)

      if (!inputHandle) {
        continue
      }

      const outputHandle = outputNode.getOutputHandle()

      connections.push(new Connection(outputHandle, inputHandle))
    }

    return connections
  }
}

export default diagramConnectionDataModelsToConnectionsTransformer
