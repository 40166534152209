import Transformer from '../../../transformation/Transformer'
import DiagramNodeDataModel from '../../models/DiagramNodeDataModel'
import CompositedNodeNodePartial from './types/CompositedNodeNodePartial'

const diagramNodeDataModelToCompositeNodeNodePartialMapTransformer: Transformer<
  DiagramNodeDataModel[],
  Record<number, CompositedNodeNodePartial>
> = {
  transform: (nodes) => {
    return nodes.reduce<Record<number, CompositedNodeNodePartial>>(
      (map, node) => {
        map[node.id] = {
          id: node.id,
          classString: node.classString,
          typeString: node.typeString,
          outputType: node.outputType
        }
        return map
      },
      {}
    )
  }
}

export default diagramNodeDataModelToCompositeNodeNodePartialMapTransformer
