import { NodeProps, Position } from 'reactflow'
import DatatypeHandle from '../DatatypeHandle'
import NodeData from '../../features/presentation/NodeData'
import Inputable from '../../features/presentation/Inputable'
import Outputable from '../../features/presentation/Outputable'
import './MultipleInputsNode.styles.css'
import Label from '../Label'
import useMultipleInputsNode from './useMultipleInputsNode'

const MultipleInputsNode = (
  props: NodeProps<NodeData & Inputable & Outputable>
): JSX.Element => {
  const {
    inputs,
    output,
    label,
    labelColor,
    selected,
    color,
    viewBoxHeight,
    rectHeight
  } = useMultipleInputsNode(props)

  return (
    <div style={{ position: 'relative' }}>
      <svg
        style={{ display: 'block' }}
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 295 ${viewBoxHeight}`}
      >
        <rect
          className={selected ? 'selected-node-outline' : 'node-stroke'}
          fill={color}
          x="2.5"
          y="2.5"
          width="290"
          height={rectHeight}
          rx="10"
          ry="10"
        />
      </svg>
      <DatatypeHandle
        id={output.id}
        type="source"
        position={Position.Right}
        symbol={output.symbol}
      />
      <Label
        style={{
          width: '100%'
        }}
        color={labelColor}
      >
        {label}
      </Label>
      <section
        style={{
          top: 0,
          left: 0,
          position: 'fixed',
          display: 'flex',
          height: '100%',
          flexDirection: 'column',
          justifyContent: inputs.length > 1 ? 'space-between' : 'center'
        }}
      >
        {inputs.map(({ id, symbol }) => (
          <article key={id}>
            <DatatypeHandle
              id={id}
              type="target"
              position={Position.Left}
              style={{ position: 'relative' }}
              symbol={symbol}
            />
          </article>
        ))}
      </section>
    </div>
  )
}

export default MultipleInputsNode
