import Constraint from './Constraint'
import DataType from './dataTypes/DataType'
import Array from './dataTypes/Array'

class ValueConstraint implements Constraint {
  getSymbol(dataType: DataType | undefined): string {
    return dataType ? dataType.symbol : '?'
  }

  modify(dataType: DataType | undefined): DataType | undefined {
    return dataType && dataType instanceof Array
      ? (dataType.itemDataType as DataType)
      : dataType
  }

  conforms(dataType: DataType | undefined): boolean {
    return !dataType || !(dataType instanceof Array)
  }
}

export default ValueConstraint
