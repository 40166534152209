import { useParams } from 'react-router'
import useDiagram from '../../features/application/hooks/useDiagram'
import {
  useCallback,
  useEffect,
  useMemo,
  ChangeEvent,
  useState,
  DragEvent,
  useRef
} from 'react'
import diagramToReactFlowDiagramTransformer from '../../features/domain/transformation/toPresentation/diagramToReactFlowDiagramTransformer'
import {
  Connection,
  EdgeMouseHandler,
  NodeChange,
  ReactFlowInstance,
  useEdgesState,
  useNodesState,
  useOnSelectionChange
} from 'reactflow'
import useDownloader from '../../shared/useDownloader'
import useDiagramToImage from '../../features/presentation/hooks/useDiagramToImage'
import FunctionNode from '../../features/domain/models/FunctionNode'
import ConstantInputNode from '../../features/domain/models/ConstantInputNode'
import ContainerNode from '../../features/domain/models/ContainerNode'
import OutputNode from '../../features/domain/models/OutputNode'
import StreamInputNode from '../../features/domain/models/StreamInputNode'
import { toast } from '../../features/materialToast'
import NodePositionUpdate from '../../features/presentation/NodePositionUpdate'
import useNodeFactories from '../../features/application/hooks/useNodeFactories'
import DraggedNode from '../../features/presentation/DraggedNode'
import DraggedNodeFunctionNodeData from '../../features/presentation/DraggedNodeFunctionNodeData'
import DraggedNodeDataTypeableNodeData from '../../features/presentation/DraggedNodeDataTypeableNodeData'
import useDataTypes from '../../features/application/hooks/useDataTypes'

const useDiagramEditor = () => {
  const { uuid } = useParams()

  const [reactFlowInstance, setReactFlowInstance] =
    useState<ReactFlowInstance | null>(null)
  const reactFlowWrapper = useRef<HTMLDivElement>(null)

  const {
    view,
    name,
    setName,
    trySave,
    updateNodePositions,
    tryCreateConnection,
    deleteNodesByIds,
    undo,
    redo,
    hasUndo,
    hasRedo,
    clear,
    manualLines,
    setManualLines,
    tryToJsonString,
    tryRemoveConnection,
    tryContain,
    tryDecontain,
    isContainer,
    addNode,
    addFunctionNode,
    getNodeById,
    updateNodeColor,
    updateNodeDescription,
    updateNodeOutputValue,
    tryReplaceNode,
    tryDuplicateNodes,
    selectedNodeIds,
    setSelectedNodeIds,
    updateNodeForwarding,
    updateNodeHidden,
    subdiagrams,
    createSubdiagram,
    deleteSubdiagramById,
    selectedSubdiagram,
    setSelectedSubdiagram,
    updateSubdiagramDescription,
    tryCopy,
    tryPaste
  } = useDiagram(uuid as string)

  const {
    getFunctionNodeFactoryByName,
    outputFactory,
    constantInputFactory,
    streamInputFactory
  } = useNodeFactories()

  const { getDataTypeByName } = useDataTypes()

  const download = useDownloader()

  const { toPng } = useDiagramToImage()

  const { nodes: initialNodes, edges: initialEdges } = useMemo(
    () => diagramToReactFlowDiagramTransformer.transform(view, undefined),
    [view]
  )

  const [nodes, setNodes, onNodesChange] = useNodesState([])
  const [edges, setEdges, onEdgesChange] = useEdgesState([])

  const deleteIsDisabled = useMemo(
    () => selectedNodeIds.length === 0,
    [selectedNodeIds.length]
  )

  const viewIsEmpty = useMemo(
    () => view.nodes.length === 0 && view.connections.length === 0,
    [view]
  )

  const handleDelete = useCallback(() => {
    deleteNodesByIds(selectedNodeIds)
    toast('Deleted selected nodes', { type: 'success' })
  }, [deleteNodesByIds, selectedNodeIds])

  useOnSelectionChange({
    onChange: ({ nodes: newSelectedNodes }) =>
      setSelectedNodeIds(
        newSelectedNodes.map((selectedNode) => selectedNode.id)
      )
  })

  useEffect(() => {
    setEdges(initialEdges)
    setNodes(initialNodes)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialEdges, initialNodes])

  const areNodesSelected = useMemo(
    () => selectedNodeIds.length > 0,
    [selectedNodeIds.length]
  )

  const handleNameChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setName(event.target.value)
    },
    [setName]
  )

  const handleSave = useCallback(
    () =>
      void trySave()
        .then(() => toast('Diagram saved', { type: 'success' }))
        .catch(() => toast('Diagram could not be saved', { type: 'error' })),
    [trySave]
  )

  const handleNodeChange = useCallback(
    (changes: NodeChange[]) => {
      const nodePositionUpdates = changes.reduce<NodePositionUpdate[]>(
        (updates, change) => {
          if (change.type === 'position' && !change.dragging) {
            for (const node of nodes) {
              if (node.id === change.id) {
                updates.push({
                  nodeId: node.id,
                  x: node.position.x,
                  y: node.position.y
                })
              }
            }
          }
          return updates
        },
        []
      )

      updateNodePositions(nodePositionUpdates)

      onNodesChange(changes)
    },
    [nodes, onNodesChange, updateNodePositions]
  )

  const handleConnect = useCallback(
    ({ source, target, targetHandle }: Connection) => {
      if (source !== null && target !== null && targetHandle !== null) {
        void tryCreateConnection(source, target, targetHandle)
          .then(() => {})
          .catch((reason) => toast(reason as string, { type: 'error' }))
      }
    },
    [tryCreateConnection]
  )

  const downloadDiagramAsImage = useCallback(() => {
    void toPng()
      .then((data) => {
        download(name, data)
        toast('Diagram image downloading...', { type: 'info' })
      })
      .catch(() =>
        toast('Could not download diagram as image', { type: 'error' })
      )
  }, [download, name, toPng])

  const manual = useMemo(() => manualLines.join('\n'), [manualLines])

  const handleManualChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      const newManual = event.target.value
      if (newManual.length === 0) {
        setManualLines([])
        return
      }
      setManualLines(newManual.split('\n'))
    },
    [setManualLines]
  )

  const downloadDiagramAsJson = useCallback(() => {
    void tryToJsonString()
      .then((json) => {
        download(
          `${name}.dfd`,
          window.URL.createObjectURL(
            new Blob([json], { type: 'application/json' })
          )
        )
        toast('Diagram downloading...', { type: 'info' })
      })
      .catch(() => toast('Could not download diagram', { type: 'error' }))
  }, [download, name, tryToJsonString])

  const handleEdgeDoubleClick = useCallback<EdgeMouseHandler>(
    (event, { source, target, targetHandle }) => {
      if (source !== null && target !== null && targetHandle) {
        void tryRemoveConnection(source, target, targetHandle)
          .then(() => {})
          .catch((reason) => toast(reason as string, { type: 'error' }))
      }
    },
    [tryRemoveConnection]
  )

  const handleContain = useCallback(() => {
    void tryContain(selectedNodeIds)
      ?.then(() => toast('Contained selected nodes', { type: 'success' }))
      .catch((reason) => toast(reason as string, { type: 'error' }))
  }, [selectedNodeIds, tryContain])

  const handleDecontain = useCallback(() => {
    if (selectedNodeIds.length !== 1) {
      return
    }

    void tryDecontain(selectedNodeIds[0])
      ?.then(() => toast('Decontained selected nodes', { type: 'success' }))
      .catch((reason) => toast(reason as string, { type: 'error' }))
  }, [selectedNodeIds, tryDecontain])

  const containIsDisabled = useMemo(
    () => selectedNodeIds.length < 2,
    [selectedNodeIds.length]
  )

  const decontainIsDisabled = useMemo(
    () =>
      selectedNodeIds.length === 0 ||
      !(selectedNodeIds.length === 1 && isContainer(selectedNodeIds[0])),
    [isContainer, selectedNodeIds]
  )

  const selectedNode = useMemo(
    () =>
      selectedNodeIds.length === 1
        ? getNodeById(selectedNodeIds[0])
        : undefined,
    [getNodeById, selectedNodeIds]
  )

  const clearSelected = useCallback(() => {
    setSelectedNodeIds([])
  }, [setSelectedNodeIds])

  const handleNodeColorPropertyChange = useCallback(
    (color: string) => {
      if (!selectedNode) {
        return
      }

      updateNodeColor(selectedNode.getId(), color)
    },
    [selectedNode, updateNodeColor]
  )

  const handleNodeDescriptionPropertyChange = useCallback(
    (description: string | undefined) => {
      if (!selectedNode) {
        return
      }

      updateNodeDescription(selectedNode.getId(), description)
    },
    [updateNodeDescription, selectedNode]
  )

  const handleNodeOutputValuePropertyChange = useCallback(
    (value: string) => {
      if (!selectedNode) {
        return
      }

      updateNodeOutputValue(selectedNode.getId(), value)
    },
    [updateNodeOutputValue, selectedNode]
  )

  const handleNodeForwardingPropertyChange = useCallback(
    (forwarding: boolean) => {
      if (!selectedNode) {
        return
      }

      updateNodeForwarding(selectedNode.getId(), forwarding)
    },
    [updateNodeForwarding, selectedNode]
  )

  const handleNodeHiddenPropertyChange = useCallback(
    (hidden: boolean) => {
      if (!selectedNode) {
        return
      }

      updateNodeHidden(selectedNode.getId(), hidden)
    },
    [updateNodeHidden, selectedNode]
  )

  const activeTabKey = useMemo(() => {
    switch (true) {
      case selectedNode instanceof FunctionNode:
        return 'function'
      case selectedNode instanceof ConstantInputNode:
        return 'constantInput'
      case selectedNode instanceof StreamInputNode:
        return 'streamInput'
      case selectedNode instanceof OutputNode:
        return 'output'
      case selectedNode instanceof ContainerNode:
        return 'container'
      default:
        return undefined
    }
  }, [selectedNode])

  const handleClear = useCallback(() => {
    clear()
    toast('Diagram cleared', { type: 'success' })
  }, [clear])

  const duplicateIsDisabled = useMemo(
    () => selectedNodeIds.length === 0,
    [selectedNodeIds.length]
  )

  const handleDuplicate = useCallback(() => {
    tryDuplicateNodes(selectedNodeIds)
      .then(() => toast('Nodes duplicated', { type: 'success' }))
      .catch((reason) => toast(reason as string, { type: 'error' }))
  }, [selectedNodeIds, tryDuplicateNodes])

  const handleReplace = useCallback(() => {
    if (selectedNodeIds.length === 2) {
      const [aId, bId] = selectedNodeIds

      tryReplaceNode(aId, bId)
        .then(() => toast('Node replaced', { type: 'success' }))
        .catch((reason) => toast(reason as string, { type: 'error' }))
    }
  }, [selectedNodeIds, tryReplaceNode])

  const replaceIsDisabled = useMemo(
    () => selectedNodeIds.length !== 2,
    [selectedNodeIds.length]
  )

  const handleCopy = useCallback(() => {
    tryCopy(selectedNodeIds)
      .then((text) => navigator.clipboard.writeText(text))
      .then(() => toast('selection copied to clipboard', { type: 'success' }))
      .catch((reason) => toast(reason as string, { type: 'error' }))
  }, [selectedNodeIds, tryCopy])

  const copyIsDisabled = useMemo(
    () => selectedNodeIds.length === 0,
    [selectedNodeIds.length]
  )

  useEffect(() => {
    const copyListener = (e: ClipboardEvent) => {
      if (
        e.target instanceof HTMLInputElement ||
        e.target instanceof HTMLTextAreaElement
      ) {
        return
      }

      e.stopPropagation()
      e.preventDefault()

      if (selectedNodeIds.length === 0) {
        return
      }

      tryCopy(selectedNodeIds)
        .then((text) => {
          if (e.clipboardData) {
            e.clipboardData.setData('text', text)
          }
          return Promise.resolve()
        })
        .then(() => toast('Selection copied to clipboard', { type: 'success' }))
        .catch(() => toast('Copy failed', { type: 'error' }))
    }

    const pasteListener = (e: ClipboardEvent) => {
      if (
        e.target instanceof HTMLInputElement ||
        e.target instanceof HTMLTextAreaElement
      ) {
        return
      }

      e.stopPropagation()
      e.preventDefault()

      if (!e.clipboardData) {
        return
      }

      const data = e.clipboardData.getData('text')
      tryPaste(data)
        .then(() => toast('Diagram pasted from clipboard', { type: 'success' }))
        .catch((reason) => toast(reason as string, { type: 'error' }))
    }

    document.addEventListener('copy', copyListener)
    document.addEventListener('paste', pasteListener)

    return () => {
      document.removeEventListener('copy', copyListener)
      document.removeEventListener('paste', pasteListener)
    }
  }, [selectedNodeIds, tryCopy, tryPaste])

  const [subdiagramsDialogIsOpen, setSubdiagramsDialogIsOpen] = useState(false)

  const handleCloseSubdiagramsDialog = useCallback(
    () => setSubdiagramsDialogIsOpen(false),
    []
  )

  const handleOpenSubdiagramsDialog = useCallback(
    () => setSubdiagramsDialogIsOpen(true),
    []
  )

  const handleCreateSubdiagram = useCallback(() => {
    createSubdiagram()
    toast('Subdiagram created', { type: 'success' })
  }, [createSubdiagram])

  const handleDeleteSubdiagram = useCallback(
    (id: number) => {
      deleteSubdiagramById(id)
      toast('Subdiagram deleted', { type: 'success' })
    },
    [deleteSubdiagramById]
  )

  const handleNodePreviewDrop = useCallback(
    (event: DragEvent<HTMLDivElement>) => {
      event.preventDefault()

      if (!reactFlowWrapper.current || !reactFlowInstance) {
        return
      }

      const reactFlowBounds = reactFlowWrapper.current?.getBoundingClientRect()

      const { x, y } = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top
      })

      const data = event.dataTransfer.getData('application/dataflow')

      try {
        const draggedNode = JSON.parse(data) as DraggedNode<unknown>

        switch (draggedNode.type) {
          case 'function':
            const factory = getFunctionNodeFactoryByName(
              (draggedNode.data as DraggedNodeFunctionNodeData).name
            )
            if (factory) {
              addFunctionNode(factory, { x, y })
            }
            return
          case 'constantInput':
            addNode(
              constantInputFactory,
              getDataTypeByName(
                (draggedNode.data as DraggedNodeDataTypeableNodeData).dataType
              ),
              { x, y }
            )
            return
          case 'streamInput':
            addNode(
              streamInputFactory,
              getDataTypeByName(
                (draggedNode.data as DraggedNodeDataTypeableNodeData).dataType
              ),
              { x, y }
            )
            return
          case 'output':
            addNode(
              outputFactory,
              getDataTypeByName(
                (draggedNode.data as DraggedNodeDataTypeableNodeData).dataType
              ),
              {
                x,
                y
              }
            )
            return
        }
      } catch {}
    },
    [
      addFunctionNode,
      addNode,
      constantInputFactory,
      getDataTypeByName,
      getFunctionNodeFactoryByName,
      outputFactory,
      reactFlowInstance,
      streamInputFactory
    ]
  )

  const handleNodePreviewDragOver = useCallback(
    (event: DragEvent<HTMLDivElement>) => {
      event.preventDefault()
      event.dataTransfer.dropEffect = 'move'
    },
    []
  )

  return {
    nodes,
    edges,
    handleNodeChange,
    onEdgesChange,
    name,
    handleNameChange,
    handleSave,
    handleDelete,
    deleteIsDisabled,
    handleConnect,
    undo,
    redo,
    hasUndo,
    hasRedo,
    viewIsEmpty,
    downloadDiagramAsImage,
    downloadDiagramAsJson,
    manual,
    handleManualChange,
    handleEdgeDoubleClick,
    handleContain,
    handleDecontain,
    containIsDisabled,
    decontainIsDisabled,
    areNodesSelected,
    selectedNode,
    clearSelected,
    activeTabKey,
    handleNodeColorPropertyChange,
    handleNodeDescriptionPropertyChange,
    handleNodeOutputValuePropertyChange,
    handleClear,
    duplicateIsDisabled,
    handleDuplicate,
    handleReplace,
    replaceIsDisabled,
    handleNodeForwardingPropertyChange,
    handleNodeHiddenPropertyChange,
    subdiagramsDialogIsOpen,
    handleCloseSubdiagramsDialog,
    handleOpenSubdiagramsDialog,
    subdiagrams,
    handleCreateSubdiagram,
    handleDeleteSubdiagram,
    selectedSubdiagram,
    setSelectedSubdiagram,
    updateSubdiagramDescription,
    handleNodePreviewDrop,
    handleNodePreviewDragOver,
    setReactFlowInstance,
    reactFlowWrapper,
    handleCopy,
    copyIsDisabled
  }
}

export default useDiagramEditor
