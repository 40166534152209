import useContrastingColor from '../../features/presentation/hooks/useContrastingColor'
import { useMemo } from 'react'
import { NodeProps } from 'reactflow'
import OutputNodeData from '../../features/presentation/OutputNodeData'

const useOutputNode = (props: NodeProps<OutputNodeData>) => {
  const {
    data: { label, inputs, color, hasUniqueDescription, looping, hidden },
    selected
  } = props

  const labelColor = useContrastingColor(color ?? '')

  const input = useMemo(
    () =>
      inputs.length > 0
        ? inputs[0]
        : { dataType: undefined, id: '', nodeId: '', symbol: '?' },
    [inputs]
  )

  return {
    selected,
    input,
    label,
    labelColor,
    color,
    hasUniqueDescription,
    looping,
    hidden
  }
}

export default useOutputNode
