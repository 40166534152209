import { useCallback, useContext, useEffect, useState } from 'react'
import diagramServiceContext from '../context/diagramServiceContext'
import useThrowAsyncError from '../../../shared/useThrowAsyncError'
import Diagram from '../../domain/models/Diagram'
import useForceRender from '../../../shared/useForceRender'
import DiagramDataModel from '../../data/models/DiagramDataModel'

const useDiagrams = () => {
  const throwError = useThrowAsyncError()

  const service = useContext(diagramServiceContext)

  const [diagrams, setDiagrams] = useState<Diagram[]>([])

  const forceRender = useForceRender()

  useEffect(() => {
    void service
      .allDiagrams()
      .then((newDiagrams) => {
        setDiagrams(newDiagrams)
      })
      .catch(throwError)
  }, [forceRender, service, throwError])

  const deleteDiagramByUuid = useCallback(
    (uuid: string) =>
      service.deleteDiagramByUuid(uuid).then(() => {
        forceRender()
      }),
    [forceRender, service]
  )

  const duplicateDiagramByUuid = useCallback(
    (diagramUuid: string) =>
      service.duplicateDiagramByUuid(diagramUuid).then(() => {
        forceRender()
      }),
    [forceRender, service]
  )

  const importDiagram = useCallback(
    (name: string, diagramDataModel: DiagramDataModel) =>
      service.importDiagram(name, diagramDataModel).then(() => {
        forceRender()
      }),
    [forceRender, service]
  )

  const createDiagram = useCallback(
    (name: string, manualLines: string[]) =>
      service.createDiagram(name, manualLines).then(() => {
        forceRender()
      }),
    [forceRender, service]
  )

  const downloadDiagramAsJson = useCallback(
    (diagram: Diagram) =>
      Promise.resolve(service.createJsonStringFromDiagram(diagram)),
    [service]
  )

  return {
    diagrams,
    deleteDiagramByUuid,
    createDiagram,
    importDiagram,
    duplicateDiagramByUuid,
    downloadDiagramAsJson
  }
}

export default useDiagrams
