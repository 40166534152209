import Node from '../../../models/Node'
import { Node as ReactFlowNode, Position } from 'reactflow'

const reactFlowNodeFactory = {
  create: <TNodeData>(
    node: Node,
    data: TNodeData,
    overrides?: Partial<Omit<ReactFlowNode<TNodeData>, 'data'>>
  ): ReactFlowNode<TNodeData> => ({
    id: node.getId(),
    selected: node.selected,
    position: {
      x: node.x,
      y: node.y
    },
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
    ...overrides,
    data
  })
}

export default reactFlowNodeFactory
