import { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

const useApp = () => {
  // OKE NERDS
  const navigate = useNavigate()

  const location = useLocation()

  useEffect(() => {
    if (location.pathname === '/') {
      navigate('/diagrams', {
        replace: true
      })
    }
  }, [location.pathname, navigate])
}

export default useApp
