import { ForwardingSettingProps } from './ForwardingSetting.types'
import { ChangeEvent, useCallback } from 'react'

const useForwardingSetting = (props: ForwardingSettingProps) => {
  const { forwarding = false, onForwardingChange, disabled = false } = props

  const handleForwardingChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      onForwardingChange(checked)
    },
    [onForwardingChange]
  )

  return {
    forwarding,
    handleForwardingChange,
    disabled
  }
}

export default useForwardingSetting
