import { RestrictiveColorInputProps } from './RestrictiveColorInput.types'
import { Box, MenuItem, Stack, TextField } from '@mui/material'
import useRestrictiveColorInput from './useRestrictiveColorInput'

const RestrictiveColorInput = (props: RestrictiveColorInputProps) => {
  const { colorOptions, selectedColorOption } = props

  const { handleChange } = useRestrictiveColorInput(props)

  return (
    <TextField
      select
      label="Color"
      value={selectedColorOption?.color}
      onChange={handleChange}
    >
      {colorOptions.map((option) => (
        <MenuItem key={option.label} value={option.color}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Box
              component="div"
              sx={{
                backgroundColor: option.color,
                height: 17,
                width: 17,
                borderRadius: 1
              }}
            />
            <Box component="span">{option.label}</Box>
          </Stack>
        </MenuItem>
      ))}
    </TextField>
  )
}

export default RestrictiveColorInput
