import { z } from 'zod'
import DiagramPositionDataModel from '../models/DiagramPositionDataModel'

const diagramPositionDataModelValidator: z.ZodType<DiagramPositionDataModel> =
  z.object({
    id: z.number(),
    x: z.number(),
    y: z.number()
  })

export default diagramPositionDataModelValidator
