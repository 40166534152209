import { SubdiagramsDialogProps } from './SubdiagramsDialog.types'

const useSubdiagramsDialog = (props: SubdiagramsDialogProps) => {
  const {
    onCreateSubdiagram,
    onDeleteSubdiagram,
    onUpdateDescriptionOfSubdiagram,
    subdiagrams,
    onClose,
    isOpen
  } = props

  return {
    onCreateSubdiagram,
    onDeleteSubdiagram,
    onUpdateDescriptionOfSubdiagram,
    subdiagrams,
    onClose,
    isOpen
  }
}

export default useSubdiagramsDialog
