import { z } from 'zod'
import DiagramPropertyDataModel from '../models/DiagramPropertyDataModel'

const diagramPropertyDataModelValidator: z.ZodType<DiagramPropertyDataModel> = z
  .object({
    id: z.number(),
    propertyString: z.enum([
      'OutputValue',
      'Color',
      'Description',
      'Forwarding',
      'Hidden'
    ]),
    valueString: z.string()
  })
  .refine(
    (input) =>
      !(input.propertyString === 'Description' && input.valueString.length > 32)
  )

export default diagramPropertyDataModelValidator
