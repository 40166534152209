import Transformer from '../../../transformation/Transformer'
import { formatNodeId } from '../../../domain/utils/idFormatters'
import Node from '../../../domain/models/Node'
import DiagramContainerDataModel from '../../models/DiagramContainerDataModel'
import ConnectionRepository from '../../../domain/models/ConnectionRepository'
import ContainerNode from '../../../domain/models/ContainerNode'
import DiagramPropertyPropertyString from '../../models/DiagramPropertyPropertyString'
import containerUtils from '../../../domain/utils/containerUtils'
import NodeSelection from '../../../domain/models/NodeSelection'

const createContainerNodes = (
  containers: DiagramContainerDataModel[],
  nodeMap: Record<string, Node>,
  connectionRepository: ConnectionRepository,
  nodeSelection: NodeSelection
): Node[] => {
  const nodes: Node[] = []

  for (const container of containers) {
    const children: Node[] = []
    for (const nodeId of container.nodeIds) {
      children.push(nodeMap[formatNodeId(nodeId)])
    }

    if (container.containers && container.containers.length > 0) {
      children.push(
        ...createContainerNodes(
          container.containers,
          nodeMap,
          connectionRepository,
          nodeSelection
        )
      )
    }

    nodes.push(
      new ContainerNode(
        container.x,
        container.y,
        {
          Description: container.description,
          Color: container.color
        } as Record<DiagramPropertyPropertyString, string>,
        children,
        connectionRepository,
        nodeSelection
      )
    )
  }

  return nodes
}

const nodesToNodesWithContainerNodesTransformer: Transformer<
  Node[],
  Node[],
  {
    containers: DiagramContainerDataModel[]
    connectionRepository: ConnectionRepository
    nodeSelection: NodeSelection
  }
> = {
  transform: (nodes, { containers, connectionRepository, nodeSelection }) => {
    const nodeMap = nodes.reduce<Record<string, Node>>((previous, current) => {
      previous[current.getId()] = current
      return previous
    }, {})

    const containerNodes = createContainerNodes(
      containers,
      nodeMap,
      connectionRepository,
      nodeSelection
    )

    const nodeIdContainerIdMap =
      containerUtils.createNodeIdContainerIdMap(containerNodes)

    for (const nodeId of Object.keys(nodeIdContainerIdMap)) {
      delete nodeMap[nodeId]
    }

    return [...Object.values(nodeMap), ...containerNodes]
  }
}

export default nodesToNodesWithContainerNodesTransformer
