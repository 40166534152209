import { Alert } from '@mui/material'
import React from 'react'
import { Id, toast as reactToastifyToast } from 'react-toastify'
import { ToastContent } from 'react-toastify/dist/types'
import MaterialToastOptions from './MaterialToastOptions'
import './toastify-overrides.css'

export function toast<TData>(
  content: ToastContent<TData>,
  options?: MaterialToastOptions
): Id {
  return reactToastifyToast<TData>((contentProps) => {
    const {
      toastProps: { type, closeButton },
      closeToast
    } = contentProps

    return (
      <Alert
        variant="filled"
        severity={type === 'default' ? 'info' : type}
        onClose={closeButton ? closeToast : undefined}
        sx={{ boxShadow: 1 }}
        action={
          options?.action instanceof Function
            ? options.action(type === 'default' ? 'info' : type)
            : options?.action
        }
      >
        {content instanceof Function ? content(contentProps) : content}
      </Alert>
    )
  }, options)
}
