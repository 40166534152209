import { Stack } from '@mui/material'
import React from 'react'
import { FunctionNodeSettingsProps } from './FunctionNodeSettings.types'
import ColorSetting from '../ColorSetting'

const FunctionNodeSettings = (props: FunctionNodeSettingsProps) => {
  const { node, onChangeColor } = props

  return (
    <Stack spacing={2}>
      <ColorSetting color={node.color} onColorChange={onChangeColor} />
    </Stack>
  )
}

export default FunctionNodeSettings
