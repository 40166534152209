import Value from './Value'

class FloatValue implements Value {
  public value: number

  constructor(value = 0.0) {
    this.value = value
  }

  toString(): string {
    return this.value.toFixed(4)
  }
}

export default FloatValue
