import {
  saveDataStore,
  loadDataStoreOrDefault,
  validateDataStoreOrDefault
} from './utils'
import Diagram from '../models/Diagram'

const diagramDataStore = {
  loadDiagrams: (): Promise<Diagram[]> =>
    loadDataStoreOrDefault()
      .then(validateDataStoreOrDefault)
      .then((store) => Promise.resolve(Object.values(store))),

  loadDiagram: (uuid: string): Promise<Diagram> =>
    loadDataStoreOrDefault()
      .then(validateDataStoreOrDefault)
      .then((store) =>
        store[uuid]
          ? Promise.resolve(store[uuid])
          : Promise.reject(`diagram with uuid was not found '${uuid}'`)
      ),

  saveDiagram: (diagram: Diagram): Promise<void> =>
    loadDataStoreOrDefault()
      .then(validateDataStoreOrDefault)
      .then((store) => {
        store[diagram.uuid] = diagram
        return saveDataStore(store)
      }),

  deleteDiagram: (uuid: string): Promise<void> =>
    loadDataStoreOrDefault()
      .then(validateDataStoreOrDefault)
      .then((store) => {
        delete store[uuid]
        return saveDataStore(store)
      })
}

export default diagramDataStore
