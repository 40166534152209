import functionNodeTypeFactoryFactory from './FunctionNodeTypeFactoryFactory'
import FunctionNode from '../models/FunctionNode'
import FunctionTypeNodeFactory from './FunctionTypeNodeFactory'
import TypedHandle from '../models/TypedHandle'
import Float from '../models/dataTypes/Float'
import Input from '../models/Input'
import Output from '../models/Output'
import GenericHandle from '../models/GenericHandle'
import ValueConstraint from '../models/ValueConstraint'
import ArrayConstraint from '../models/ArrayConstraint'
import DiagramFunctionType from '../../data/models/DiagramFunctionType'
import BooleanType from '../models/dataTypes/Boolean'
import Array from '../models/dataTypes/Array'
import Integer from '../models/dataTypes/Integer'
import Sequence from '../models/dataTypes/Sequence'
import Vector from '../models/dataTypes/Vector'
import StringType from '../models/dataTypes/String'
import AnyConstraint from '../models/AnyConstraint'
import Orientation from '../models/dataTypes/Orientation'

const functionNodeFactoryMap: Record<
  DiagramFunctionType,
  FunctionTypeNodeFactory
> = {
  Absolute: functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Absolute',
        (node) => [
          new TypedHandle(node, new Input(), new Float()),
          new TypedHandle(node, new Input(), new Float(), 1)
        ],
        (node) => new TypedHandle(node, new Output(), new Float())
      )
  ),
  Add: functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection, dataType) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Add',
        (node) => [
          new GenericHandle(node, new Input(), undefined, dataType, 0),
          new GenericHandle(node, new Input(), undefined, dataType, 1)
        ],
        (node) => new GenericHandle(node, new Output(), undefined, dataType)
      )
  ),
  'Add to array': functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection, dataType) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Add to array',
        (node) => [
          new GenericHandle(
            node,
            new Input(),
            new ValueConstraint(),
            dataType,
            0
          ),
          new GenericHandle(
            node,
            new Input(),
            new ArrayConstraint(),
            dataType,
            1
          )
        ],
        (node) =>
          new GenericHandle(node, new Output(), new ArrayConstraint(), dataType)
      )
  ),
  Adder: functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Adder',
        (node) => [new TypedHandle(node, new Input(), new Float())],
        (node) => new TypedHandle(node, new Output(), new Float())
      )
  ),
  And: functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'And',
        (node) => [
          new TypedHandle(node, new Input(), new BooleanType(), 0),
          new TypedHandle(node, new Input(), new BooleanType(), 1)
        ],
        (node) => new TypedHandle(node, new Output(), new BooleanType())
      )
  ),
  'Array absolute': functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Array absolute',
        (node) => [new TypedHandle(node, new Input(), new Array(new Float()))],
        (node) => new TypedHandle(node, new Output(), new Array(new Float()))
      )
  ),
  'Array sum': functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Array sum',
        (node) => [new TypedHandle(node, new Input(), new Array(new Float()))],
        (node) => new TypedHandle(node, new Output(), new Float())
      )
  ),
  Average: functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Average',
        (node) => [new TypedHandle(node, new Input(), new Array(new Float()))],
        (node) => new TypedHandle(node, new Output(), new Float())
      )
  ),
  Averager: functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Averager',
        (node) => [
          new TypedHandle(node, new Input(), new BooleanType(), 0),
          new TypedHandle(node, new Input(), new Array(new Float()), 1)
        ],
        (node) => new TypedHandle(node, new Output(), new Float())
      )
  ),
  Buffer: functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection, dataType) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Buffer',
        (node) => [
          new TypedHandle(node, new Input(), new Integer(), 0),
          new GenericHandle(node, new Input(), undefined, dataType, 1)
        ],
        (node) => new GenericHandle(node, new Output(), undefined, dataType)
      )
  ),
  'Cap values': functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Cap values',
        (node) => [
          new TypedHandle(node, new Input(), new Float(), 0),
          new TypedHandle(node, new Input(), new Float(), 1),
          new TypedHandle(node, new Input(), new Sequence(), 2)
        ],
        (node) => new TypedHandle(node, new Output(), new Sequence())
      )
  ),
  'Centered moving average': functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Centered moving average',
        (node) => [
          new TypedHandle(node, new Input(), new Integer(), 0),
          new TypedHandle(node, new Input(), new Sequence(), 1)
        ],
        (node) => new TypedHandle(node, new Output(), new Sequence())
      )
  ),
  Clip: functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Clip',
        (node) => [
          new TypedHandle(node, new Input(), new Float(), 0),
          new TypedHandle(node, new Input(), new Float(), 1),
          new TypedHandle(node, new Input(), new Array(new Float()), 2)
        ],
        (node) => new TypedHandle(node, new Output(), new Array(new Float()))
      )
  ),
  'Combine arrays': functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Combine arrays',
        (node) => [
          new TypedHandle(node, new Input(), new Integer(), 0),
          new TypedHandle(node, new Input(), new Array(new Float()), 1),
          new TypedHandle(node, new Input(), new Array(new Float()), 2)
        ],
        (node) => new TypedHandle(node, new Output(), new Array(new Float()))
      )
  ),
  'Convert to sequence': functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Convert to sequence',
        (node) => [
          new TypedHandle(node, new Input(), new Array(new Float()), 0),
          new TypedHandle(node, new Input(), new Array(new Float()), 1)
        ],
        (node) => new TypedHandle(node, new Output(), new Sequence())
      )
  ),
  Cosine: functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Cosine',
        (node) => [new TypedHandle(node, new Input(), new Float())],
        (node) => new TypedHandle(node, new Output(), new Float())
      )
  ),
  'Create array': functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Create array',
        (node) => [
          new TypedHandle(node, new Input(), new Integer(), 0),
          new TypedHandle(node, new Input(), new Array(new Float()), 1),
          new TypedHandle(node, new Input(), new Array(new Float()), 2)
        ],
        (node) => new TypedHandle(node, new Output(), new Array(new Float()))
      )
  ),
  'Create sequence': functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Create sequence',
        (node) => [
          new TypedHandle(node, new Input(), new BooleanType(), 0),
          new TypedHandle(node, new Input(), new Float(), 1)
        ],
        (node) => new TypedHandle(node, new Output(), new Sequence())
      )
  ),
  'Degrees to radians': functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Degrees to radians',
        (node) => [new TypedHandle(node, new Input(), new Float())],
        (node) => new TypedHandle(node, new Output(), new Float())
      )
  ),
  Delay: functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Delay',
        (node) => [
          new TypedHandle(node, new Input(), new Float(), 0),
          new TypedHandle(node, new Input(), new Float(), 1)
        ],
        (node) => new TypedHandle(node, new Output(), new Float())
      )
  ),
  Derivative: functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Derivative',
        (node) => [new TypedHandle(node, new Input(), new Float())],
        (node) => new TypedHandle(node, new Output(), new Float())
      )
  ),
  Distance: functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Distance',
        (node) => [
          new TypedHandle(node, new Input(), new Vector(), 0),
          new TypedHandle(node, new Input(), new Vector(), 1)
        ],
        (node) => new TypedHandle(node, new Output(), new Float())
      )
  ),
  Divide: functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection, dataType) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Divide',
        (node) => [
          new GenericHandle(
            node,
            new Input(),
            new AnyConstraint(),
            dataType,
            0
          ),
          new GenericHandle(node, new Input(), new AnyConstraint(), dataType, 1)
        ],
        (node) =>
          new GenericHandle(node, new Output(), new AnyConstraint(), dataType)
      )
  ),
  Duration: functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Duration',
        (node) => [new TypedHandle(node, new Input(), new Sequence())],
        (node) => new TypedHandle(node, new Output(), new Float())
      )
  ),
  'End time': functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'End time',
        (node) => [new TypedHandle(node, new Input(), new Sequence())],
        (node) => new TypedHandle(node, new Output(), new Float())
      )
  ),
  Equal: functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection, dataType) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Equal',
        (node) => [
          new GenericHandle(
            node,
            new Input(),
            new AnyConstraint(),
            dataType,
            0
          ),
          new GenericHandle(node, new Input(), new AnyConstraint(), dataType, 1)
        ],
        (node) => new TypedHandle(node, new Output(), new BooleanType())
      )
  ),
  'Euler to quaternion': functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Euler to quaternion',
        (node) => [new TypedHandle(node, new Input(), new Vector())],
        (node) => new TypedHandle(node, new Output(), new Orientation())
      )
  ),
  Float: functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Float',
        (node) => [new TypedHandle(node, new Input(), new Integer())],
        (node) => new TypedHandle(node, new Output(), new Float())
      )
  ),
  'Float interpolation': functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Float interpolation',
        (node) => [
          new TypedHandle(node, new Input(), new Float(), 0),
          new TypedHandle(node, new Input(), new Float(), 1),
          new TypedHandle(node, new Input(), new Float(), 2)
        ],
        (node) => new TypedHandle(node, new Output(), new Float())
      )
  ),
  Gate: functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection, dataType) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Gate',
        (node) => [
          new TypedHandle(node, new Input(), new BooleanType(), 0),
          new GenericHandle(node, new Input(), new AnyConstraint(), dataType, 1)
        ],
        (node) =>
          new GenericHandle(node, new Output(), new AnyConstraint(), dataType)
      )
  ),
  'Get element': functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection, dataType) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Get element',
        (node) => [
          new TypedHandle(node, new Input(), new Integer(), 0),
          new GenericHandle(
            node,
            new Input(),
            new ArrayConstraint(),
            dataType,
            1
          )
        ],
        (node) =>
          new GenericHandle(node, new Output(), new ValueConstraint(), dataType)
      )
  ),
  'Get interpolated values': functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Get interpolated values',
        (node) => [
          new TypedHandle(node, new Input(), new Array(new Float()), 0),
          new TypedHandle(node, new Input(), new Sequence(), 1)
        ],
        (node) => new TypedHandle(node, new Output(), new Array(new Float()))
      )
  ),
  'Insert to array': functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Insert to array',
        (node) => [
          new TypedHandle(node, new Input(), new Integer(), 0),
          new TypedHandle(node, new Input(), new Float(), 1),
          new TypedHandle(node, new Input(), new Array(new Float()), 2)
        ],
        (node) => new TypedHandle(node, new Output(), new Array(new Float()))
      )
  ),
  Integer: functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Integer',
        (node) => [new TypedHandle(node, new Input(), new Float())],
        (node) => new TypedHandle(node, new Output(), new Integer())
      )
  ),
  Integral: functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Integral',
        (node) => [new TypedHandle(node, new Input(), new Float())],
        (node) => new TypedHandle(node, new Output(), new Float())
      )
  ),
  Intersections: functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Intersections',
        (node) => [
          new TypedHandle(node, new Input(), new Float(), 0),
          new TypedHandle(node, new Input(), new Sequence(), 1)
        ],
        (node) => new TypedHandle(node, new Output(), new Array(new Float()))
      )
  ),
  'Inverse cosine': functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Inverse cosine',
        (node) => [new TypedHandle(node, new Input(), new Float())],
        (node) => new TypedHandle(node, new Output(), new Float())
      )
  ),
  'Inverse quaternion': functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Inverse quaternion',
        (node) => [new TypedHandle(node, new Input(), new Orientation())],
        (node) => new TypedHandle(node, new Output(), new Orientation())
      )
  ),
  'Inverse sine': functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Inverse sine',
        (node) => [new TypedHandle(node, new Input(), new Float())],
        (node) => new TypedHandle(node, new Output(), new Float())
      )
  ),
  'Inverse tangent': functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Inverse tangent',
        (node) => [new TypedHandle(node, new Input(), new Float())],
        (node) => new TypedHandle(node, new Output(), new Float())
      )
  ),
  'JSON output': functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection, dataType) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'JSON output',
        (node) => [
          new TypedHandle(node, new Input(), new StringType(), 0),
          new TypedHandle(node, new Input(), new StringType(), 1),
          new GenericHandle(node, new Input(), new AnyConstraint(), dataType, 2)
        ],
        (node) => new TypedHandle(node, new Output(), new StringType())
      )
  ),
  Larger: functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection, dataType) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Larger',
        (node) => [
          new GenericHandle(
            node,
            new Input(),
            new AnyConstraint(),
            dataType,
            0
          ),
          new GenericHandle(node, new Input(), new AnyConstraint(), dataType, 1)
        ],
        (node) => new TypedHandle(node, new Output(), new BooleanType())
      )
  ),
  'Larger or equal': functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection, dataType) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Larger or equal',
        (node) => [
          new GenericHandle(
            node,
            new Input(),
            new AnyConstraint(),
            dataType,
            0
          ),
          new GenericHandle(node, new Input(), new AnyConstraint(), dataType, 1)
        ],
        (node) => new TypedHandle(node, new Output(), new BooleanType())
      )
  ),
  'Make quaternion': functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Make quaternion',
        (node) => [
          new TypedHandle(node, new Input(), new Float(), 0),
          new TypedHandle(node, new Input(), new Vector(), 1)
        ],
        (node) => new TypedHandle(node, new Output(), new Orientation())
      )
  ),
  'Make vector': functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Make vector',
        (node) => [
          new TypedHandle(node, new Input(), new Float(), 0),
          new TypedHandle(node, new Input(), new Float(), 1),
          new TypedHandle(node, new Input(), new Float(), 2)
        ],
        (node) => new TypedHandle(node, new Output(), new Vector())
      )
  ),
  Max: functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Max',
        (node) => [
          new TypedHandle(node, new Input(), new Float(), 0),
          new TypedHandle(node, new Input(), new Float(), 1)
        ],
        (node) => new TypedHandle(node, new Output(), new Float())
      )
  ),
  Maximum: functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Maximum',
        (node) => [new TypedHandle(node, new Input(), new Array(new Float()))],
        (node) => new TypedHandle(node, new Output(), new Float())
      )
  ),
  Min: functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Min',
        (node) => [
          new TypedHandle(node, new Input(), new Float(), 0),
          new TypedHandle(node, new Input(), new Float(), 1)
        ],
        (node) => new TypedHandle(node, new Output(), new Float())
      )
  ),
  Minimum: functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Minimum',
        (node) => [new TypedHandle(node, new Input(), new Array(new Float()))],
        (node) => new TypedHandle(node, new Output(), new Float())
      )
  ),
  'Moving averager': functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Moving averager',
        (node) => [
          new TypedHandle(node, new Input(), new Integer(), 0),
          new TypedHandle(node, new Input(), new Float(), 1)
        ],
        (node) => new TypedHandle(node, new Output(), new Float())
      )
  ),
  Multiply: functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection, dataType) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Multiply',
        (node) => [
          new GenericHandle(
            node,
            new Input(),
            new AnyConstraint(),
            dataType,
            0
          ),
          new GenericHandle(node, new Input(), new AnyConstraint(), dataType, 1)
        ],
        (node) =>
          new GenericHandle(node, new Output(), new AnyConstraint(), dataType)
      )
  ),
  Negate: functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection, dataType) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Negate',
        (node) => [
          new GenericHandle(node, new Input(), new AnyConstraint(), dataType)
        ],
        (node) =>
          new GenericHandle(node, new Output(), new AnyConstraint(), dataType)
      )
  ),
  Normalize: functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Normalize',
        (node) => [new TypedHandle(node, new Input(), new Sequence())],
        (node) => new TypedHandle(node, new Output(), new Sequence())
      )
  ),
  'Normalize quaternion': functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Normalize quaternion',
        (node) => [new TypedHandle(node, new Input(), new Orientation())],
        (node) => new TypedHandle(node, new Output(), new Orientation())
      )
  ),
  'Normalize vector': functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Normalize vector',
        (node) => [new TypedHandle(node, new Input(), new Vector())],
        (node) => new TypedHandle(node, new Output(), new Vector())
      )
  ),
  Not: functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Not',
        (node) => [new TypedHandle(node, new Input(), new BooleanType())],
        (node) => new TypedHandle(node, new Output(), new BooleanType())
      )
  ),
  'Number of elements': functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Number of elements',
        (node) => [new TypedHandle(node, new Input(), new Integer())],
        (node) => new TypedHandle(node, new Output(), new Array(new Float()))
      )
  ),
  Or: functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Or',
        (node) => [
          new TypedHandle(node, new Input(), new BooleanType(), 0),
          new TypedHandle(node, new Input(), new BooleanType(), 1)
        ],
        (node) => new TypedHandle(node, new Output(), new BooleanType())
      )
  ),
  Order: functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Order',
        (node) => [
          new TypedHandle(node, new Input(), new BooleanType(), 0),
          new TypedHandle(node, new Input(), new Array(new Float()), 1)
        ],
        (node) => new TypedHandle(node, new Output(), new Array(new Float()))
      )
  ),
  'Pass updated': functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection, dataType) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Pass updated',
        (node) => [
          new GenericHandle(
            node,
            new Input(),
            new AnyConstraint(),
            dataType,
            0
          ),
          new GenericHandle(node, new Input(), new AnyConstraint(), dataType, 1)
        ],
        (node) =>
          new GenericHandle(node, new Output(), new AnyConstraint(), dataType)
      )
  ),
  'Pass-through': functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection, dataType) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Pass-through',
        (node) => [
          new GenericHandle(node, new Input(), new AnyConstraint(), dataType)
        ],
        (node) =>
          new GenericHandle(node, new Output(), new AnyConstraint(), dataType)
      )
  ),
  'Peak detector': functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Peak detector',
        (node) => [
          new TypedHandle(node, new Input(), new Float(), 0),
          new TypedHandle(node, new Input(), new Float(), 1)
        ],
        (node) => new TypedHandle(node, new Output(), new Float())
      )
  ),
  Power: functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Power',
        (node) => [
          new TypedHandle(node, new Input(), new Float(), 0),
          new TypedHandle(node, new Input(), new Float(), 1)
        ],
        (node) => new TypedHandle(node, new Output(), new Float())
      )
  ),
  Quaternion: functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Quaternion',
        (node) => [
          new TypedHandle(node, new Input(), new Float(), 0),
          new TypedHandle(node, new Input(), new Float(), 1),
          new TypedHandle(node, new Input(), new Float(), 2),
          new TypedHandle(node, new Input(), new Float(), 3)
        ],
        (node) => new TypedHandle(node, new Output(), new Orientation())
      )
  ),
  'Quaternion angle': functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Quaternion angle',
        (node) => [new TypedHandle(node, new Input(), new Orientation())],
        (node) => new TypedHandle(node, new Output(), new Float())
      )
  ),
  'Quaternion axis': functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Quaternion axis',
        (node) => [new TypedHandle(node, new Input(), new Orientation())],
        (node) => new TypedHandle(node, new Output(), new Vector())
      )
  ),
  'Quaternion interpolation': functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Quaternion interpolation',
        (node) => [
          new TypedHandle(node, new Input(), new Float(), 0),
          new TypedHandle(node, new Input(), new Orientation(), 1),
          new TypedHandle(node, new Input(), new Orientation(), 2)
        ],
        (node) => new TypedHandle(node, new Output(), new Orientation())
      )
  ),
  'Quaternion spline': functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Quaternion spline',
        (node) => [
          new TypedHandle(node, new Input(), new Float(), 0),
          new TypedHandle(node, new Input(), new Orientation(), 1),
          new TypedHandle(node, new Input(), new Orientation(), 2),
          new TypedHandle(node, new Input(), new Orientation(), 3),
          new TypedHandle(node, new Input(), new Orientation(), 4)
        ],
        (node) => new TypedHandle(node, new Output(), new Orientation())
      )
  ),
  'Quaternion to Euler': functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Quaternion to Euler',
        (node) => [new TypedHandle(node, new Input(), new Orientation(), 0)],
        (node) => new TypedHandle(node, new Output(), new Vector())
      )
  ),
  'Radians to degrees': functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Radians to degrees',
        (node) => [new TypedHandle(node, new Input(), new Float(), 0)],
        (node) => new TypedHandle(node, new Output(), new Float())
      )
  ),
  'Remove from array': functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Remove from array',
        (node) => [
          new TypedHandle(node, new Input(), new Integer(), 0),
          new TypedHandle(node, new Input(), new Array(new Float()), 1)
        ],
        (node) => new TypedHandle(node, new Output(), new Array(new Float()))
      )
  ),
  Resample: functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Resample',
        (node) => [
          new TypedHandle(node, new Input(), new Integer(), 0),
          new TypedHandle(node, new Input(), new Sequence(), 1)
        ],
        (node) => new TypedHandle(node, new Output(), new Sequence())
      )
  ),
  'Rotate vector': functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Rotate vector',
        (node) => [
          new TypedHandle(node, new Input(), new Vector(), 0),
          new TypedHandle(node, new Input(), new Orientation(), 1)
        ],
        (node) => new TypedHandle(node, new Output(), new Vector())
      )
  ),
  Round: functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Round',
        (node) => [
          new TypedHandle(node, new Input(), new Float(), 0),
          new TypedHandle(node, new Input(), new Integer(), 1)
        ],
        (node) => new TypedHandle(node, new Output(), new Float())
      )
  ),
  Scale: functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection, dataType) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Scale',
        (node) => [
          new TypedHandle(node, new Input(), new Float(), 0),
          new GenericHandle(node, new Input(), new AnyConstraint(), dataType, 1)
        ],
        (node) =>
          new GenericHandle(node, new Output(), new AnyConstraint(), dataType)
      )
  ),
  'Scale time': functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Scale time',
        (node) => [
          new TypedHandle(node, new Input(), new Float(), 0),
          new TypedHandle(node, new Input(), new Float(), 1),
          new TypedHandle(node, new Input(), new Sequence(), 2)
        ],
        (node) => new TypedHandle(node, new Output(), new Sequence())
      )
  ),
  'Search array': functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Search array',
        (node) => [
          new TypedHandle(node, new Input(), new Float(), 0),
          new TypedHandle(node, new Input(), new Array(new Float()), 1)
        ],
        (node) => new TypedHandle(node, new Output(), new Integer())
      )
  ),
  'Select interval': functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Select interval',
        (node) => [
          new TypedHandle(node, new Input(), new Float(), 0),
          new TypedHandle(node, new Input(), new Float(), 1),
          new TypedHandle(node, new Input(), new Sequence(), 2)
        ],
        (node) => new TypedHandle(node, new Output(), new Sequence())
      )
  ),
  'Sequence rectifier': functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Sequence rectifier',
        (node) => [new TypedHandle(node, new Input(), new Sequence())],
        (node) => new TypedHandle(node, new Output(), new Sequence())
      )
  ),
  'Sequence timestamps': functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Sequence timestamps',
        (node) => [new TypedHandle(node, new Input(), new Sequence())],
        (node) => new TypedHandle(node, new Output(), new Array(new Float()))
      )
  ),
  'Sequence values': functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Sequence values',
        (node) => [new TypedHandle(node, new Input(), new Sequence())],
        (node) => new TypedHandle(node, new Output(), new Array(new Float()))
      )
  ),
  Sequencer: functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Sequencer',
        (node) => [
          new TypedHandle(node, new Input(), new Float(), 0),
          new TypedHandle(node, new Input(), new Float(), 1)
        ],
        (node) => new TypedHandle(node, new Output(), new Sequence())
      )
  ),
  'Shift elements': functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Shift elements',
        (node) => [
          new TypedHandle(node, new Input(), new Float(), 0),
          new TypedHandle(node, new Input(), new Array(new Float()), 1)
        ],
        (node) => new TypedHandle(node, new Output(), new Array(new Float()))
      )
  ),
  'Shift time': functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Shift time',
        (node) => [
          new TypedHandle(node, new Input(), new Float(), 0),
          new TypedHandle(node, new Input(), new Sequence(), 1)
        ],
        (node) => new TypedHandle(node, new Output(), new Sequence())
      )
  ),
  Sine: functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Sine',
        (node) => [new TypedHandle(node, new Input(), new Float())],
        (node) => new TypedHandle(node, new Output(), new Float())
      )
  ),
  Smaller: functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection, dataType) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Smaller',
        (node) => [
          new GenericHandle(
            node,
            new Input(),
            new AnyConstraint(),
            dataType,
            0
          ),
          new GenericHandle(node, new Input(), new AnyConstraint(), dataType, 1)
        ],
        (node) => new TypedHandle(node, new Output(), new BooleanType())
      )
  ),
  'Smaller or equal': functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection, dataType) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Smaller or equal',
        (node) => [
          new GenericHandle(
            node,
            new Input(),
            new AnyConstraint(),
            dataType,
            0
          ),
          new GenericHandle(node, new Input(), new AnyConstraint(), dataType, 1)
        ],
        (node) => new TypedHandle(node, new Output(), new BooleanType())
      )
  ),
  'Start time': functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Start time',
        (node) => [new TypedHandle(node, new Input(), new Sequence())],
        (node) => new TypedHandle(node, new Output(), new Float())
      )
  ),
  Subtract: functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection, dataType) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Subtract',
        (node) => [
          new GenericHandle(
            node,
            new Input(),
            new AnyConstraint(),
            dataType,
            0
          ),
          new GenericHandle(node, new Input(), new AnyConstraint(), dataType, 1)
        ],
        (node) =>
          new GenericHandle(node, new Output(), new AnyConstraint(), dataType)
      )
  ),
  Switch: functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection, dataType) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Switch',
        (node) => [
          new TypedHandle(node, new Input(), new BooleanType(), 0),
          new GenericHandle(
            node,
            new Input(),
            new AnyConstraint(),
            dataType,
            1
          ),
          new GenericHandle(node, new Input(), new AnyConstraint(), dataType, 2)
        ],
        (node) =>
          new GenericHandle(node, new Output(), new AnyConstraint(), dataType)
      )
  ),
  Tangent: functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Tangent',
        (node) => [new TypedHandle(node, new Input(), new Float())],
        (node) => new TypedHandle(node, new Output(), new Float())
      )
  ),
  'Time derivative': functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Time derivative',
        (node) => [new TypedHandle(node, new Input(), new Sequence())],
        (node) => new TypedHandle(node, new Output(), new Sequence())
      )
  ),
  'Time integral': functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Time integral',
        (node) => [new TypedHandle(node, new Input(), new Sequence())],
        (node) => new TypedHandle(node, new Output(), new Sequence())
      )
  ),
  Timestamp: functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection, dataType) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Timestamp',
        (node) => [
          new GenericHandle(node, new Input(), new AnyConstraint(), dataType)
        ],
        (node) => new TypedHandle(node, new Output(), new Float())
      )
  ),
  Toggled: functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Toggled',
        (node) => [new TypedHandle(node, new Input(), new BooleanType())],
        (node) => new TypedHandle(node, new Output(), new BooleanType())
      )
  ),
  Trigger: functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection, dataType) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Trigger',
        (node) => [
          new TypedHandle(node, new Input(), new BooleanType(), 0),
          new GenericHandle(node, new Input(), new AnyConstraint(), dataType, 1)
        ],
        (node) =>
          new GenericHandle(node, new Output(), new AnyConstraint(), dataType)
      )
  ),
  Trim: functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Trim',
        (node) => [
          new TypedHandle(node, new Input(), new Integer(), 0),
          new TypedHandle(node, new Input(), new Integer(), 1),
          new TypedHandle(node, new Input(), new Array(new Float()), 2)
        ],
        (node) => new TypedHandle(node, new Output(), new Array(new Float()))
      )
  ),
  'Update counter': functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection, dataType) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Update counter',
        (node) => [
          new GenericHandle(node, new Input(), new AnyConstraint(), dataType)
        ],
        (node) => new TypedHandle(node, new Output(), new Integer())
      )
  ),
  Updated: functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection, dataType) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Updated',
        (node) => [
          new GenericHandle(node, new Input(), new AnyConstraint(), dataType)
        ],
        (node) => new TypedHandle(node, new Output(), new BooleanType())
      )
  ),
  'Vector angle': functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Vector angle',
        (node) => [
          new TypedHandle(node, new Input(), new Vector(), 0),
          new TypedHandle(node, new Input(), new Vector(), 1)
        ],
        (node) => new TypedHandle(node, new Output(), new Float())
      )
  ),
  'Vector cross product': functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Vector cross product',
        (node) => [
          new TypedHandle(node, new Input(), new Vector(), 0),
          new TypedHandle(node, new Input(), new Vector(), 1)
        ],
        (node) => new TypedHandle(node, new Output(), new Vector())
      )
  ),
  'Vector dot product': functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Vector dot product',
        (node) => [
          new TypedHandle(node, new Input(), new Vector(), 0),
          new TypedHandle(node, new Input(), new Vector(), 1)
        ],
        (node) => new TypedHandle(node, new Output(), new Float())
      )
  ),
  'Vector length': functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Vector length',
        (node) => [new TypedHandle(node, new Input(), new Vector())],
        (node) => new TypedHandle(node, new Output(), new Float())
      )
  ),
  'Vector x-coordinate': functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Vector x-coordinate',
        (node) => [new TypedHandle(node, new Input(), new Vector())],
        (node) => new TypedHandle(node, new Output(), new Float())
      )
  ),
  'Vector y-coordinate': functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Vector y-coordinate',
        (node) => [new TypedHandle(node, new Input(), new Vector())],
        (node) => new TypedHandle(node, new Output(), new Float())
      )
  ),
  'Vector z-coordinate': functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Vector z-coordinate',
        (node) => [new TypedHandle(node, new Input(), new Vector())],
        (node) => new TypedHandle(node, new Output(), new Float())
      )
  ),
  Xor: functionNodeTypeFactoryFactory.create(
    (id, x, y, properties, nodeSelection) =>
      new FunctionNode(
        id,
        x,
        y,
        properties,
        nodeSelection,
        'Xor',
        (node) => [
          new TypedHandle(node, new Input(), new BooleanType(), 0),
          new TypedHandle(node, new Input(), new BooleanType(), 1)
        ],
        (node) => new TypedHandle(node, new Output(), new BooleanType())
      )
  )
}

export default functionNodeFactoryMap
