import { DiagramListProps } from './DiagramList.types'
import useDiagramList from './useDiagramList'
import { Box, Divider, List, ListItemButton, ListItemText } from '@mui/material'
import Search from '../Search'
import * as React from 'react'
import NoDiagramsFound from '../NoDiagramsFound'

const DiagramList = (props: DiagramListProps) => {
  const {
    diagrams,
    searchTerm,
    handleSearchTermChange,
    clearSearchTerm,
    handleDiagramClick,
    isDiagramSelected
  } = useDiagramList(props)

  return (
    <>
      <Box sx={{ padding: 1, paddingBottom: 0 }}>
        <Search
          value={searchTerm}
          onChange={handleSearchTermChange}
          placeholder="search diagrams..."
        />
      </Box>
      <List>
        {diagrams.length > 0 ? (
          diagrams.map((diagram) => (
            <Box key={diagram.uuid}>
              <ListItemButton
                onClick={() => handleDiagramClick(diagram)}
                selected={isDiagramSelected(diagram.uuid)}
              >
                <ListItemText
                  primary={diagram.name}
                  secondary={
                    // TODO: look into manual lines problem: 1 entry, empty string
                    <>
                      {diagram.manualLines.join(' ')}
                      <span>&nbsp;</span>
                    </>
                  }
                  primaryTypographyProps={{ noWrap: true }}
                  secondaryTypographyProps={{ noWrap: true }}
                />
              </ListItemButton>
              <Divider variant="middle" light />
            </Box>
          ))
        ) : (
          <NoDiagramsFound
            onClearSearchTerm={clearSearchTerm}
            searchTerm={searchTerm}
          />
        )}
      </List>
    </>
  )
}

export default DiagramList
