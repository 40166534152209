import diagramDataStoreValidator from '../../validation/diagramDataStoreValidator'
import DiagramDataStore from '../../models/DiagramDataStore'

const validateDataStoreOrDefault = (
  data: unknown,
  def: DiagramDataStore = {}
): Promise<DiagramDataStore> =>
  diagramDataStoreValidator
    .parseAsync(data)
    .then((store) => Promise.resolve(store))
    .catch(() => Promise.resolve(def))

export default validateDataStoreOrDefault
