import NodeFactory from './NodeFactory'
import DiagramPropertyPropertyString from '../../data/models/DiagramPropertyPropertyString'
import ConstantInputNode from '../models/ConstantInputNode'
import NodeSelection from '../models/NodeSelection'
import DataType from '../models/dataTypes/DataType'

const constantInputNodeFactory: NodeFactory = {
  create: (
    id: string,
    x: number,
    y: number,
    dataType: DataType,
    properties: Partial<Record<DiagramPropertyPropertyString, string>>,
    nodeSelection: NodeSelection
  ) => new ConstantInputNode(id, x, y, dataType, properties, nodeSelection)
}

export default constantInputNodeFactory
