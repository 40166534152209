import DataType from './DataType'
import dataTypeUtils from '../../utils/dataTypeUtils'
import DiagramVariableType from '../../../data/models/DiagramVariableType'

class String implements DataType {
  get name(): DiagramVariableType {
    return 'String'
  }

  get symbol(): string {
    return '$'
  }

  public isCompatible(dataType: DataType): boolean {
    return dataTypeUtils.isCompatible(this, dataType)
  }
}

export default String
