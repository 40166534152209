import Constraint from './Constraint'
import DataType from './dataTypes/DataType'

class AnyConstraint implements Constraint {
  getSymbol(dataType: DataType | undefined): string {
    return dataType ? dataType.symbol : '*'
  }

  modify(dataType: DataType | undefined): DataType | undefined {
    return dataType
  }

  conforms(): boolean {
    return true
  }
}

export default AnyConstraint
