import { ValueSettingInputProps } from '../ValueSettingInput/ValueSettingInput.types'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import sequenceValueFactory from '../../features/domain/factories/values/sequenceValueFactory'
import SequenceValue from '../../features/domain/models/values/SequenceValue'

const useSequenceValueSettingInput = (props: ValueSettingInputProps) => {
  const { value, onValueChange } = props

  const [text, setText] = useState('')

  const [errorText, setErrorText] = useState<string>()

  useEffect(() => {
    sequenceValueFactory
      .tryCreateWithStringRepresentation(value)
      .then((created) => setText(created.values.join('\n')))
      .catch(console.log)
  }, [value])

  const handleTextChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => setText(event.target.value),
    []
  )

  const handleSave = useCallback(() => {
    const sequences = text.split('\n')

    if (
      !/\[(\([0-9]+, [0-9]+\)(, (?!]))?)+]/g.test(`[${sequences.join(', ')}]`)
    ) {
      setErrorText(`Provided value is not a valid sequence array`)
      return
    }

    const newValue = new SequenceValue(sequences)
    onValueChange(newValue.toString())
  }, [onValueChange, text])

  return {
    text,
    handleTextChange,
    handleSave,
    errorText
  }
}

export default useSequenceValueSettingInput
