import useNodeToolbox from './useNodeToolbox'
import MultipleInputsNode from '../MultipleInputsNode'
import ConstantInputNode from '../ConstantInputNode'
import OutputNode from '../OutputNode'
import StreamInputNode from '../StreamInputNode'
import {
  Box,
  Collapse,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  Typography
} from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import FunctionNodeData from '../../features/presentation/FunctionNodeData'
import ConstantInputNodeData from '../../features/presentation/ConstantInputNodeData'
import OutputNodeData from '../../features/presentation/OutputNodeData'
import DiagramFunctionType from '../../features/data/models/DiagramFunctionType'
import DiagramVariableType from '../../features/data/models/DiagramVariableType'
import NodeData from '../../features/presentation/NodeData'
import React from 'react'
import Search from '../Search'
import StreamInputNodeData from '../../features/presentation/StreamInputNodeData'
import draggedFunctionNodeFactory from '../../features/presentation/utils/draggedFunctionNodeFactory'
import DraggedNodeFunctionNodeData from '../../features/presentation/DraggedNodeFunctionNodeData'
import draggedDataTypeableNodeFactory from '../../features/presentation/utils/draggedDataTypeableNodeFactory'
import DraggedNodeDataTypeableNodeData from '../../features/presentation/DraggedNodeDataTypeableNodeData'

const NodeToolbox = () => {
  const {
    functionNodePreviews,
    constantInputNodePreviews,
    streamInputNodePreviews,
    outputNodePreviews,
    handleFunctionPreviewsButtonClick,
    handleConstantPreviewsButtonClick,
    handleStreamPreviewsButtonClick,
    handleOutputPreviewsButtonClick,
    isFunctionPreviewsOpen,
    isConstantPreviewsOpen,
    isStreamPreviewsOpen,
    isOutputPreviewsOpen,
    handleSearchChange,
    handleDragStart
  } = useNodeToolbox()

  return (
    <Box sx={{ height: 'calc(100% - 234px)', width: '270px' }}>
      <Box sx={{ margin: 1 }}>
        <Typography variant="h6" gutterBottom>
          Toolbox
        </Typography>
        <Search onChange={handleSearchChange} placeholder="search nodes..." />
      </Box>
      <List sx={{ overflowY: 'auto', height: '100%' }}>
        <ListItemButton onClick={handleFunctionPreviewsButtonClick}>
          <ListItemText primary="Functions"></ListItemText>
          {isFunctionPreviewsOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={isFunctionPreviewsOpen} timeout="auto" unmountOnExit>
          <Grid container spacing={2} sx={{ paddingX: 2, paddingY: 1 }}>
            {functionNodePreviews.map((node, i) => (
              <Grid item xs={6} key={i} sx={{ height: 100 }}>
                <div
                  onDragStart={(event) =>
                    handleDragStart<DraggedNodeFunctionNodeData>(
                      event,
                      draggedFunctionNodeFactory.create(
                        (node.data as FunctionNodeData)
                          .label as DiagramFunctionType
                      )
                    )
                  }
                  draggable
                  className="react-flow__node react-flow__node-multipleInputs nopan selectable"
                  style={{
                    ...node.style,
                    transform: 'scale(0.25)',
                    position: 'relative'
                  }}
                >
                  <MultipleInputsNode
                    id={node.id}
                    data={node.data as FunctionNodeData}
                    xPos={node.position.x}
                    yPos={node.position.y}
                    isConnectable={false}
                    dragging={false}
                    selected={false}
                    type={node.type as string}
                    zIndex={0}
                  />
                </div>
              </Grid>
            ))}
          </Grid>
        </Collapse>
        <ListItemButton onClick={handleConstantPreviewsButtonClick}>
          <ListItemText primary="Constants"></ListItemText>
          {isConstantPreviewsOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={isConstantPreviewsOpen} timeout="auto" unmountOnExit>
          <Grid container spacing={2} sx={{ paddingX: 2, paddingY: 1 }}>
            {constantInputNodePreviews.map((node, i) => (
              <Grid item xs={6} key={i} sx={{ height: 80 }}>
                <div
                  onDragStart={(event) =>
                    handleDragStart<DraggedNodeDataTypeableNodeData>(
                      event,
                      draggedDataTypeableNodeFactory.create(
                        'constantInput',
                        (node.data as NodeData).label as DiagramVariableType
                      )
                    )
                  }
                  draggable
                  className="react-flow__node react-flow__node-multipleInputs nopan selectable"
                  style={{
                    ...node.style,
                    transform: 'scale(0.25)',
                    position: 'relative'
                  }}
                >
                  <ConstantInputNode
                    id={node.id}
                    data={{
                      ...(node.data as ConstantInputNodeData),
                      displayNodeToolbar: false
                    }}
                    xPos={node.position.x}
                    yPos={node.position.y}
                    isConnectable={false}
                    dragging={false}
                    selected={false}
                    type={node.type as string}
                    zIndex={0}
                  />
                </div>
              </Grid>
            ))}
          </Grid>
        </Collapse>
        <ListItemButton onClick={handleStreamPreviewsButtonClick}>
          <ListItemText primary="Input streams"></ListItemText>
          {isStreamPreviewsOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={isStreamPreviewsOpen} timeout="auto" unmountOnExit>
          <Grid container spacing={2} sx={{ paddingX: 2, paddingY: 1 }}>
            {streamInputNodePreviews.map((node, i) => (
              <Grid item xs={6} key={i} sx={{ height: 55 }}>
                <div
                  onDragStart={(event) =>
                    handleDragStart<DraggedNodeDataTypeableNodeData>(
                      event,
                      draggedDataTypeableNodeFactory.create(
                        'streamInput',
                        (node.data as NodeData).label as DiagramVariableType
                      )
                    )
                  }
                  draggable
                  className="react-flow__node react-flow__node-multipleInputs nopan selectable"
                  style={{
                    ...node.style,
                    transform: 'scale(0.25)',
                    position: 'relative'
                  }}
                >
                  <StreamInputNode
                    id={node.id}
                    data={node.data as StreamInputNodeData}
                    xPos={node.position.x}
                    yPos={node.position.y}
                    isConnectable={false}
                    dragging={false}
                    selected={false}
                    type={node.type as string}
                    zIndex={0}
                  />
                </div>
              </Grid>
            ))}
          </Grid>
        </Collapse>
        <ListItemButton onClick={handleOutputPreviewsButtonClick}>
          <ListItemText primary="Variable outputs"></ListItemText>
          {isOutputPreviewsOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={isOutputPreviewsOpen} timeout="auto" unmountOnExit>
          <Grid container spacing={2} sx={{ paddingX: 2, paddingY: 1 }}>
            {outputNodePreviews.map((node, i) => (
              <Grid item xs={6} key={i} sx={{ height: 55 }}>
                <div
                  onDragStart={(event) =>
                    handleDragStart<DraggedNodeDataTypeableNodeData>(
                      event,
                      draggedDataTypeableNodeFactory.create(
                        'output',
                        (node.data as NodeData).label as DiagramVariableType
                      )
                    )
                  }
                  draggable
                  className="react-flow__node react-flow__node-multipleInputs nopan selectable"
                  style={{
                    ...node.style,
                    transform: 'scale(0.25)',
                    position: 'relative'
                  }}
                >
                  <OutputNode
                    id={node.id}
                    data={node.data as OutputNodeData}
                    xPos={node.position.x}
                    yPos={node.position.y}
                    isConnectable={false}
                    dragging={false}
                    selected={false}
                    type={node.type as string}
                    zIndex={0}
                  />
                </div>
              </Grid>
            ))}
          </Grid>
        </Collapse>
      </List>
    </Box>
  )
}

export default NodeToolbox
