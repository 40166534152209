import Transformer from '../../../transformation/Transformer'
import DiagramSubdiagramDataModel from '../../models/DiagramSubdiagramDataModel'
import Subdiagram from '../../../domain/models/Subdiagram'
import NodeRepository from '../../../domain/models/NodeRepository'
import { formatNodeId } from '../../../domain/utils/idFormatters'

const diagramSubdiagramDataModelsToSubdiagramsTransformer: Transformer<
  DiagramSubdiagramDataModel[] | undefined,
  Subdiagram[],
  {
    nodeRepository: NodeRepository
  }
> = {
  transform: (dataSubdiagrams, { nodeRepository }) => {
    if (!dataSubdiagrams) {
      return []
    }

    const subdiagrams: Subdiagram[] = []

    for (let i = 0; i < dataSubdiagrams.length; i++) {
      const dataSubdiagram = dataSubdiagrams[i]
      const nodeIds = dataSubdiagram.nodeIds.map((nodeId) =>
        formatNodeId(nodeId)
      )

      const subdiagram = new Subdiagram(i, dataSubdiagram.description)

      subdiagrams.push(subdiagram)

      for (const nodeId of nodeIds) {
        const node = nodeRepository.getById(nodeId)

        if (!node) {
          continue
        }

        subdiagram.addNode(node)
      }
    }

    return subdiagrams
  }
}

export default diagramSubdiagramDataModelsToSubdiagramsTransformer
