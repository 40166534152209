import { LoopSettingProps } from './LoopSetting.types'
import { Alert } from '@mui/material'
import React from 'react'
import useLoopSetting from './useLoopSetting'

const LoopSetting = (props: LoopSettingProps) => {
  const { looping } = useLoopSetting(props)

  return (
    <>
      {looping && (
        <Alert severity="info">This node is part of a local loop</Alert>
      )}
    </>
  )
}

export default LoopSetting
