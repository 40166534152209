import { NodeProps } from 'reactflow'
import useContrastingColor from '../../features/presentation/hooks/useContrastingColor'
import StreamInputNodeData from '../../features/presentation/StreamInputNodeData'

const useStreamInputNode = (props: NodeProps<StreamInputNodeData>) => {
  const {
    data: { label, output, color, forwarding, looping },
    selected
  } = props

  const labelColor = useContrastingColor(color ?? '')

  return {
    color,
    label,
    labelColor,
    output,
    selected,
    forwarding,
    looping
  }
}

export default useStreamInputNode
